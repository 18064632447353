import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

export const Logout: React.FC<unknown> = () => {
  const { instance } = useMsal();

  useEffect(() => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(process.env.REACT_APP_CLIENT_ID!),
      postLogoutRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
      mainWindowRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
    };
    instance.logoutRedirect(logoutRequest);
  }, []);
  return <></>;
};
