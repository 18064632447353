import type { Plan } from '../../models';

export const getpendingPlanificationCount = (plans: Plan[]): number => {
  let pendingPlanCount = 0;

  plans?.map((plan) => {
    if (plan.controlMeasure && plan.riskType) {
      ++pendingPlanCount;
    }
  });

  return pendingPlanCount;
};

export const groupBy = (list: any, keyGetter: any): any => {
  return Object.entries(
    list.reduce((acc: any, item: any) => {
      if (!acc[item[keyGetter]]) {
        acc[item[keyGetter]] = [];
      }

      acc[item[keyGetter]].push(item);

      return acc;
    }, {})
  ).map(([name, result]) => ({ name, result }));
};
