export const STATUS = {
  NOT_STARTED: 'NOT STARTED',
  STARTED: 'STARTED',
  PENDING_1ST_VISIT: 'PENDING 1ST VISIT',
  PENDING_2ND_VISIT: 'PENDING 2ND VISIT',
  COMPLETED: 'COMPLETED',
};

export const STATUS_ID = {
  NOT_STARTED: 0,
  STARTED: 1,
  PENDING_1ST_VISIT: 2,
  PENDING_2ND_VISIT: 3,
  COMPLETED: 4,
};
