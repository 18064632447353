import type { AddUserToCrew, ChangeRoleForCrew, UserProfile } from '../../models';

import { baseQuery, createApi } from './base.service';

// Define a service using a base URL and expected endpoints
export const profileService = createApi({
  reducerPath: 'profileService',
  baseQuery: baseQuery,
  tagTypes: ['userprofile'],
  endpoints: (builder) => ({
    getMyProfile: builder.query<UserProfile, void>({
      query: () => `Employee/me`,
      providesTags: ['userprofile'],
    }),
    getEmployeeSearch: builder.query<UserProfile[], { searchText: string }>({
      query: (arg) => {
        const { searchText } = arg;

        return `Employee/search/${searchText}`;
      },
    }),
    getADSearch: builder.query<UserProfile[], { searchText: string }>({
      query: (arg) => {
        const { searchText } = arg;

        return `Employee/searchuser/${searchText}`;
      },
    }),
    updateMyProfile: builder.mutation({
      query: (payload: UserProfile) => ({
        url: `userprofile/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['userprofile'],
    }),
    changeRoleForCrew: builder.mutation({
      query: (payload) => ({
        url: `CrewMember/${payload.id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['userprofile'],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetMyProfileQuery,
  useUpdateMyProfileMutation,
  useChangeRoleForCrewMutation,
  useGetEmployeeSearchQuery,
  useGetADSearchQuery,
} = profileService;
