import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';

export const LastCrewCommentsLoader: React.FC<{ displayCollapseBtn?: boolean; isFilterEnabled?: boolean }> = (
  props
) => {
  const { t } = useTranslation();
  const data = [{}, {}, {}, {}, {}];
  return (
    <>
      <div className="overflow-hidden rounded-xl bg-black shadow">
        <div
          className={`p-4 ${
            props.isFilterEnabled ? 'lg:h-[calc(100vh-275px)]' : 'lg:h-[calc(100vh-193px)]'
          } text-center`}
        >
          <div>
            <h2 className="text-base uppercase text-center text-[14px] dark:text-quartery-default whitespace-nowrap">
              <span className="rounded-md border-[2.5px] border-workcard-redbox-primary inline-block px-3.5 py-0 mr-2.5 arrow_box">
                <i className="fa-solid fa-exclamation text-[16px] "></i>
              </span>
              {t('COMPONENTS.LAST_CREW_COMMENTS.TITLE')}
            </h2>
          </div>

          <div className="border border-b-[1px] border-gray-500 mt-3 -mx-10"></div>
          <div
            className={`mt-6 flow-root ${
              props.isFilterEnabled ? 'lg:h-[calc(100vh-387px)]' : 'lg:h-[calc(100vh-305px)]'
            } dark:text-quartery-default`}
          >
            <ul
              role="list"
              className={`-mt-5  overflow-y-auto ${
                props.isFilterEnabled ? 'lg:h-[calc(100vh-380px)]' : 'lg:h-[calc(100vh-297px)]'
              } no-scrollbar`}
            >
              {data?.map((comment, index) => (
                <li key={index} className="">
                  <div className="py-4 px-2 my-5 ml-2 mr-3 rounded-xl border-[3px] border-workcard-redbox-primary relative arrow_right_box">
                    <div className="flex items-center space-x-4">
                      <div className="min-w-0 flex-1 !ml-1.5 mt-3"></div>
                      <div className="-mt-1 pr-1.5">
                        <div className="inline-flex items-center text-xs text-workcard-redbox-primary">
                          <i className="fa-regular fa-clock mr-1"></i>
                          <Skeleton height={'20px'} />
                          <span className="inline-flex items-center rounded ml-3 mt-[-1px] ">
                            <i className="fa-solid fa-location-dot  mr-1.5 mt-[-1px]"></i>
                            <Skeleton height={'20px'} />
                          </span>
                        </div>
                        <div className="">
                          <p className="mt-[7px] items-center text-sm text-gray-500 sm:mt-0"></p>
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center space-x-4 mb-2">
                      <div className="min-w-0 flex-1 !ml-1.5 ">
                        <div className="flex-grow text-sm text-gray-500">
                          <Skeleton height={'20px'} />
                        </div>
                      </div>
                      <div className="-mt-1 pr-0">
                        <div className="">
                          <Skeleton height={'20px'} />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center space-x-4 mr-2.5 ml-[7px]">
                      <div className="flex-grow mt-1 text-[13px] text-whiteline-clamp-2 whitespace-pre-line">
                        <h2 className="text-[14px] text-white uppercase font-semibold">
                          <Skeleton height={'20px'} />
                        </h2>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
