import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface WorkcardInspectionChecklistState {
  userType: string;
}

const initialState: WorkcardInspectionChecklistState = {
  userType: '',
};

export const workcardInspectionChecklistSlice = createSlice({
  name: 'WorkcardInspectionChecklist',
  initialState,
  reducers: {
    selectUserType: (state, action: PayloadAction<string>) => {
      state.userType = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectUserType } = workcardInspectionChecklistSlice.actions;

export default workcardInspectionChecklistSlice.reducer;
