import type { IPublicClientApplication } from '@azure/msal-browser';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface AuthState {
  value: string;
  siteId: number | null;
  msalInstance: IPublicClientApplication | null;
  msalRequest: any;
}

const initialState: AuthState = {
  value: '',
  siteId: null,
  msalInstance: null,
  msalRequest: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    setSiteId: (state, action: PayloadAction<number>) => {
      state.siteId = action.payload;
    },
    setMsalInstance: (state, action: PayloadAction<IPublicClientApplication>) => {
      state.msalInstance = action.payload;
    },
    setMsalRequest: (state, action: PayloadAction<any>) => {
      state.msalRequest = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken } = authSlice.actions;
export const { setSiteId } = authSlice.actions;
export const { setMsalInstance } = authSlice.actions;
export const { setMsalRequest } = authSlice.actions;

export default authSlice.reducer;
