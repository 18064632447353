import type { OverlayEventDetail } from '@ionic/core/components';
import { IonContent, IonPage, useIonModal } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Content } from '../../layout/content/content';
import { Header } from '../../layout/header/Header/Header';
import type { Crew } from '../../models';

import { CrewMgmtEmployeeList } from './components/CrewMgmtEmployeeList/CrewMgmtEmployeeList';
import { CrewMgmtList } from './components/CrewMgmtList/CrewMgmtList';
import ModalAddCrew from './components/CrewMgmtModalAddCrew/CrewMgmtModalAddCrew';
import { CrewMgmtSupervisorList } from './components/CrewMgmtSupervisorList/CrewMgmtSupervisorList';

export const CrewMgmt: React.FC = () => {
  const { t } = useTranslation();

  const [present, dismiss] = useIonModal(ModalAddCrew, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const [selecteCrew, setCrew] = useState<Crew | null>(null);

  const handleSelectCrew = (crew: Crew) => {
    setCrew(crew);
  };

  function handleOpenModal() {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        // if (ev.detail.role === 'confirm') {
        //   setMessage(`Hello, ${ev.detail.data}!`);
        // }
      },
    });
  }

  useEffect(() => {
    setCrew(null);
  }, []);

  return (
    <Content
      isVerticalScroll={true}
      header={<Header title="Generate Demo Workcard" hasProfile={true} backBtn={true} />}
    >
      <div className=" px-4 sm:px-0 sm:ml-[40px] mt-5">
        <div className="">
          <div className="">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto"></div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  {/* <button
                    onClick={() => handleOpenModal()}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                  >
                    <i className="fa-solid fa-plus text-white mr-2"></i>
                    {t('FEATURES.CREW_MGMT.ADD_CREW')}
                  </button> */}
                </div>
              </div>
            </div>

            <div className="space-y-6 sm:space-y-5">
              {/* <CrewMgmtSupervisorList /> */}

              <CrewMgmtList selectCrew={handleSelectCrew} />

              <div className="!mt-[80px]">
                <CrewMgmtEmployeeList selectCrew={selecteCrew!} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};
