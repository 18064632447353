import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { Workcard, WorkcardTask } from '../../models';

export interface WorkcardState {
  selectedWorkcard: Workcard | null;
  selectedWorkcardTask: WorkcardTask | null;
}

const initialState: WorkcardState = {
  selectedWorkcard: null,
  selectedWorkcardTask: null,
};

export const workcardSlice = createSlice({
  name: 'workcard',
  initialState,
  reducers: {
    setSelectedWorkcard: (state, action: PayloadAction<Workcard>) => {
      state.selectedWorkcard = action.payload;
    },
    setSelectedWorkcardTask: (state, action: PayloadAction<WorkcardTask>) => {
      state.selectedWorkcardTask = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedWorkcard } = workcardSlice.actions;
export const { setSelectedWorkcardTask } = workcardSlice.actions;

export default workcardSlice.reducer;
