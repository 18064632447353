import { Switch } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { constants } from 'crypto';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Language } from '../../../../enums/language.enum';
import { Content } from '../../../../layout/content/content';
import { Header } from '../../../../layout/header/Header/Header';
import type { Crew, Risk } from '../../../../models';
import { isUserCrewMember } from '../../../../services/common/profile.utils';
import { classNames } from '../../../../services/common/workcard.utils';
import { useGetCrewsQuery } from '../../../../services/data/dataCreation.service';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import {
  useGetRiskListQuery,
  usePostRiskMutation,
  usePostRiskTranslationMutation,
  useUpdateRiskMutation,
  useUpdateRiskTranslationMutation,
} from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';

export const RiskList: React.FC = () => {
  const { t } = useTranslation();
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const [selectedRow, setRow] = useState<number>(-1);
  const [riskEn, setRiskEn] = useState<Risk[] | null>(null);
  const [riskFr, setRiskFr] = useState<Risk[] | null>(null);
  const [riskFi, setRiskFi] = useState<Risk[] | null>(null);

  const { data: dataProfile, error: errorProfile, refetch: refetchProfile } = useGetMyProfileQuery();

  const [updateRiskTranslation, resUpdateRiskTranslation] = useUpdateRiskTranslationMutation();
  const [postRiskTranslation, resPostRiskTranslation] = usePostRiskTranslationMutation();
  const [updateRisk, resUpdateRisk] = useUpdateRiskMutation();
  const [postRisk, resPostRisk] = usePostRiskMutation();

  const { data, error, isLoading } = useGetCrewsQuery();

  const handleSetActive = async (value: boolean, index: number) => {
    const newRisk: any = Object.assign([], riskEn!);
    newRisk[index].active = value;
    setRiskEn(newRisk);

    const payload: any = {
      id: riskEn && riskEn[index].id,
      title: riskEn && riskEn[index].title,
      active: value,
      approved: true,
      revisionNumber: 0,
      siteId: +selectedSiteId!,
    };

    try {
      await updateRisk({ siteId: selectedSiteId, payload });
    } catch (error) {
      console.log('error', error);
    }
  };

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    clearErrors,
  } = useForm();

  const { data: typeOfRisksEn, refetch: refetchEn } = useGetRiskListQuery({ siteId: selectedSiteId!, language: 'en' });

  const { data: typeOfRisksFr, refetch: refetchFr } = useGetRiskListQuery({ siteId: selectedSiteId!, language: 'fr' });

  const { data: typeOfRisksFi, refetch: refetchFi } = useGetRiskListQuery({ siteId: selectedSiteId!, language: 'fi' });

  const handleCreateRisk = async () => {
    const payload: any = {
      title: '',
      active: true,
      approved: true,
      revisionNumber: 0,
      siteId: +selectedSiteId!,
    };

    try {
      await postRisk({ siteId: selectedSiteId, payload });

      toast.success('Risk created successfully !', { duration: 2000, position: 'top-center' });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleInputOnChange = async (e: any) => {
    const inputVal: string = e.target.value;
    const lang: any = e?.target?.getAttribute('data-lang');
    const index: any = e?.target?.getAttribute('data-index');
    let newRisk: any = null;

    switch (lang) {
      case 'fr':
        newRisk = Object.assign([], riskFr!);
        newRisk[index].title = inputVal;
        setRiskFr(newRisk);
        break;
      case 'en':
        newRisk = Object.assign([], riskEn!);
        newRisk[index].title = inputVal;
        setRiskEn(newRisk);
        break;
      case 'fi':
        newRisk = Object.assign([], riskFi!);
        newRisk[index].title = inputVal;
        setRiskFi(newRisk);
        break;
      default:
        newRisk = Object.assign([], riskEn!);
        newRisk[index].title = inputVal;
        setRiskEn(newRisk);
        break;
    }
  };

  const handleInputOnBlur = async (e: any) => {
    const inputVal: string = e.target.value;
    const id: any = e?.target?.getAttribute('data-id');
    const lang: any = e?.target?.getAttribute('data-lang');
    const titleTranslationId = e?.target?.getAttribute('data-translation-id');

    console.log('inputVal', inputVal);

    if (lang === Language.english) {
      const payload: any = {
        id: +id,
        title: inputVal,
        active: true,
        approved: true,
        revisionNumber: 0,
        siteId: +selectedSiteId!,
      };

      try {
        await updateRisk({ siteId: selectedSiteId, payload });
      } catch (error) {
        console.log('error', error);
      }
    } else {
      const payload: any = {
        title: inputVal,
        language: lang,
      };

      if (titleTranslationId) {
        await updateRiskTranslation({ siteId: selectedSiteId, riskId: id, id: titleTranslationId, payload });
      } else {
        await postRiskTranslation({ siteId: selectedSiteId, riskId: id, payload });
      }
    }

    refetchFr();
    refetchFi();
    refetchEn();
  };

  useEffect(() => {
    if (typeOfRisksEn) {
      setRiskEn([...JSON.parse(JSON.stringify(typeOfRisksEn))]);
    }
  }, [typeOfRisksEn]);

  useEffect(() => {
    if (typeOfRisksFr) {
      setRiskFr([...JSON.parse(JSON.stringify(typeOfRisksFr))]);
    }
  }, [typeOfRisksFr]);

  useEffect(() => {
    if (typeOfRisksFi) {
      setRiskFi([...JSON.parse(JSON.stringify(typeOfRisksFi))]);
    }
  }, [typeOfRisksFi]);

  return (
    <Content isVerticalScroll={true} header={<Header title="Risk List" hasProfile={true} backBtn={true} />}>
      <div className=" px-4 sm:px-0 sm:ml-[40px] mt-5">
        <div className="">
          <div className="">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto"></div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => handleCreateRisk()}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                  >
                    <i className="fa-solid fa-plus text-white mr-2"></i>
                    Add Risk
                  </button>
                </div>
              </div>
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 ">
                <div className="mt-1 sm:col-span-5 sm:mt-0 mb-10">
                  <div className="">
                    <div className="px-4 sm:px-6 lg:px-8 pl-2 sm:!pl-0">
                      <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="">
                            <tr>
                              <th
                                scope="col"
                                className="w-[3%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                              >
                                ID
                              </th>
                              <th
                                scope="col"
                                className="w-[28%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                              >
                                EN
                              </th>
                              <th
                                scope="col"
                                className="w-[28%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                              >
                                FR
                              </th>
                              <th
                                scope="col"
                                className="w-[28%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                              >
                                FI
                              </th>
                              <th
                                scope="col"
                                className="w-[13%]  px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default lg:table-cell"
                              >
                                {t('FEATURES.CREW_MGMT.ACTIVE')}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white dark:bg-secondary-default">
                            {riskEn?.map((risk, idx) => (
                              <tr
                                key={riskEn[idx].id}
                                className={`${idx === selectedRow && 'bg-blue-100 dark:bg-gray-400'} cursor-pointer`}
                              >
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-quartery-default sm:w-auto sm:max-w-none sm:pl-6">
                                  {riskEn[idx].id}
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">{riskEn[idx].id}</dt>

                                    <dt className="sr-only sm:hidden">{riskEn[idx].id}</dt>
                                  </dl>
                                </td>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-gray-400 sm:w-auto sm:max-w-none sm:pl-6">
                                  <input
                                    key={`input-en-${idx}`}
                                    value={riskEn && riskEn[idx].title}
                                    type="text"
                                    onBlur={handleInputOnBlur}
                                    onChange={handleInputOnChange}
                                    data-id={riskEn[idx].id}
                                    data-index={idx}
                                    data-lang="en"
                                    data-translation-id={riskEn[idx].id}
                                    className={`text-black bg-white dark:bg-dark-tertiary dark:text-gray-200 rounded-l border border-gray-400 comment block w-full  focus:border-blue-darker focus:ring-blue-darker h-[40px] mt-2`}
                                  />
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">{t('FEATURES.CREW_MGMT.ACTIVE')}</dt>

                                    <dt className="sr-only sm:hidden">{t('FEATURES.CREW_MGMT.MEMBERS')}</dt>
                                  </dl>
                                </td>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-gray-400 sm:w-auto sm:max-w-none sm:pl-6">
                                  {riskFr && (
                                    <input
                                      key={`input-fr-${idx}`}
                                      value={riskFr[idx].title}
                                      type="text"
                                      onBlur={handleInputOnBlur}
                                      onChange={handleInputOnChange}
                                      data-id={riskFr[idx].id}
                                      data-index={idx}
                                      data-lang="fr"
                                      data-translation-id={riskFr[idx].titleTranslationId}
                                      className={`text-black bg-white dark:bg-dark-tertiary dark:text-gray-200 rounded-l border border-gray-400 comment block w-full  focus:border-blue-darker focus:ring-blue-darker h-[40px] mt-2`}
                                    />
                                  )}
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">{t('FEATURES.CREW_MGMT.ACTIVE')}</dt>

                                    <dt className="sr-only sm:hidden">{t('FEATURES.CREW_MGMT.MEMBERS')}</dt>
                                  </dl>
                                </td>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-gray-400 sm:w-auto sm:max-w-none sm:pl-6">
                                  {riskFi && (
                                    <input
                                      key={`input-fi-${idx}`}
                                      value={riskFi[idx].title}
                                      type="text"
                                      onBlur={handleInputOnBlur}
                                      onChange={handleInputOnChange}
                                      data-id={riskFi[idx].id}
                                      data-index={idx}
                                      data-lang="fi"
                                      data-translation-id={riskFi[idx].titleTranslationId}
                                      className={`text-black bg-white dark:bg-dark-tertiary dark:text-gray-200 rounded-l border border-gray-400 comment block w-full  focus:border-blue-darker focus:ring-blue-darker h-[40px] mt-2`}
                                    />
                                  )}
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">{t('FEATURES.CREW_MGMT.ACTIVE')}</dt>

                                    <dt className="sr-only sm:hidden">{t('FEATURES.CREW_MGMT.MEMBERS')}</dt>
                                  </dl>
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                  <span className="dark:text-gray-300 text-black text-sm top-[-7px] mt-6 mr-2 relative left-[7px] sm:left-[0px] inline-block mb-2">
                                    Active
                                  </span>
                                  <Switch
                                    key={`active-${riskEn[idx].id}`}
                                    data-index={idx}
                                    checked={riskEn && riskEn[idx].active}
                                    onChange={(e) => {
                                      handleSetActive(e, idx);
                                    }}
                                    className={` ${classNames(
                                      riskEn && riskEn[idx].active ? 'bg-blue-900 dark:bg-[#009cde]' : 'bg-gray-200',
                                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
                                    )}`}
                                  >
                                    <span
                                      className={classNames(
                                        riskEn && riskEn[idx].active ? 'translate-x-5' : 'translate-x-0',
                                        'pointer-events-none relative inline-block h-[23px] w-[23px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                      )}
                                    >
                                      <span
                                        className={classNames(
                                          riskEn && riskEn[idx].active
                                            ? 'opacity-0 duration-100 ease-out'
                                            : 'opacity-100 duration-200 ease-in',
                                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                          <path
                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>
                                      <span
                                        className={classNames(
                                          riskEn && riskEn[idx].active
                                            ? 'opacity-100 duration-200 ease-in'
                                            : 'opacity-0 duration-100 ease-out',
                                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <svg
                                          className="h-3 w-3 text-indigo-600"
                                          fill="currentColor"
                                          viewBox="0 0 12 12"
                                        >
                                          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </Switch>{' '}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};
