import { STATUS } from '../../constants';
import { ActivityStatus, WorkcardStatus } from '../../enums/status.enum';
import type { Status, Workcard, WorkcardStatus as WcStatus, WorkcardLocation } from '../../models';

export const getRemainingCards = (data: Workcard[]): Workcard[] => {
  return data?.filter((workcard) => workcard.completedAt === null);
};

export const getNotStartedCards = (data: Workcard[]): Workcard[] => {
  return data?.filter((workcard) => workcard.startedAt === null);
};

export const getStartedCards = (data: Workcard[]): Workcard[] => {
  return data?.filter(
    (workcard) => workcard.startedAt !== null && workcard.firstVisit === null && workcard.secondVisit === null
  );
};

export const getPending1stVisitCards = (data: Workcard[]): Workcard[] => {
  return data?.filter((workcard) => workcard.secondVisit === null && workcard.firstVisit !== null);
};

export const getPending2ndVisitCards = (data: Workcard[]): Workcard[] => {
  return data?.filter((workcard) => workcard.completedAt === null && workcard.secondVisit !== null);
};

export const getCompletedCards = (data: Workcard[]): Workcard[] => {
  return data?.filter((workcard) => workcard.completedAt !== null);
};

export const getWorkcardStepMenuStatus = (workcard: WcStatus): number => {
  let stepMenu = 0;

  if (workcard) {
    if (workcard.startedAt === null) {
      stepMenu = 0;
    } else if (workcard.startedAt !== null && workcard.firstVisit === null && workcard.secondVisit === null) {
      stepMenu = 1;
    } else if (
      workcard.startedAt !== null &&
      workcard.firstVisit === null &&
      workcard.firstInspectionCompleted === true
    ) {
      stepMenu = 2;
    } else if (workcard.completedAt === null && workcard.secondVisit !== null) {
      stepMenu = 4;
    } else if (workcard.secondVisit === null && workcard.firstVisit !== null) {
      stepMenu = 3;
    }
  }

  return stepMenu;
};

export const getCardsBystatusId = (statusId: number, workcards: Workcard[]): Workcard[] => {
  let filteredData: Workcard[] = [];

  switch (statusId) {
    case 1:
      filteredData = workcards;
      break;
    case 2:
      filteredData = getNotStartedCards(workcards);
      break;
    case 3:
      filteredData = getStartedCards(workcards);
      break;
    case 4:
      filteredData = getPending1stVisitCards(workcards);
      break;
    case 5:
      filteredData = getPending2ndVisitCards(workcards);
      break;
    case 7:
      filteredData = getCompletedCards(workcards);
      break;
  }

  return filteredData;
};

export const getTasks = (workcard: Workcard): any => {
  const total = workcard.totalTaskCount;
  const pending = workcard.taskPendingCount;
  const completed = total - pending;

  return {
    total: total,
    completed: completed,
  };
};

export const resizeTextarea = (dataQuestion: string): void => {
  const element: HTMLTextAreaElement | null = document.querySelector(`[data-question=${dataQuestion}]`);

  if (element) {
    element.style.height = 'auto';
    element.style.height = `${element?.scrollHeight}px`;
  }
};

export const classNames = (...classes: any): string => {
  return classes.filter(Boolean).join(' ');
};

export const scrollToErrorField = (firstErrorKey: string | undefined): void => {
  if (firstErrorKey) {
    const errorElement = document.querySelector(`[data-question="${firstErrorKey}"]`) as HTMLElement;
    errorElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }
};

export const getStatusColor = (workcard: Workcard): any => {
  const statusColor = {
    bg: 'bg-workcard-greeting-secondary',
    border: 'border-workcard-greeting-secondary',
    icon: 'icon-greeting',
    text: 'text-workcard-greeting-secondary',
    name: 'LABELS.GREETING',
    stepSkipped: false,
  };

  if (workcard.workcardGreetingSection?.id !== 0) {
    statusColor.bg = 'bg-workcard-greeting-secondary';
    statusColor.border = 'border-workcard-greeting-secondary';
    statusColor.text = 'text-workcard-greeting-secondary';
    statusColor.icon = 'icon-greeting';
    statusColor.name = 'LABELS.GREETING';
    statusColor.stepSkipped = false;
  }

  const isTaskStarted = workcard.workcardTasks?.some((task) => task.activityStatus !== ActivityStatus.notStarted);

  if (isTaskStarted) {
    statusColor.bg = 'bg-workcard-planification-secondary';
    statusColor.border = 'border-workcard-planification-secondary';
    statusColor.text = 'text-workcard-planification-secondary';
    statusColor.icon = 'icon-inspection-planif';
    statusColor.name = 'LABELS.INSPECTION';
    statusColor.stepSkipped = workcard.workcardGreetingSection === null;
  }

  if (workcard.workcardDecisionSection?.id !== 0) {
    statusColor.bg = 'bg-workcard-decision-secondary';
    statusColor.border = 'border-workcard-decision-secondary';
    statusColor.text = 'text-workcard-decision-secondary';
    statusColor.icon = 'icon-decision';
    statusColor.name = 'LABELS.DECISION';
    statusColor.stepSkipped =
      workcard.workcardGreetingSection === null || workcard.workcardPlanificationSection === null;
  }

  if (workcard.workcardExecutionSection?.id !== 0) {
    statusColor.bg = 'bg-workcard-execution-primary';
    statusColor.border = 'border-workcard-execution-primary';
    statusColor.text = 'text-workcard-execution-primary';
    statusColor.icon = 'icon-execution';
    statusColor.name = 'LABELS.EXECUTION';
    statusColor.stepSkipped =
      workcard.workcardGreetingSection === null ||
      workcard.workcardPlanificationSection === null ||
      workcard.workcardDecisionSection === null;
  }

  if (workcard.workcardEndShiftSection?.id !== 0) {
    statusColor.bg = 'bg-workcard-report-secondary';
    statusColor.border = 'border-workcard-report-secondary';
    statusColor.text = 'text-workcard-report-secondary';
    statusColor.icon = 'icon-report';
    statusColor.name = 'LABELS.REPORT';
    statusColor.stepSkipped =
      workcard.workcardGreetingSection === null ||
      workcard.workcardPlanificationSection === null ||
      workcard.workcardDecisionSection === null ||
      workcard.workcardExecutionSection === null;
  }

  return statusColor;
};

export const getStatus = (workcard: Workcard | WcStatus): Status => {
  let status = {
    id: 0,
    title: STATUS.NOT_STARTED,
    name: 'FEATURES.DASHBOARD.NOT_STARTED',
  };

  const isNotStarted: boolean = workcard.startedAt === null;
  const isStarted: boolean =
    workcard.startedAt !== null && workcard.firstVisit === null && workcard.secondVisit === null;
  const isPending1stVisit: boolean = workcard.secondVisit === null && workcard.firstVisit !== null;
  const isPending2ndVisit: boolean = workcard.completedAt === null && workcard.secondVisit !== null;
  const isCompleted: boolean = workcard.completedAt !== null;

  if (isNotStarted) {
    status = {
      id: 0,
      title: STATUS.NOT_STARTED,
      name: 'FEATURES.DASHBOARD.NOT_STARTED',
    };
  }

  if (isStarted) {
    status = {
      id: 1,
      title: STATUS.STARTED,
      name: 'FEATURES.DASHBOARD.STARTED',
    };
  }

  if (isPending1stVisit) {
    status = {
      id: 2,
      title: STATUS.PENDING_1ST_VISIT,
      name: 'FEATURES.DASHBOARD.PENDING_FIRST_VISIT',
    };
  }

  if (isPending2ndVisit) {
    status = {
      id: 3,
      title: STATUS.PENDING_2ND_VISIT,
      name: 'FEATURES.DASHBOARD.PENDING_SECOND_VISIT',
    };
  }

  if (isCompleted) {
    status = {
      id: 4,
      title: STATUS.COMPLETED,
      name: 'FEATURES.DASHBOARD.COMPLETED',
    };
  }

  return status;
};

export const getLocationById = (locations: WorkcardLocation[] | undefined, id: number): any => {
  const location = locations?.find((location) => location.id === id);

  return location || {};
};
