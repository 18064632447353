import { useMsal } from '@azure/msal-react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { setToken, setSiteId, setMsalInstance, setMsalRequest } from '../features/Auth/AuthSlice';
import { useAppSelector, useAppDispatch } from '../hooks/hooks';
import { useGetMyProfileQuery } from '../services/data/profile.service';
import type { RootState } from '../store/store';
import { loginRequest } from '../utils/authConfig';

export const AuthContext = createContext<any>('');

export const useAuth = (): any => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC<{ children: any }> = (props) => {
  const [accessToken, setAccessToken] = useState<any>('');
  const [skip, setSkip] = useState(true);

  const { instance, accounts, inProgress } = useMsal();
  const dispatch = useAppDispatch();
  const token = useSelector((state: RootState) => state.auth.value);
  const siteId = useSelector((state: RootState) => state.auth.siteId);
  const name = accounts[0] && accounts[0].name;
  const { data, error, isLoading } = useGetMyProfileQuery(undefined, { skip });

  const requestAccessToken = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        dispatch(setToken(response.accessToken));
        dispatch(setMsalInstance(instance));
        dispatch(setMsalRequest(request));
        setAccessToken(response.accessToken);
      })
      .catch((e) => {
        instance.acquireTokenRedirect(request);
      });
  };

  useEffect(() => {
    if (token) {
      setSkip(false);
    }
  }, [token]);

  useEffect(() => {
    requestAccessToken();
  }, []);

  useEffect(() => {
    if (data?.businessUnitId) {
      dispatch(setSiteId(+data?.businessUnitId));
    }
  }, [data]);

  return (
    <>
      {accessToken && token && siteId && (
        <AuthContext.Provider value={{ accessToken, setAccessToken }}>{props.children}</AuthContext.Provider>
      )}
    </>
  );
};
