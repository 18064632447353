import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface SettingsState {
  darkmode: boolean;
  lang: string;
  isDashboardCommentCollapsed: boolean;
  isSidebarExpanded: boolean;
  lastUpdate: string;
  refreshData: string;
}

const initialState: SettingsState = {
  darkmode: true,
  lang: 'en',
  isDashboardCommentCollapsed: false,
  isSidebarExpanded: false,
  lastUpdate: '',
  refreshData: '',
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<boolean>) => {
      state.isSidebarExpanded = action.payload;
    },
    toggleDashboardComment: (state, action: PayloadAction<boolean>) => {
      state.isDashboardCommentCollapsed = action.payload;
    },
    toggleDarkmode: (state, action: PayloadAction<boolean>) => {
      state.darkmode = action.payload;
    },
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
    setLastUpdateinfo: (state, action: PayloadAction<string>) => {
      state.lastUpdate = action.payload;
    },
    setRefreshData: (state, action: PayloadAction<string>) => {
      state.refreshData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleDashboardComment } = settingsSlice.actions;
export const { toggleDarkmode } = settingsSlice.actions;
export const { setLang } = settingsSlice.actions;
export const { setLastUpdateinfo } = settingsSlice.actions;
export const { setRefreshData } = settingsSlice.actions;
export const { toggleSidebar } = settingsSlice.actions;

export default settingsSlice.reducer;
