import type { OverlayEventDetail } from '@ionic/core';
import { useIonModal } from '@ionic/react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { CrewMember, Crew } from '../../../../models';
import { isUserCrewMember } from '../../../../services/common/profile.utils';
import { useGetEmployeesQuery } from '../../../../services/data/dataCreation.service';
import {
  useUpdateMyProfileMutation,
  useGetMyProfileQuery,
  useChangeRoleForCrewMutation,
} from '../../../../services/data/profile.service';
import ModalAddWorkcard from '../CrewMgmtModalAddWorkcard/CrewMgmtModalAddWorkcard';

export const CrewMgmtEmployeeList: React.FC<{ selectCrew: Crew }> = (props) => {
  const { t } = useTranslation();

  const { data, error, isLoading } = useGetEmployeesQuery();

  const checkbox: any = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [canGenerateData, setCanGenerateData] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState<any>([]);

  const [present, dismiss] = useIonModal(ModalAddWorkcard, {
    selectedPeople: selectedPeople,
    crewId: props?.selectCrew?.id,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const { data: dataProfile, error: errorProfile, refetch: refetchProfile } = useGetMyProfileQuery();
  const [updateMyProfile, response] = useUpdateMyProfileMutation();
  const [changeRole, res] = useChangeRoleForCrewMutation();

  useLayoutEffect(() => {
    const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < props.selectCrew.crewMembers.length;
    setChecked(selectedPeople.length === props?.selectCrew?.crewMembers?.length);

    setIndeterminate(isIndeterminate);

    if (checkbox?.current) {
      checkbox.current.indeterminate = isIndeterminate;
    }
  }, [selectedPeople]);

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : props.selectCrew.crewMembers);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  function handleOpenModal() {
    present({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
          setSelectedPeople([]);
        }
      },
    });
  }

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  useEffect(() => {
    setSelectedPeople([]);
    setCanGenerateData(isUserCrewMember(props?.selectCrew?.crewMembers, dataProfile?.id));
  }, [props?.selectCrew]);

  useEffect(() => {
    setSelectedPeople([]);
  }, []);

  return (
    <>
      <div className="flex justify-between">
        <label
          htmlFor="username"
          className="block text-base font-bold text-gray-900 dark:text-quartery-default sm:mt-px sm:pt-2 ml-1"
        >
          {t('FEATURES.CREW_MGMT.USERS_LIST')}{' '}
          {props?.selectCrew && <span className="font-normal">({props?.selectCrew?.name})</span>}
        </label>

        {selectedPeople && selectedPeople.length > 0 && (
          <button
            onClick={() => handleOpenModal()}
            type="button"
            className="mr-8 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
          >
            <i className="fa-solid fa-plus text-white mr-2"></i>
            {t('FEATURES.CREW_MGMT.GENERATE_WORKCARD')}
          </button>
        )}
      </div>

      {!props?.selectCrew && (
        <div className="mb-4 mr-4 block">
          <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 !ml-0 p-12 pt-20 text-center hover:border-gray-400 ">
            <i className="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
            <p className="mt-6 block text-base font-medium text-gray-500">{t('FEATURES.CREW_MGMT.NO_CREW_SELECTED')}</p>
            <p className="mt-2 block text-sm font-medium text-gray-500"> &nbsp; </p>
          </div>
        </div>
      )}

      {props?.selectCrew && (
        <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 ">
          <div className="sm:col-span-5 mt-[40px]">
            <div className="">
              <div className="px-4 sm:px-6 lg:px-8 pl-2 sm:!pl-0">
                <div className="-mx-4  shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg mb-10">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50 dark:bg-secondary-default">
                      <tr>
                        <th scope="col" className="w-[5%] relative px-7 sm:w-12 sm:px-6">
                          {canGenerateData && (
                            <input
                              type="checkbox"
                              className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              ref={checkbox}
                              checked={checked}
                              onChange={toggleAll}
                            />
                          )}
                        </th>
                        <th
                          scope="col"
                          className="w-[45%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                        >
                          {t('FEATURES.CREW_MGMT.NAME')}
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default lg:table-cell"
                        >
                          {t('FEATURES.CREW_MGMT.CREW')}
                        </th>
                        <th
                          scope="col"
                          className="w-[30%] px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:table-cell"
                        >
                          {t('FEATURES.CREW_MGMT.ROLE')}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white dark:bg-secondary-default">
                      {props?.selectCrew?.crewMembers.map((user) => (
                        <tr key={user.id}>
                          <td className="relative px-7 sm:w-12 sm:px-6">
                            {selectedPeople.includes(user) && (
                              <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-800" />
                            )}

                            {canGenerateData && (
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-blue-800 focus:ring-indigo-600"
                                value={user.id}
                                checked={selectedPeople.includes(user)}
                                onChange={(e) =>
                                  setSelectedPeople(
                                    e.target.checked
                                      ? [...selectedPeople, user]
                                      : selectedPeople.filter((p: any) => p !== user)
                                  )
                                }
                              />
                            )}
                          </td>
                          <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-quartery-default sm:w-auto sm:max-w-none sm:pl-6">
                            {user.name}
                            <dl className="font-normal lg:hidden">
                              <dt className="sr-only">{t('FEATURES.CREW_MGMT.CREW')}</dt>
                              <dd className="mt-1 truncate text-gray-700">{props?.selectCrew?.name}</dd>
                            </dl>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                            {props?.selectCrew?.name}
                          </td>
                          {/* <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{user.email}</td> */}
                          <td className="px-3 py-4 text-sm text-gray-500">
                            {' '}
                            {user.employeeLevel === 0 ? (
                              <span className="rounded bg-gray-600 text-white px-2 my-2 inline-block">
                                {t('FEATURES.CREW_MGMT.WORKER')}
                              </span>
                            ) : (
                              <span className="rounded bg-gray-600 text-white px-2 my-2 inline-block">
                                {t('FEATURES.CREW_MGMT.SUPERVISOR')}
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
