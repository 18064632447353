import type { OverlayEventDetail } from '@ionic/core';
import {
  IonContent,
  IonHeader,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonDatetimeButton,
  IonModal,
  IonDatetime,
  useIonAlert,
} from '@ionic/react';
import { submit } from 'dom7';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppRolesId } from '../../../../constants';
import type { CrewMember, WorkcardLocation, WorkcardTemplate } from '../../../../models';
import { useGetWorkcardTemplatesQuery, usePostWorkcardsMutation } from '../../../../services/data/dataCreation.service';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import { useGetWorkcardLocationQuery } from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ModalAddWorkcard = ({
  selectedPeople,
  crewId,
  onDismiss,
}: {
  selectedPeople: CrewMember[];
  crewId: number;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const { t } = useTranslation();

  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);

  const [selectedDateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [isModalDateFromOpen, setIsModalDateFromOpen] = useState(false);
  const [selectedTemplate, setTemplate] = useState<WorkcardTemplate | null>(null);
  const [selectedLocation, setLocation] = useState<WorkcardLocation | null>(null);
  const [allTemplates, setAllTemplates] = useState<any>([]);
  const [allLocations, setAllLocations] = useState<any>([]);
  const [selectedShift, setShift] = useState(0);

  const [presentAlert, hideAlert] = useIonAlert();

  const [generateWorkcard, res] = usePostWorkcardsMutation();
  const { data: userProfile } = useGetMyProfileQuery();
  const { data: templates } = useGetWorkcardTemplatesQuery({ siteId: selectedSiteId! });
  const { data: locations } = useGetWorkcardLocationQuery({
    siteId: selectedSiteId!,
  });

  const modalDateFrom = useRef<HTMLIonModalElement>(null);

  const onWillDismissDateFrom = (ev: CustomEvent<OverlayEventDetail>) => {
    setIsModalDateFromOpen(false);
  };

  const handleDateFromChanged = (ev: any) => {
    setDateFrom(ev.detail.value);

    if (modalDateFrom && modalDateFrom.current) {
      modalDateFrom.current.dismiss();
    }
  };

  const submit = () => {
    try {
      const workcardCreationRequests: any = [];

      selectedPeople?.map((user) => {
        const workcardCreationRequest = {
          siteId: userProfile?.sites[0]?.id,
          workcardTemplateId: selectedTemplate?.id,
          crewId: crewId,
          startDate: selectedDateFrom,
          shiftType: selectedShift,
          equipment: '',
          location: selectedLocation?.id,
          employees: [
            {
              employeeId: user.employeeId,
              employeeLevel: AppRolesId.EMPLOYEE,
            },
          ],
        };

        workcardCreationRequests.push(workcardCreationRequest);
      });

      generateWorkcard({ payload: { workcardCreationRequests: workcardCreationRequests } });

      toast.success(t('FEATURES.CREW_MGMT.WORKCARD_GENERATED_SUCCESSFULLY'), {
        duration: 2000,
        position: 'top-center',
      });
    } catch (error) {
      //
    }
  };

  const handleTemplateChanged = (ev: any) => {
    const template = templates?.find((template: WorkcardTemplate) => template.id === ev.value);

    setTemplate(template!);

    hideAlert();
  };

  const handleLocationChanged = (ev: any) => {
    const location = locations?.find((location: WorkcardLocation) => location.id === ev.value);

    setLocation(location!);

    hideAlert();
  };

  const onOptionChange = (e: any) => {
    setShift(+e.target.value);
  };

  useEffect(() => {
    const allTempl: any = [];

    if (templates) {
      setTemplate(templates[0]);

      templates.map((template, idx) => {
        const temp = {
          label: template.name,
          type: 'radio',
          value: template.id,
          checked: idx === 0,
          handler: (e: any) => handleTemplateChanged(e),
        };

        allTempl.push(temp);
      });
    }

    setAllTemplates(allTempl);
  }, [templates]);

  useEffect(() => {
    const allLoc: any = [];

    if (locations) {
      setLocation(locations[0]);

      locations.map((location: any, idx: number) => {
        const temp = {
          label: location.name,
          type: 'radio',
          value: location.id,
          checked: idx === 0,
          handler: (e: any) => handleLocationChanged(e),
        };

        allLoc.push(temp);
      });
    }

    setAllLocations(allLoc);
  }, [locations]);

  useEffect(() => {
    if (res && res.isSuccess) {
      onDismiss(inputRef.current?.value, 'confirm');
    }
  }, [res]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {' '}
            <span className="dark:text-quartery-default">Generate Workcard</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h4 className="mb-3 text-base font-medium text-gray-900 dark:text-white">Date</h4>
        <div className="cursor-pointer relative border border-gray-300 dark:border-primary-default dark:bg-secondary-default bg-white rounded-xl min-w-[75px] lg:w-[110px] xl:w-44 py-1.5 sm:py-2 px-2 sm:px-4 flex xl:flex-row flex-col xl:items-center sm:my-2 xl:space-y-0 space-y-3">
          <IonDatetimeButton className="inline" datetime="date1"></IonDatetimeButton>

          <IonModal
            ref={modalDateFrom}
            keepContentsMounted={true}
            isOpen={isModalDateFromOpen}
            onWillDismiss={(ev) => onWillDismissDateFrom(ev)}
          >
            <IonDatetime
              value={selectedDateFrom}
              onIonChange={handleDateFromChanged}
              presentation="date"
              id="date1"
            ></IonDatetime>
          </IonModal>
        </div>

        <ul className="grid gap-6 w-full md:grid-cols-2">
          <li>
            <h4 className="mt-6 mb-3 text-base font-medium text-gray-900 dark:text-white">Workcard Template</h4>
            <div
              onClick={() =>
                presentAlert({
                  header: t('FEATURES.DASHBOARD_FILTER.SELECT_WORKCARD_TEMPLATE'),
                  buttons: [],
                  inputs: allTemplates,
                })
              }
              className="cursor-pointer relative border border-gray-300 dark:border-primary-default dark:bg-secondary-default bg-white rounded-xl min-w-[75px] lg:w-[110px] xl:w-44 py-1.5 sm:py-2 px-2 sm:px-4 flex xl:flex-row flex-col xl:items-center sm:my-2 xl:space-y-0 space-y-3"
            >
              <section className="">
                <h2 className=" text-base lg:text-base font-gray-900  flex items-center relative">
                  <span className="dark:text-secondary-light">{selectedTemplate?.name}</span>
                </h2>
              </section>
            </div>
          </li>
          <li>
            <h4 className="mt-6 mb-3 text-base font-medium text-gray-900 dark:text-white">Location</h4>
            <div
              onClick={() =>
                presentAlert({
                  header: t('FEATURES.DASHBOARD_FILTER.SELECT_LOCATION'),
                  buttons: [],
                  inputs: allLocations,
                })
              }
              className="cursor-pointer relative border border-gray-300 dark:border-primary-default dark:bg-secondary-default bg-white rounded-xl min-w-[75px] lg:w-[110px] xl:w-44 py-1.5 sm:py-2 px-2 sm:px-4 flex xl:flex-row flex-col xl:items-center sm:my-2 xl:space-y-0 space-y-3"
            >
              <section className="">
                <h2 className=" text-base lg:text-base font-gray-900  flex items-center relative">
                  <span className="dark:text-secondary-light">{selectedLocation?.name}</span>
                </h2>
              </section>
            </div>
          </li>
        </ul>

        <h4 className="mt-6 mb-3 text-base font-medium text-gray-900 dark:text-white">Shift</h4>
        <ul className="grid gap-6 w-full md:grid-cols-2">
          <li>
            <input
              type="radio"
              id="day"
              name="shift"
              value="0"
              className="hidden peer"
              checked
              onChange={onOptionChange}
            />
            <label
              htmlFor="day"
              className="inline-flex justify-between items-center p-4 w-full text-gray-700 bg-white rounded border-[2px] border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div className="block">
                <div className="w-full">Day</div>
              </div>
            </label>
          </li>
          <li>
            <input type="radio" id="night" name="shift" value="1" className="hidden peer" onChange={onOptionChange} />
            <label
              htmlFor="night"
              className="inline-flex justify-between items-center p-4 w-full text-gray-700 bg-white rounded border-gray-200 border-[2px] cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div className="block">
                <div className="w-full">Night</div>
              </div>
            </label>
          </li>
        </ul>

        <h4 className="mt-8 mb-3 text-base font-medium text-gray-900 dark:text-white">User(s)</h4>

        <ul
          role="list"
          className="divide-y divide-gray-200 px-2 pt-[6px] bg-gray-100 dark:bg-secondary-default rounded"
        >
          {selectedPeople?.map((user) => (
            <li key={user.id} className="flex py-4">
              <i className="h-9 w-9 rounded-full bg-gray-600 pl-3 pt-[9px] text-white fa-solid fa-user"></i>

              <div className="ml-3">
                <p className="text-sm font-medium text-gray-900 dark:text-gray-100">{user.name}</p>
                <p className="text-sm text-gray-500 dark:text-gray-300">
                  {user.employeeLevel === 0 ? (
                    <span className="rounded bg-gray-600 text-white px-2 my-2 inline-block">
                      {t('FEATURES.CREW_MGMT.WORKER')}
                    </span>
                  ) : (
                    <span className="rounded bg-gray-600 text-white px-2 my-2 inline-block">
                      {t('FEATURES.CREW_MGMT.SUPERVISOR')}
                    </span>
                  )}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start"></IonButtons>
          <IonButtons slot="end">
            <button
              onClick={() => onDismiss(inputRef.current?.value, 'confirm')}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-black dark:text-quartery-default shadow-sm sm:w-auto mr-3 mt-[-5px]"
            >
              Cancel
            </button>

            <button
              onClick={() => submit()}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-green-700 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm sm:w-auto mr-3 mt-[-5px]"
            >
              Submit
            </button>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ModalAddWorkcard;
