import { ClipboardIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export const CopyToClipboard: React.FC<{ textToCopy: string; type?: string }> = (props) => {
  const { t } = useTranslation();

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(props.textToCopy);

      toast.success(t('COMPONENTS.COPY_TO_CLIPBOARD.SUCCESS'), { duration: 2000, position: 'top-center' });
    } catch (error) {
      toast.error(t('COMPONENTS.COPY_TO_CLIPBOARD.ERROR'), { duration: 2000, position: 'top-center' });
    }
  };

  return (
    <>
      {props.type === 'button' ? (
        <button
          onClick={() => {
            handleCopy();
          }}
          type="button"
          className={`py-1 px-1.5 border-solid border rounded-md border-workcard-redbox-primary text-workcard-redbox-primary w-[90px] h-[25px] text-center mt-2 ml-2`}
        >
          <div className="relative top-[-7px]">
            <i className="fa-solid fa-copy mr-1.5 text-[14px]"></i>
            <span className="text-[15px] font-medium leading-[10px] relative top-[-1px]">{t('LABELS.COPY')}</span>
          </div>
        </button>
      ) : (
        <button
          onClick={() => {
            handleCopy();
          }}
          type="button"
          className={`py-1 px-1.5 rounded inline-flex items-center text-gray-500`}
        >
          <i className="fa-solid fa-copy"></i>
        </button>
      )}
    </>
  );
};
