import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { msalConfig } from './utils/authConfig';

import './i18n.js';

const msalInstance = new PublicClientApplication(msalConfig);

// This file is the entry point of EWC application
ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

(function () {
  clearExpiredCacheStorages();

  // We are going to track an updated flag and an activated flag.
  // When both of these are flagged true the service worker was updated and activated.

  if (navigator.serviceWorker) {
    navigator.serviceWorker.register('service-worker.js').then((regitration) => {
      regitration.addEventListener('updatefound', () => {
        const worker = regitration.installing;

        if (worker) {
          worker.addEventListener('statechange', () => {
            if (worker.state === 'activated') {
              // Here is when the activated state was triggered from the lifecycle of the service worker.
              // This will trigger on the first install and any updates.
              checkUpdate();
            }
          });
        }
      });
    });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // This will be triggered when the service worker is replaced with a new one.
      // We do not just reload the page right away, we want to make sure we are fully activated using the checkUpdate function.
      checkUpdate();
    });
  }

  function checkUpdate() {
    window.location.reload();
  }

  // Clear cache endpoint (if query is > 5 days compare to current date)
  async function clearExpiredCacheStorages() {
    const cacheNames = await caches.keys();

    cacheNames.map(async (cacheName) => {
      const cache = await caches.open(cacheName);
      const keys = await cache.keys();
      const nowDateMs = Date.parse(new Date().toDateString());

      // 5 days in ms
      const days5ms = 432000000;

      if (cacheName.includes('eworkcard_cache')) {
        keys.map(async (key: any) => {
          const response = await cache.match(key);
          const keyDate = response?.headers.get('date');

          if (keyDate) {
            const keyDateMs = Date.parse(keyDate);

            if (days5ms + keyDateMs < nowDateMs) {
              await cache.delete(key.url);
            }
          }
        });
      }
    });
  }
})();
