import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import type { RootState } from '../../store/store';

import { SpeechToTextContent } from './components/SpeechToTextContent';

export const SpeechToText: React.FC<{
  class?: string;
  questionId: string;
  onTranscriptEnd: any;
  onListeningEnd: any;
  onListeningStart?: any;
  stopListening?: any;
}> = (props) => {
  const [isListening, setIsListening]: any = useState(false);
  const [isActivateSpeechToText, setIsActivateSpeechToText]: any = useState(false);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  const handleIsListening = () => {
    if (isListening === 1) {
      setIsListening(-1);
    } else {
      handleListeningStart(props.questionId);

      setIsActivateSpeechToText(true);
      setIsListening(1);
    }
  };

  const handleListeningStart = (e: any) => {
    props.onListeningStart(e);
  };

  const handleListeningEnd = (e: any) => {
    setIsListening(-1);
    setIsActivateSpeechToText(false);

    if (e.transcript) {
      props.onListeningEnd(e);
    }
  };

  const handleTranscriptEnd = (e: any) => {
    props.onTranscriptEnd(e);
  };

  useEffect(() => {
    if (props.stopListening !== 0) {
      setIsListening(-1);
    }
  }, [props.stopListening]);

  return (
    <button
      disabled={!isOnline}
      type="button"
      onClick={(e: any) => {
        handleIsListening();
      }}
      className={`${props.class} ${!isOnline && 'pointer-events-none bg-gray-400'} ${
        isActivateSpeechToText ? 'bg-red-700' : 'bg-gray-300 dark:bg-dark-primary border-l border-gray-600'
      } relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-xl px-3 py-2 text-sm font-semibold w-[40px] text-center border-r border-solid border-[3px] border-r-[2px] dark:border-dark-tertiary h-auto`}
    >
      {isActivateSpeechToText ? (
        <SpeechToTextContent
          key={props.questionId}
          isListening={isListening}
          questionId={props.questionId}
          onListeningEnd={handleListeningEnd}
          onTranscriptEnd={handleTranscriptEnd}
        ></SpeechToTextContent>
      ) : (
        <>
          <span className="active cursor-pointer inline-flex items-center  h-full w-full text-[18px] font-medium text-[#646464] text-center">
            <i className={`fa-solid fa-microphone`}></i>
          </span>
        </>
      )}
    </button>
  );
};
