import { IonApp, setupIonicReact, IonContent } from '@ionic/react';
import {
  ReactPlugin,
  withAITracking,
  AppInsightsErrorBoundary,
  AppInsightsContext,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { Router } from './router/router';
import { persistor, store } from './store/store';

import './theme/style.scss';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();

// Microsoft Azure Monitoring tool for frontend
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONN_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();
export const azureAppInsights = appInsights.appInsights;

window.addEventListener('unhandledrejection', (event) => {
  azureAppInsights.trackException({ exception: event.reason.message });
});

setupIonicReact({
  swipeBackEnabled: false,
  mode: 'ios',
});

// main component of EWC application
const App: React.FC = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary
          onError={() => {
            return <>An Error occurs !</>;
          }}
          appInsights={reactPlugin}
        >
          <IonApp>
            <IonContent forceOverscroll={false} fullscreen className="relative flex">
              <Router />
            </IonContent>
          </IonApp>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    </PersistGate>
  </Provider>
);

export default withAITracking(reactPlugin, App);
