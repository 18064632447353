export enum Status {
  notStarted = 0,
  pending1stVisit = 1,
  pending2nVisit = 2,
  completed = 3,
}

export enum WorkcardStatus {
  notStarted = 'Not Started',
  started = 'Started',
  firstInspectionCompleted = 'First Inspection Completed',
  pending1stVisit = 'Pending 1st Visit',
  pending2nVisit = 'Pending 2nd Visit',
  completed = 'Completed',
}

export enum ActivityStatus {
  notStarted = 'NotStarted',
  started = 'Started',
  completed = 'Completed',
}

export enum InspectionQuestionsStatus {
  pending = 'pending',
  check = 'check',
  check_warning = 'check_warning',
  warning = 'warning',
}
