import type { Crew, DataCreationEmployee, WorkcardTemplate, WorkcardLocation, AddUserToCrew } from '../../models';

import { baseQuery, createApi } from './base.service';

// this API service is used for admin page
// Define a service using a base URL and expected endpoints
export const dataCreationService = createApi({
  reducerPath: 'dataCreationService',
  baseQuery: baseQuery,
  tagTypes: ['dataCreation'],
  endpoints: (builder) => ({
    getWorkcardTemplates: builder.query<WorkcardTemplate[], { siteId: number }>({
      query: (arg) => {
        const { siteId } = arg;
        const query = `DataCreation/WorkcardTemplates?siteId=${siteId}`;

        return query;
      },
    }),
    getEmployees: builder.query<DataCreationEmployee[], void>({
      query: () => {
        const query = `DataCreation/employees`;

        return query;
      },
      providesTags: ['dataCreation'],
    }),
    getCrews: builder.query<Crew[], void>({
      query: () => {
        const query = `DataCreation/Crews`;

        return query;
      },
      providesTags: ['dataCreation'],
    }),
    postWorkcards: builder.mutation({
      query: ({ payload }) => ({
        url: `DataCreation/Workcards`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['dataCreation'],
    }),
    AddUserToCrew: builder.mutation({
      query: (payload: AddUserToCrew) => ({
        url: `CrewMember`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['dataCreation'],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetEmployeesQuery,
  useGetCrewsQuery,
  useGetWorkcardTemplatesQuery,
  usePostWorkcardsMutation,
  useAddUserToCrewMutation,
} = dataCreationService;
