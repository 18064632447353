import { IonContent, IonHeader, IonFooter, IonPage, IonTitle, IonToolbar, IonButtons } from '@ionic/react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ModalAddCrew = ({
  onDismiss,
}: {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const inputRef = useRef<HTMLIonInputElement>(null);
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {' '}
            <span className="dark:text-quartery-default">Add Crew</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h4 className="mb-3 text-base font-medium text-gray-900 dark:text-white">Role</h4>
        <ul className="grid gap-6 w-full md:grid-cols-2">
          <li>
            <input
              type="radio"
              id="hosting-small"
              name="hosting"
              value="hosting-small"
              className="hidden peer"
              required
            />
            <label
              htmlFor="hosting-small"
              className="inline-flex justify-between items-center p-4 w-full text-gray-700 bg-white rounded border-[2px] border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div className="block">
                {/* <div className="w-full text-lg font-semibold">Supervisor</div> */}
                <div className="w-full">Supervisor</div>
              </div>
            </label>
          </li>
          <li>
            <input type="radio" id="hosting-big" name="hosting" value="hosting-big" className="hidden peer" />
            <label
              htmlFor="hosting-big"
              className="inline-flex justify-between items-center p-4 w-full text-gray-700 bg-white rounded border-gray-200 border-[2px] cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div className="block">
                {/* <div className="w-full text-lg font-semibold">Member</div> */}
                <div className="w-full">Member</div>
              </div>
            </label>
          </li>
        </ul>

        <h4 className="mt-8 mb-3 text-base font-medium text-gray-900 dark:text-white">User</h4>
        <form>
          <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter a Name or Employee ID"
              required
            />
            <button
              type="submit"
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 dark:bg-[#009cde] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2  dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Search
            </button>
          </div>
        </form>

        <ul role="list" className="divide-y divide-gray-200 px-2 pt-2 bg-gray-100 dark:bg-secondary-default rounded">
          <li className="flex py-4">
            <img
              className="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900 dark:text-gray-100">Jon Doe</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">jon.doe@agnicoeagle.com</p>
            </div>
          </li>

          <li className="flex py-4">
            <img
              className="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900 dark:text-gray-100">Jane Doe</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">jane.doe@agnicoeagle.com</p>
            </div>
          </li>

          <li className="flex py-4">
            <img
              className="h-10 w-10 rounded-full"
              src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt=""
            />
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900 dark:text-gray-100">Ryan Doe</p>
              <p className="text-sm text-gray-500 dark:text-gray-300">ryan.doe@agnicoeagle.com</p>
            </div>
          </li>
        </ul>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot="start"></IonButtons>
          <IonButtons slot="end">
            <button
              onClick={() => onDismiss(inputRef.current?.value, 'confirm')}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent  px-4 py-2 text-sm font-medium text-black dark:text-quartery-default shadow-sm sm:w-auto mr-3"
            >
              Cancel
            </button>

            <button
              onClick={() => onDismiss(inputRef.current?.value, 'confirm')}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-700 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-offset-2 sm:w-auto mr-3"
            >
              Submit
            </button>

            {/* <IonButton onClick={() => onDismiss(inputRef.current?.value, 'confirm')}>Confirm</IonButton> */}
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ModalAddCrew;
