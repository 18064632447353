import { useMsal } from '@azure/msal-react';
import { Dialog, Transition } from '@headlessui/react';
import { UserIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useEffect, useRef, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../hooks/hooks';
import { selectMenuItem } from '../../layout/Sidebar/SidebarSlice';
import { LastUpdateInfo } from '../LastUpdateInfo/LastUpdateInfo';
import { OfflineBanner } from '../OfflineBanner/OfflineBanner';

export const TopMenuModal: React.FC<{ isOpenPanel?: any }> = (props) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);
  const [open, setOpen] = useState(false);

  const mountedRef = useRef(true);

  const mailAPD = `mailto:apd.services@agnicoeagle.com?subject=E-WORKCARD: Help / Feedback`;

  const mediaQuery = '(max-width: 768px)';

  const gotoRoute = (route: string) => {
    dispatch(selectMenuItem(route));
    router.push(`/${route}`, 'back');
  };

  function handleSignOutClick(instance: any) {
    const logoutRequest = {
      account: instance.getAccountByHomeId(process.env.REACT_APP_CLIENT_ID!),
      postLogoutRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
      mainWindowRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
    };
    instance.logoutRedirect(logoutRequest);
  }

  useEffect(() => {
    if (props.isOpenPanel) {
      setOpen(true);
    }
  }, [props.isOpenPanel]);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      <i
        onClick={() => {
          setOpen(true);
        }}
        className="fa-solid fa-plus text-[29px] font-bold text-gray-300"
      ></i>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 top-0 flex max-w-full h-[240px]">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-y-[-240px]"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-[-240px]"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen ">
                    <div className="flex h-full flex-col bg-black py-6 shadow-xl rounded-b-2xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-end">
                          <div className="ml-3 flex h-2 items-center">
                            <i
                              onClick={() => {
                                setOpen(false);
                              }}
                              className="cursor-pointer fa-solid fa-xmark text-[29px] font-bold text-gray-300"
                            ></i>
                          </div>
                        </div>
                        <div className="flex justify-center gap-4 mt-7">
                          <div
                            onClick={() => {
                              setOpen(false);
                              gotoRoute('settings');
                            }}
                            className="flex flex-col justify-center text-center bg-gradient-to-r from-[#cbcbcd] to-[#fff] border-[3px] border-white rounded-2xl h-[90px] w-[90px]"
                          >
                            <i className="fa-solid fa-user-gear text-[32px] text-black mb-1 mt-1 ml-1"></i>
                            <span className="uppercase text-black text-[12px] font-semibold">
                              {t('LABELS.SETTING')}
                            </span>
                          </div>
                          <a
                            href={mailAPD}
                            className="flex flex-col justify-center text-center bg-gradient-to-r from-[#cbcbcd] to-[#fff] border-[3px] border-white rounded-2xl h-[90px] w-[90px]"
                          >
                            <i className="fa-solid fa-circle-question text-[32px] text-black mb-1 mt-1"></i>
                            <span className="uppercase text-black text-[12px] font-semibold">{t('LABELS.HELP')}</span>
                          </a>
                          <div
                            onClick={() => handleSignOutClick(instance)}
                            className="flex flex-col justify-center text-center bg-gradient-to-r from-[#cbcbcd] to-[#fff] border-[3px] border-white rounded-2xl h-[90px] w-[90px]"
                          >
                            <i className="fa-solid fa-right-from-bracket text-[32px] text-black mb-1 mt-1 ml-1"></i>
                            <span className="uppercase text-black text-[12px] font-semibold leading-[11px]">
                              {t('LABELS.SIGNOUT')}
                            </span>
                          </div>
                        </div>
                        <OfflineBanner></OfflineBanner>
                        <LastUpdateInfo></LastUpdateInfo>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default TopMenuModal;
