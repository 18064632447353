import { useIonViewWillEnter } from '@ionic/react';

import { Content } from '../../layout/content/content';
import { Header } from '../../layout/header/Header/Header';
import { useGetSitesQuery } from '../../services/data/site.service';

export const Sites: React.FC = () => {
  const getImageUrl = (siteId: number): string => {
    let imgUrl = '';

    switch (siteId) {
      case 62:
        imgUrl = './assets/img/laronde-site.png';
        break;
      case 82:
        imgUrl = './assets/img/finland-site.jpg';
        break;
      default:
        imgUrl = './assets/img/australia-site.jpg';
        break;
    }

    return imgUrl;
  };

  const { data, refetch } = useGetSitesQuery();

  useIonViewWillEnter(() => {
    refetch();
  });

  return (
    <Content header={<Header title="Sites" hasProfile={true} />}>
      <div className="relative px-6 pt-2  lg:px-8 pb-10">
        <div className="absolute inset-0">
          <div className="h-1/3  sm:h-2/3" />
        </div>
        <div className="relative mx-auto max-w-7xl">
          <div className="mx-auto md:mt-12 md:flex items-center justify-center flex-basis space-x-5 max-w-none block ">
            {data &&
              data.map((site) => (
                <div
                  key={site.businessUnitId}
                  className="flex flex-basis flex-col overflow-hidden rounded-lg shadow-lg basis-full  max-w-[400px] m-auto md:m-0 mb-10 md:mb-0"
                >
                  <div className="flex-shrink-0">
                    <img className="h-48 w-full object-cover" src={getImageUrl(site.businessUnitId)} alt="" />
                  </div>
                  <div className="flex flex-1 flex-col justify-between bg-white dark:bg-secondary-default p-6 h-[500px]">
                    <div className="flex-1">
                      <p className="text-xl font-semibold text-gray-900 dark:text-quartery-default">{site.title}</p>
                      <p className="mt-3 text-base text-gray-500 dark:text-gray-300">{site.description}</p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Content>
  );
};
