import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import type { RefresherEventDetail } from '@ionic/react';
import React, { useState, Suspense, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useAppSelector } from '../../hooks/hooks';
import { Content } from '../../layout/content/content';
import { WorkcardHeader } from '../../layout/header/WorkcardHeader/WorkcardHeader';
import { useGetTasksByWorkcardIdQuery, useGetWorkcardStatusByIdQuery } from '../../services/data/workcard.service';
import type { RootState } from '../../store/store';

import { MenuContext } from './WorkcardContext';
import { WorkcardDecision } from './components/WorkcardDecision/WorkcardDecision';
import { WorkcardExecution } from './components/WorkcardExecution/WorkcardExecution';
import { WorkcardGreeting } from './components/WorkcardGreeting/WorkcardGreeting';
import { WorkcardReport } from './components/WorkcardReport/WorkcardReport';
import { WorkcardTasks } from './components/WorkcardTasks/WorkcardTasks';
import { TaskContext } from './components/WorkcardTasks/WorkcardTasksContext';

import './Workcard.scss';

export const Workcard: React.FC = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const selectedMenuItem = useAppSelector((state: RootState) => state.sidebar.value);
  const refreshData = useSelector((state: RootState) => state.settings.refreshData);
  const isSidebarExpanded = useAppSelector((state) => state.settings.isSidebarExpanded);
  const lang = useAppSelector((state) => state.settings.lang);

  const [selectedMenu, setselectedMenu] = useState('Greeting');
  const [selectedTaskId, setTaskId] = useState(-1);
  const [seed, setSeed] = useState(1);
  const [disablePullToRefresh, setDisablePullToRefresh] = useState(false);

  const inspectionChecklist: any = React.useRef(null);

  const { data, error, isLoading, refetch } = useGetWorkcardStatusByIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    refetch();

    setSeed(Math.random());

    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  };

  const handleDisablePullToRefresh = (value: boolean) => {
    setDisablePullToRefresh(value);
  };

  const handleSelectTask = (e: any, taskId: number) => {
    setTaskId(taskId);
  };

  useEffect(() => {
    refetch();

    setTimeout(() => {
      setSeed(Math.random());
    }, 1000);
  }, [refreshData]);

  useEffect(() => {
    console.log('selectedMenuItem', selectedMenuItem);
    setselectedMenu(selectedMenuItem);
    refetch();
  }, [selectedMenuItem]);

  useEffect(() => {
    if (lang) {
      navigator.serviceWorker.register('service-worker.js');

      navigator.serviceWorker.ready.then((registration) => {
        if (registration && registration.active) {
          registration.active.postMessage({ type: 'LANGUAGE', language: lang });
        }
      });
    }
  }, [lang]);

  return (
    <MenuContext.Provider value={{ selectedMenu, setselectedMenu }}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <span className="dark:text-quartery-default">{t('FEATURES.WORKCARD.INSPECTION_CHECKLIST')}</span>
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <Content header={<WorkcardHeader backBtn={true} />}>
        <IonRefresher
          disabled={disablePullToRefresh}
          className={`ion-refresher-workcard ${isSidebarExpanded && 'margin-sidebar-open'}`}
          slot="fixed"
          onIonRefresh={handleRefresh}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className="pb-[80px] md:pb-0 w-full ion-content-scroll-host workcard-content relative">
          {selectedMenu === 'Greeting' && (
            <WorkcardGreeting
              onDisablePullToRefresh={(value: boolean) => handleDisablePullToRefresh(value)}
              key={seed}
            ></WorkcardGreeting>
          )}
          {selectedMenu === 'plan-decision' && <WorkcardDecision key={seed}></WorkcardDecision>}
          {selectedMenu === 'Execution' && <WorkcardExecution key={seed}></WorkcardExecution>}
          {selectedMenu === 'Report' && <WorkcardReport key={seed} />}
          {selectedMenu === 'Inspection' && <WorkcardTasks key={seed} onSelectTask={handleSelectTask} />}
        </div>
      </Content>
    </MenuContext.Provider>
  );
};
