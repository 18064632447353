import { Combobox } from '@headlessui/react';
import {
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import type { WorkcardLocation } from '../../models';
import { useGetWorkcardLocationQuery, usePostWorkcardRedboxMutation } from '../../services/data/workcard.service';
import type { RootState } from '../../store/store';
import { SpeechToText } from '../SpeechToText/SpeechToText';

import './RedboxModal.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const RedboxModal = ({
  workcardId,
  siteId,
  taskId,
  onDismiss,
}: {
  workcardId: number;
  siteId: number;
  taskId?: number;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const { t } = useTranslation();
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const [locationId, setLocationId] = useState('');
  const [data, setData] = useState();
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [filteredLocation, setFilteredLocation] = useState<any>([]);
  const [selectedSearchText, setSelectedSearchText] = useState('');

  const { data: locations } = useGetWorkcardLocationQuery({
    siteId: selectedSiteId!,
  });

  const [updateRedbox, resRedbox] = usePostWorkcardRedboxMutation();

  const handleLocationChanged = (ev: any) => {
    setLocationId(ev.detail.value);
  };

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const submit = () => {
    trigger();
    handleSubmit(onSubmit);

    const workerComment = getValues('comment');
    const title = getValues('title');
    const location = getValues('location');

    if (workerComment && title && location?.id) {
      const payload = {
        workersComment: getValues('comment'),
        title: getValues('title'),
        locationId: getValues('location').id,
      };

      updateRedbox({ siteId: selectedSiteId, workcardId: workcardId, payload })
        .unwrap()
        .then(() => {
          onDismiss(null, 'confirm');
        });
    }
  };

  const onSubmit = (data: any) => {
    trigger();
    setData(data);
  };

  const handleListeningEnd = (e: any) => {
    //
  };

  const updateLocation = (location: any) => {
    setSelectedLocation(location);
    setValue('location', location);
  };

  const searchLocation = (search: string) => {
    if (search.length > 2) {
      setSelectedSearchText(search);
      const searchLocations = locations?.filter((location) =>
        location.name.toLowerCase().includes(search.toLowerCase())
      );

      setFilteredLocation(searchLocations);
    } else {
      setSelectedLocation(null);
      setFilteredLocation([]);
      setValue('location', null);
      setSelectedSearchText('');
    }
  };

  const handleTranscriptEnd = (e: any) => {
    let value = e.transcript;

    if (data && data[e.questionId as keyof any]) {
      value = data[e.questionId as keyof any] + ' ' + e.transcript;
    }

    setValue(e.questionId, value, { shouldValidate: false });
  };

  return (
    <IonPage>
      <IonContent className="ion-padding redbox-modal">
        <div className="flex justify-center mb-6">
          <span className="rounded-md border-[2.5px] border-workcard-redbox-primary inline-block px-3.5 py-0 mr-2.5 arrow_box">
            <i className="fa-solid fa-exclamation text-[16px] "></i>
          </span>
          <span className="dark:text-quartery-default">
            {t('FEATURES.WORKCARD.WORKCARD_REPORT.CREATE_WORKER_COMMENT')}
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold flex gap-2 relative md:mb-5">
            {t('FEATURES.WORKCARD.WORKCARD_REPORT.TITLE')}
          </dt>
          <dd className={` relative col-span-6 text-lg text-gray-900 sm:mt-[-18px] mb-3 mt-1.5 `}>
            <div className="mt-0 flex rounded-md shadow-sm">
              <input
                {...register(`title`, { required: true })}
                type="text"
                data-question="title"
                className={`focus:outline-none text-black  rounded-l-xl border  border-gray-400 dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] comment block w-full  h-[50px] mt-2 text-sm`}
              />
              <SpeechToText
                class="mt-[8px] h-[50px] border-l border-black"
                key="title"
                questionId="title"
                onTranscriptEnd={handleTranscriptEnd}
                onListeningEnd={handleListeningEnd}
              ></SpeechToText>
            </div>
            {errors[`title`]?.type === 'required' && (
              <div className="absolute mt-[1px] text-[13px] text-red-600" role="alert">
                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
              </div>
            )}
          </dd>

          <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold flex gap-2 relative pt-7 pb-2 md:mb-5">
            {t('FEATURES.WORKCARD.WORKCARD_REPORT.LOCATION')}
          </dt>
          <dd className={` relative col-span-6 text-lg text-gray-900 sm:mt-[-18px] mb-3 mt-1.5 `}>
            <div className="mt-0  rounded-md shadow-sm">
              <Controller
                control={control}
                name="location"
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Combobox name={name} value={selectedLocation} onChange={updateLocation}>
                    <Combobox.Input
                      autoComplete="off"
                      onChange={(event) => searchLocation(event.target.value)}
                      displayValue={(location: any) => {
                        return location?.name;
                      }}
                      className="focus:outline-none bg-black w-full h-[50px] rounded-xl text-white border border-gray-700 text-[13px]"
                    />
                    {filteredLocation && filteredLocation.length > 0 && (
                      <Combobox.Options className="bg-dark-tertiary border border-gray-500 rounded-md max-h-[200px] absolute w-full z-10 overflow-y-scroll text-white p-2 text-[18px] mt-2">
                        {filteredLocation &&
                          filteredLocation?.map((location: any) => (
                            <Combobox.Option key={location.id} value={location} className="hover:bg-dark-primary  py-2">
                              {location.name}
                            </Combobox.Option>
                          ))}
                      </Combobox.Options>
                    )}
                    {selectedSearchText && filteredLocation && filteredLocation.length === 0 && (
                      <Combobox.Options className="bg-dark-tertiary border border-gray-500 rounded-md max-h-[200px] absolute w-full z-10 overflow-y-scroll text-white p-2 text-[18px] mt-2">
                        <Combobox.Option key="location-empty" value="" className="hover:bg-dark-primary  py-2">
                          <i className="fa-solid fa-triangle-exclamation mr-2 ml-2"></i>
                          No location found
                        </Combobox.Option>
                      </Combobox.Options>
                    )}
                  </Combobox>
                )}
              />

              <div className="h-[10px]">
                {errors[`location`]?.type === 'required' && (
                  <div className="absolute bottom-[-18px] text-[13px] text-red-600" role="alert">
                    <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                    <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                  </div>
                )}
              </div>
            </div>
          </dd>

          <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold flex gap-2 relative pt-4 md:mb-5">
            {t('FEATURES.WORKCARD.WORKCARD_REPORT.COMMENT')}
          </dt>
          <dd className={` relative col-span-6 text-lg text-gray-900 sm:mt-[-18px] mb-3 mt-1.5 `}>
            <div className="mt-0 flex rounded-md shadow-sm">
              <textarea
                {...register(`comment`, { required: true })}
                data-question="comment"
                className={`focus:outline-none text-black  rounded-l-xl border  border-gray-400 dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] comment block w-full  mt-2 text-sm h-[120px]`}
              ></textarea>
              <SpeechToText
                class="mt-[8px] h-[120px] border-l border-black"
                key="comment"
                questionId="comment"
                onTranscriptEnd={handleTranscriptEnd}
                onListeningEnd={handleListeningEnd}
              ></SpeechToText>
            </div>
            {errors[`comment`]?.type === 'required' && (
              <div className="absolute mt-[1px] text-[13px] text-red-600" role="alert">
                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
              </div>
            )}
          </dd>

          <footer className="absolute bottom-6 w-full left-1 px-4">
            <div className="flex justify-between">
              <button
                onClick={() => onDismiss(null, 'confirm')}
                type="button"
                className="inline-flex items-center justify-center  rounded-xl px-4 py-1 w-[120px]   text-workcard-redbox-primary shadow-sm  sm:w-auto mr-3 mt-[-6px] border-[2px] border-workcard-redbox-primary border-solid text-[15px] uppercase font-bold"
              >
                {t('LABELS.CANCEL')}
              </button>
              <button
                onClick={() => submit()}
                type="button"
                className="inline-flex items-center justify-center rounded-xl border border-transparent px-4 py-1 w-[120px]  text-white shadow-sm  focus:outline-none  sm:w-auto mr-3 mt-[-6px] bg-gradient-to-r from-workcard-redbox-primary to-workcard-redbox-secondary text-[15px] uppercase font-bold"
              >
                {t('LABELS.SAVE')}
                <i className="fa-solid fa-circle-right ml-1.5 mt-[-1px] "></i>
              </button>
            </div>
          </footer>
        </form>
      </IonContent>
    </IonPage>
  );
};
