import moment from 'moment';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ConnectivityStatus } from '../../../components/ConnectivityStatus/ConnectivityStatus';
import { EnvName } from '../../../components/EnvName/EnvName';
import { LastUpdateInfo } from '../../../components/LastUpdateInfo/LastUpdateInfo';
import { OfflineBanner } from '../../../components/OfflineBanner/OfflineBanner';
import TopMenuModal from '../../../components/TopMenuModal/TopMenuModal';
import { UserProfileButton } from '../../../components/UserProfileButton/UserProfileButton';
import { DashboardFilterView } from '../../../enums/dashboardFilterView.enum';
import { FilterToggleContext } from '../../../features/Dashboard/DashboardContext';
import { setRefreshData } from '../../../features/Settings/SettingsSlice';
import { useAppSelector, useAppDispatch } from '../../../hooks/hooks';
import { getRemainingCards } from '../../../services/common';
import { isUserSupervisor } from '../../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../../services/data/profile.service';
import { useGetWorkcardsQuery, useLazyGetWorkcardsQuery } from '../../../services/data/workcard.service';
import type { RootState } from '../../../store/store';
import { openHamburgerMenu } from '../../HamburgerMenu/HamburgerMenuSlice';

import { DashboardFilters } from './components/DashboardFilter/DashboardFilter';

export const DashboardHeader: React.FC<unknown> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const mountedRef = useRef(true);

  const [selectedCompletedCards, setCompletedCards] = useState<number>(0);
  const [selectedTotalCards, setTotalCards] = useState<number>(0);
  const [selectedPercentCompletedCard, setPercentCompletedCard] = useState<number>(0);

  const [skip, setSkip] = React.useState(true);
  const [selectedDateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [selectedDateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
  const [selectedShift, setShift] = useState('');
  const [selectedUser, setUser] = useState('');
  const [selectedAllCrew, setAllCrew] = useState(false);
  const [selectedIsCurrentUserAssignedSupervisor, setIsCurrentUserAssignedSupervisor] = useState(true);
  const [lastUpdate, setLastUpdate] = useState('');

  const filterView = useSelector((state: RootState) => state.dashboardFilters.filterView);
  const lang = useAppSelector((state) => state.settings.lang);
  const isSidebarExpanded = useAppSelector((state) => state.settings.isSidebarExpanded);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  const { selectedFilterToggle, setFilterToggle } = useContext(FilterToggleContext);

  const handleOpenHamburgerMenu = (value: boolean) => {
    dispatch(openHamburgerMenu(value));
  };

  const resetFilter = () => {
    setSkip(true);
    setDateFrom(moment().format('YYYY-MM-DD'));
    setDateTo(moment().format('YYYY-MM-DD'));
    setShift('');
    setUser('');
    setSkip(false);
  };

  const setFilter = () => {
    setSkip(true);
    setDateFrom(filters.fromDate || '');
    setDateTo(filters.toDate || '');
    setShift('');
    setUser('');
    setSkip(false);
  };

  useEffect(() => {
    setSkip(true);

    switch (filterView) {
      case DashboardFilterView.AllCrews:
        setAllCrew(true);
        setIsCurrentUserAssignedSupervisor(false);
        break;
      case DashboardFilterView.myCrews:
        setAllCrew(false);
        setIsCurrentUserAssignedSupervisor(false);
        break;
      case DashboardFilterView.assignToMe:
        setAllCrew(true);
        setIsCurrentUserAssignedSupervisor(true);
        break;
      default:
        break;
    }

    setSkip(false);
  }, [filterView]);

  const filters = useSelector((state: RootState) => state.dashboardFilters.filters);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const { data, error, isLoading } = useGetWorkcardsQuery(
    {
      siteId: selectedSiteId!,
      fromDate: selectedDateFrom,
      toDate: selectedDateTo,
      shift: selectedShift,
      userObjectId: selectedUser,
      isAllCrew: selectedAllCrew,
      language: lang,
      isCurrentUserAssignedSupervisor: selectedIsCurrentUserAssignedSupervisor,
    },
    { skip }
  );

  const { data: userProfile } = useGetMyProfileQuery();

  const handleRefresh = () => {
    dispatch(setRefreshData(moment().format('YYYY-MM-DD HH:MM:ss')));
  };

  useEffect(() => {
    if (filters && ((filters.fromDate && filters.toDate) || filters.userObjectId)) {
      setFilter();
    }
  }, [filters]);

  useEffect(() => {
    if (data) {
      const remainingCards = getRemainingCards(data!);
      const totalCards = data?.length || 0;
      const percentCompletedCard = ((totalCards - remainingCards.length) / totalCards) * 100;

      setLastUpdate(data && data[0] && data[0].lastReadingDate!);
      setCompletedCards(totalCards - remainingCards.length);
      setTotalCards(totalCards);
      setPercentCompletedCard(percentCompletedCard);
    }
    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
    };
  }, [data]);

  useEffect(() => {
    if (userProfile) {
      i18n.changeLanguage(lang);
    }
  }, [userProfile]);

  useEffect(() => {
    if (!selectedFilterToggle) {
      resetFilter();
    }
  }, [selectedFilterToggle]);

  return (
    <header
      className={` bg-gradient-to-r lg:bg-gradient-to-b from-black to-dark-tertiary py-2 fixed w-full lg:w-full top-0 z-[100] shadow`}
    >
      <div className="mx-auto px-4 lg:pl-3 lg:pr-4 flex xl:flex xl:items-center xl:justify-between">
        <div className="min-w-0 flex-1">
          <div className="flex justify-between mt-2">
            <div className="lg:hidden">
              <i
                onClick={() => {
                  handleRefresh();
                }}
                className="fa-solid fa-house-chimney text-[25px] text-gray-300 mt-1.5"
              ></i>
            </div>
            <div className="mt-[-8px]  text-center hidden lg:flex">
              <UserProfileButton />
            </div>
            <div className="mt-[-10px] flex flex-col text-center lg:hidden relative">
              <span className="text-center inline-block items-center px-2  text-xs font-normal text-white">
                <span
                  className={`text-center w-[150px] py-0.5 inline-block  rounded ${
                    !isOnline ? 'bg-red-600' : 'bg-[#343434]'
                  }`}
                >
                  {isUserSupervisor(userProfile?.crews, userProfile?.id)
                    ? t('COMPONENTS.USER_PROFILE_BUTTON.SUPERVISOR')
                    : t('COMPONENTS.USER_PROFILE_BUTTON.WORKER')}

                  {!isOnline && ' - ' + t('LABELS.OFFLINE')}
                </span>
                <EnvName></EnvName>
              </span>
              <span className="mt-1.5 ml-2 dark:text-quartery-default font-bold lg:hidden">
                {userProfile && userProfile.name}
              </span>
              <span></span>
            </div>
            <div className="mt-1 lg:hidden">
              <TopMenuModal></TopMenuModal>
            </div>
          </div>
          {/* <div className="flex lg:hidden lg:justify-center ">
            <div className="ml-0 sm:ml-10 w-[500px] mt-1.5">
              <DashboardFilters />
            </div>
          </div> */}
        </div>

        <span className="hidden lg:inline-block">
          <OfflineBanner></OfflineBanner>
          <LastUpdateInfo isDashboard={true}></LastUpdateInfo>
        </span>
      </div>
    </header>
  );
};
