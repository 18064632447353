import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WorkcardSectionType } from '../../enums/workcardSectionType.enum';

export const WarningMessageModifiedSig: React.FC<{ workcardSectionType: number }> = (props) => {
  const { t } = useTranslation();
  const [warningMessage, setWarningMessage] = useState('');

  useEffect(() => {
    switch (props.workcardSectionType) {
      case WorkcardSectionType.Greeting:
        setWarningMessage(t('LABELS.GREETING_WARNING_SIGNATURE').toString());
        break;
      case WorkcardSectionType.Execution:
        setWarningMessage(t('LABELS.EXECUTION_WARNING_SIGNATURE').toString());
        break;
      case WorkcardSectionType.Decision:
        setWarningMessage(t('LABELS.DECISION_WARNING_SIGNATURE').toString());
        break;
      case WorkcardSectionType.EndShift:
        setWarningMessage(t('LABELS.REPORT_WARNING_SIGNATURE').toString());
        break;

      default:
        break;
    }
  }, [props.workcardSectionType]);

  return (
    <>
      <div className="w-full m-auto flex justify-center ">
        <div className="w-[300px] md:w-auto bg-black text-white border-[2px] rounded-md border-red-600 text-left text-[12px] font-semibold leading-[14px] py-1.5 pl-[55px] pr-4 mt-2.5 relative">
          <i className="fa-solid fa-exclamation-triangle absolute text-[35px] left-2 top-[-10px] text-red-600"></i>
          {warningMessage}
        </div>
      </div>
    </>
  );
};
