import { SignalSlashIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../hooks/hooks';
import type { RootState } from '../../store/store';
import { setConnectivityStatus } from '../ConnectivityStatus/ConnectivityStatusSlice';
import { EnvName } from '../EnvName/EnvName';

export const OfflineBanner: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  return (
    <>
      {!isOnline ? (
        <div className="inset-x-0 bottom-0 flex justify-center lg:justify-end text-center lg:text-right pt-[27px] lg:pt-[0px] sm:mt-0 mb-[6px] sm:mb-0">
          <div className="pointer-events-auto flex items-center justify-center lg:justify-end gap-x-6  px-6 lg:px-0 py-0 sm:rounded-md sm:py-2 sm:pl-4 sm:pr-3.5 mt-[-6px] w-full">
            <div className="text-sm leading-6 text-white text-[13px]">
              <>
                <i className="fa-solid fa-triangle-exclamation ml-2 mr-2 text-[17px] text-red-600"></i>
                <strong className="font-semibold uppercase text-red-600">{t('LABELS.NO_INTERNET_CONNEXION')} </strong>
                <EnvName></EnvName>
              </>
            </div>
          </div>
        </div>
      ) : (
        <div className="inset-x-0 bottom-0 flex justify-center lg:justify-end text-center lg:text-right pt-[27px] lg:pt-[0px] sm:mt-0 mb-[6px] sm:mb-0">
          <div className="pointer-events-auto flex items-center justify-center lg:justify-end gap-x-6  px-6 lg:px-0 py-0 sm:rounded-md sm:py-2 sm:pl-4 sm:pr-3.5 mt-[-6px] w-full">
            <div className="text-sm leading-6 text-white">
              <>
                <i className="fa-solid fa-wifi ml-1 mr-2 text-[17px] text-green-500"></i>
                <strong className="font-semibold uppercase text-green-500 text-[13px]">
                  {t('LABELS.YOU_ARE_CONNECTED')}
                </strong>
                <EnvName></EnvName>
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
