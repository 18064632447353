import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Reorder } from 'framer-motion';
import React, { useState, useEffect } from 'react';

import { WorkcardTaskListItemStatus } from './components/WorkcardTaskListItemStatus/WorkcardTaskListItemStatus';

import './WorkcardTaskListItem.scss';

export const WorkcadTaskListItem: React.FC<{
  item: any;
  index: number;
  isReorder: boolean;
}> = (props) => {
  return (
    <Reorder.Item
      key={props.item.id}
      value={props.item}
      className={` cursor-move border-[2px] !border-workcard-greeting-secondary bg-dark-secondary my-3 ml-2 dark:rounded-xl rounded-xl lg:rounded-none dark:lg:!mx-4 my-4 mx-4 shadow-md lg:shadow-none workcardTaskItem  lg:w-full`}
    >
      <div className="">
        <div className="flex flex-row h-[70px]">
          <div className="relative">
            <p className=" bg-gradient-to-b from-workcard-greeting-tertiary to-workcard-greeting-primary h-full rounded-l-lg px-2 pt-[25px] text-[40px] font-bold  leading-5 text-dark-primary ">
              {props.index + 1}
            </p>
          </div>
          <div className="flex-col w-full">
            <div className="mt-[3px]">
              <div className="relative">
                <p className=" text-sm text-gray-500 lg:mt-0 text-end ">
                  <span className="inline-flex items-center rounded  px-2.5 py-0.5 text-[14px] font-bold text-workcard-greeting-secondary">
                    <i className="fa-solid fa-location-dot text-workcard-greeting-secondary mr-1.5 text-[13px] relative top-[-2px]"></i>
                    {props.item.location || 'N/A'}
                  </span>
                </p>
              </div>
            </div>
            <div className="px-2.5 pt-0">
              <div className="">
                <div className={` text-sm  font-bold text-black dark:text-quartery-default`}>{props.item.name}</div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </Reorder.Item>
  );
};
