export const EnvName: React.FC<unknown> = () => {
  return (
    <>
      {process.env.REACT_APP_ENV !== 'PROD' && (
        <div className="absolute lg:relative inline-block top-[-1px] lg:ml-3 lg:top-[0px] right-[-40px] lg:right-[0] font-[600] h-[22px] lg:h-[30px] pt-[3px] text-center w-[44px] lg:w-[59px] bg-cyan-900 rounded-xl shadow text-white">
          {process.env.REACT_APP_ENV}
        </div>
      )}
    </>
  );
};
