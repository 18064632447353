import { useIonRouter } from '@ionic/react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DashboardFilterView } from '../../../../enums/dashboardFilterView.enum';
import { useAppSelector, useAppDispatch } from '../../../../hooks/hooks';
import { selectMenuItem } from '../../../../layout/Sidebar/SidebarSlice';
import type { UserProfile, Workcard } from '../../../../models';
import { getStatusColor, getCardsBystatusId, getStatus, getTasks } from '../../../../services/common/workcard.utils';
import { useGetWorkcardsQuery } from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';
import { setSelectedWorkcard } from '../../../Workcard/WorkcardSlice';
import { FilterToggleContext } from '../../DashboardContext';

import './DashboardCardList.scss';

export const DashboardCardList: React.FC<{ statusTileId: number; isFilterEnabled: boolean }> = (props) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const mountedRef = useRef(true);
  const dispatch = useAppDispatch();

  const selectedDate = useAppSelector((state) => state.dashboardFilterDate.value);
  const filters = useSelector((state: RootState) => state.dashboardFilters.filters);
  const filterView = useSelector((state: RootState) => state.dashboardFilters.filterView);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const [skip, setSkip] = React.useState(false);
  const [selectedDateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [selectedDateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
  const [selectedShift, setShift] = useState('');
  const [selectedUser, setUser] = useState('');
  const [selectedAllCrew, setAllCrew] = useState(false);
  const [selectedIsCurrentUserAssignedSupervisor, setIsCurrentUserAssignedSupervisor] = useState(true);

  const { selectedFilterToggle, setFilterToggle } = useContext(FilterToggleContext);

  const handleViewWorkcard = (item: Workcard) => {
    dispatch(setSelectedWorkcard(item));
    dispatch(selectMenuItem('Greeting'));

    router.push(`/workcard/${item.id}`, 'forward');
  };

  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const [selectedWorkcards, setWorkcards] = useState<Workcard[]>([]);

  let filteredData: Workcard[] = [];

  const { data, currentData, isFetching, error, isLoading, refetch } = useGetWorkcardsQuery(
    {
      siteId: selectedSiteId!,
      fromDate: selectedDateFrom,
      toDate: selectedDateTo,
      shift: selectedShift,
      userObjectId: selectedUser,
      isAllCrew: selectedAllCrew,
      language: lang,
      isCurrentUserAssignedSupervisor: selectedIsCurrentUserAssignedSupervisor,
    },
    { skip }
  );

  const resetFilter = () => {
    setSkip(true);
    setDateFrom(selectedDate);
    setDateTo(moment(selectedDate).format('YYYY-MM-DD'));
    setShift('');
    setUser('');
    setSkip(false);
  };

  useEffect(() => {
    if (!selectedFilterToggle) {
      resetFilter();
    }
  }, [selectedFilterToggle]);

  useEffect(() => {
    if (filters && ((filters.fromDate && filters.toDate) || filters.userObjectId)) {
      setSkip(true);
      setDateFrom(filters.fromDate || '');
      setDateTo(filters.toDate || '');
      setShift(filters.shift || '');
      setUser(filters.userObjectId || '');
      setSkip(false);

      setTimeout(() => {
        refetch();
      }, 50);
    }
  }, [filters]);

  useEffect(() => {
    setSkip(true);

    switch (filterView) {
      case DashboardFilterView.AllCrews:
        setAllCrew(true);
        setIsCurrentUserAssignedSupervisor(false);
        break;
      case DashboardFilterView.myCrews:
        setAllCrew(false);
        setIsCurrentUserAssignedSupervisor(false);
        break;
      case DashboardFilterView.assignToMe:
        setAllCrew(true);
        setIsCurrentUserAssignedSupervisor(true);
        break;
      default:
        break;
    }

    setSkip(false);
  }, [filterView]);

  useEffect(() => {
    if (data) {
      filteredData = getCardsBystatusId(props.statusTileId, data!);
      setWorkcards(filteredData);
    }
    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
    };
  }, [data, props.statusTileId]);

  return (
    <div
      className={`mt-7 lg:mt-10 flex flex-col rounded-xl bg-black ${
        props.isFilterEnabled ? 'lg:h-[calc(100vh-273px)]' : 'lg:h-[calc(100vh-192px)]'
      }`}
    >
      <h1 className="text-center text-[33px] text-gray-500 mt-2.5 hidden lg:block">
        <span className="font-bold">{selectedWorkcards?.length} </span>
        {t('FEATURES.DASHBOARD.CARDS')}
      </h1>
      <div className="overflow-x-auto md:-mx-0 no-scrollbar   mx-4 mt-0 mb-2">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-4">
          <div className="relative overflow-hidden lg:shadow lg:ring-1 lg:ring-black lg:ring-opacity-5 md:rounded-lg">
            <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 "></div>

            <table className="min-w-full flex ">
              <tbody className="pt-2 w-full  md:h-auto">
                {selectedWorkcards.map((item, index) => (
                  <tr
                    onClick={(e) => handleViewWorkcard(item)}
                    key={`workcard-${index}`}
                    className={`mx-2  border-[3px] ${
                      getStatusColor(item).border
                    } rounded-xl  block  mb-4 h-[85px] cursor-pointer bg-black lg:flex lg:justify-between `}
                  >
                    <td className="pl-3 whitespace-nowrap py-2 pr-3 text-sm font-medium text-gray-900 dark:text-quartery-default w-[50%]">
                      <dl className="font-normal max-w-[125px]">
                        <dt className="mb-2 font-semibold">
                          {' '}
                          {item.employees &&
                            item.employees.map((user: any, index: number) => (
                              <span key={`employee-${index}`}>
                                {item.employees.length > 1 ? user.name.replace(/ .*/, '') : user.name}
                                {item.employees.length > 1 && index === 0 && ', '}
                              </span>
                            ))}
                        </dt>
                      </dl>
                      <div className="mt-[-3px] flex">
                        <div
                          className={`rounded ${
                            getStatusColor(item).bg
                          } pb-1 pt-2  py-0.5 text-gray-900 w-[37px] text-center leading-[10px]`}
                        >
                          <span className="font-bold text-[20px]">{item.totalTaskCount}</span>

                          <span className="font-bold text-[8px] block truncate">
                            {item.totalTaskCount > 1 ? t('FEATURES.DASHBOARD.TASKS') : t('FEATURES.DASHBOARD.TASKS')}
                          </span>
                        </div>

                        <span className="inline-block mt-[8px] ml-[7px] uppercase">
                          {moment(item.startDate).format('DD MMM')}
                        </span>

                        {item.shift === 'Night' ? (
                          <i className="fa-solid fa-moon text-[18px] ml-2 mt-2 "></i>
                        ) : (
                          <i className="fa-solid fa-sun text-[18px] ml-2 mt-2"></i>
                        )}

                        <span className="ml-2 mt-2 max-w-[110px] truncate">{item.crewName}</span>
                      </div>
                    </td>

                    <td className="pr-1 whitespace-nowrap px-0 py-4 text-sm text-gray-500 dark:text-quartery-default w-[5%] lg:w-[15%] text-center relative ">
                      <span
                        className={`${getStatusColor(item).icon} ${
                          getStatusColor(item).text
                        } text-[30px] mt-0.5 inline-block`}
                      ></span>
                      <span className={`block ${getStatusColor(item).text} text-[9px] leading-[8px]`}>
                        {t(getStatusColor(item).name)}
                      </span>
                      {/* {getStatusColor(item).stepSkipped && (
                        <span className="rounded-full bg-red-600 p-1 absolute right-[-6px] top-[-10px]">
                          <i className="fa-solid fa-triangle-exclamation text-white text-[17px]"></i>
                        </span>
                      )} */}
                      <span></span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {selectedWorkcards && selectedWorkcards.length === 0 && (
              <div className="mb-4 mx-4 block">
                <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg my-4 p-12 pt-20 text-center hover:border-gray-400 ">
                  <i className="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
                  <p className="mt-6 block text-base font-medium text-gray-500">
                    {t('FEATURES.DASHBOARD.NO_CARD_AVAILABLE')}
                  </p>
                  <p className="mt-2 block text-sm font-medium text-gray-500"> &nbsp; </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
