import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import connectivityStatusReducer from '../components/ConnectivityStatus/ConnectivityStatusSlice';
import userSearchModalReducer from '../components/UserSearchModal.tsx/UserSearchModalSlice';
import authReducer from '../features/Auth/AuthSlice';
import dashboardFiltersReducer from '../features/Dashboard/components/DashboardFilters/DashboardFiltersSlice';
import settingsReducer from '../features/Settings/SettingsSlice';
import siteReducer from '../features/Sites/SiteSlice';
import workcardReducer from '../features/Workcard/WorkcardSlice';
import workcardInspectionChecklistReducer from '../features/Workcard/components/WorkcardTasks/components/WorkcardInspection/WorkcardInspectionChecklist/WorkcardInspectionChecklistSlice';
import hamburgerMenuReducer from '../layout/HamburgerMenu/HamburgerMenuSlice';
import sidebarReducer from '../layout/Sidebar/SidebarSlice';
import dashboardFilterDateReducer from '../layout/header/DashboardHeader/components/DashboardFilter/DashboardFilterSlice';
import { profileService, siteService, workcardService, decisionService, dataCreationService } from '../services/data/';
import { rtkQueryErrorLogger } from '../services/data/global.error.middleware.service';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [
    decisionService.reducerPath,
    profileService.reducerPath,
    dataCreationService.reducerPath,
    workcardService.reducerPath,
    siteService.reducerPath,
    'auth',
    'connectivityStatus',
    'dashboardFilters',
    'dashboardFilterDate',
  ],
};

const rootReducer = combineReducers({
  dashboardFilters: dashboardFiltersReducer,
  workcardInspectionChecklist: workcardInspectionChecklistReducer,
  userSearchModal: userSearchModalReducer,
  auth: authReducer,
  connectivityStatus: connectivityStatusReducer,
  settings: settingsReducer,
  workcard: workcardReducer,
  site: siteReducer,
  sidebar: sidebarReducer,
  hamburgerMenu: hamburgerMenuReducer,
  dashboardFilterDate: dashboardFilterDateReducer,
  [profileService.reducerPath]: profileService.reducer,
  [dataCreationService.reducerPath]: dataCreationService.reducer,
  [workcardService.reducerPath]: workcardService.reducer,
  [decisionService.reducerPath]: decisionService.reducer,
  [siteService.reducerPath]: siteService.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      dataCreationService.middleware,
      profileService.middleware,
      workcardService.middleware,
      decisionService.middleware,
      siteService.middleware,
      rtkQueryErrorLogger
    ),
  reducer: persistedReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

setupListeners(store.dispatch);
