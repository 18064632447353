import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Content } from '../../../../layout/content/content';
import { Header } from '../../../../layout/header/Header/Header';
import { isUserSupervisor, isUserWorkcardOwner } from '../../../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import type { RootState } from '../../../../store/store';
import { WorkcardChecklistOverview } from '../WorkcardGreeting/components/WorkcardChecklistOverview/WorkcardChecklistOverview';
import { WorkcardHeader } from '../WorkcardHeader/WorkcardHeader';
import { WorkcardInspection } from '../WorkcardTasks/components/WorkcardInspection/WorkcardInspection';
import { WorkcardPlanning } from '../WorkcardTasks/components/WorkcardPlanning/WorkcardPlanning';

export const WorkcardTaskDetails: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const selectedWorkcard = useSelector((state: RootState) => state.workcard.selectedWorkcard);
  const selectedWorkcardTask = useSelector((state: RootState) => state.workcard.selectedWorkcardTask);

  const [isWorker, setIsWorker] = useState<boolean>(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const { data: myProfileData } = useGetMyProfileQuery();

  useEffect(() => {
    if (myProfileData) {
      const isUserWorker: boolean = isUserWorkcardOwner(myProfileData?.crews, selectedWorkcard!);
      setIsWorker(isUserWorker);
      setIsSupervisor(isUserSupervisor(myProfileData?.crews, myProfileData?.id));
    }
  }, [myProfileData]);

  return (
    <Content header={<Header title="Workcard Task 1" backBtn={true} hasProfile={false} />}>
      <div className="mt-[-6px] lg:mt-[-31px]">
        {isSupervisor && (
          <div className="relative w-full z-10 mt-4">
            <WorkcardHeader></WorkcardHeader>
          </div>
        )}

        <div className="relative w-full z-10 mt-2">
          <h2 className="w-[80%] block m-auto left-o right-0 relative text-center rounded-lg pt-2 font-bold text-gray-100 text-[18px] mb-1.5">
            {selectedWorkcardTask?.name}
          </h2>
          <p className="m-auto text-center text-gray-500 leading-[13px] text-[13px] mt-0">
            <i className="fa-solid fa-location-dot mr-2"></i>
            <span>{selectedWorkcardTask?.location}</span>
          </p>
        </div>

        {+id !== 0 && (
          <>
            <WorkcardInspection />
          </>
        )}
        {+id === 0 && (
          <>
            <WorkcardChecklistOverview />
          </>
        )}
      </div>
    </Content>
  );
};
