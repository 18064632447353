import { useTranslation } from 'react-i18next';

import { STATUS_ID } from '../../constants';
import type { Status } from '../../models/status.model';

export const StatusTag: React.FC<{ status: Status }> = (props) => {
  const { t } = useTranslation();

  return (
    <span
      className={`${
        props.status.id === STATUS_ID.STARTED ||
        props.status.id === STATUS_ID.PENDING_1ST_VISIT ||
        props.status.id === STATUS_ID.PENDING_2ND_VISIT
          ? `bg-[#0033A0] dark:bg-[#009cde] text-white`
          : ``
      } 
  ${props.status.id === STATUS_ID.NOT_STARTED ? `bg-gray-200 text-black` : ``}  ${
        props.status.id === STATUS_ID.COMPLETED ? `bg-white border border-black text-black` : ``
      }  py-2 px-3 rounded-2xl text-[11px]`}
    >
      {props.status.id === STATUS_ID.NOT_STARTED ? (
        <>
          <i className="fa-solid fa-circle-pause  mr-2"></i> <span>{t('FEATURES.DASHBOARD.NOT_STARTED')}</span>
        </>
      ) : (
        ''
      )}
      {props.status.id === STATUS_ID.STARTED ? (
        <>
          <i className="fa-solid fa-clock  mr-2"></i> <span>{t('FEATURES.DASHBOARD.STARTED')}</span>
        </>
      ) : (
        ''
      )}
      {props.status.id === STATUS_ID.PENDING_1ST_VISIT ? (
        <>
          <i className="fa-solid fa-clock  mr-2"></i> <span>{t('FEATURES.DASHBOARD.PENDING_FIRST_VISIT')}</span>
        </>
      ) : (
        ''
      )}
      {props.status.id === STATUS_ID.PENDING_2ND_VISIT ? (
        <>
          <i className="fa-solid fa-clock  mr-2"></i> <span>{t('FEATURES.DASHBOARD.PENDING_SECOND_VISIT')}</span>
        </>
      ) : (
        ''
      )}
      {props.status.id === STATUS_ID.COMPLETED ? (
        <>
          <i className="fa-solid fa-circle-check  mr-2"></i> <span>{t('FEATURES.DASHBOARD.COMPLETED')}</span>
        </>
      ) : (
        ''
      )}
    </span>
  );
};
