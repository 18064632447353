import { useIonRouter } from '@ionic/react';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ConnectivityStatus } from '../../../components/ConnectivityStatus/ConnectivityStatus';
import { EnvName } from '../../../components/EnvName/EnvName';
import { LastUpdateInfo } from '../../../components/LastUpdateInfo/LastUpdateInfo';
import { OfflineBanner } from '../../../components/OfflineBanner/OfflineBanner';
import { StatusStepper } from '../../../components/StatusStepper/StatusStepper';
import TopMenuModal from '../../../components/TopMenuModal/TopMenuModal';
import { UserProfileButton } from '../../../components/UserProfileButton/UserProfileButton';
import { MenuContext } from '../../../features/Workcard/WorkcardContext';
import { WorkcardTabs } from '../../../features/Workcard/components/WorkcardTabs/WorkcardTabs';
import { useAppSelector, useAppDispatch } from '../../../hooks/hooks';
import { isUserSupervisor } from '../../../services/common/profile.utils';
import { getStatus } from '../../../services/common/workcard.utils';
import { useGetMyProfileQuery } from '../../../services/data/profile.service';
import { useGetWorkcardStatusByIdQuery } from '../../../services/data/workcard.service';
import type { RootState } from '../../../store/store';
import { openHamburgerMenu } from '../../HamburgerMenu/HamburgerMenuSlice';

import { WorkcardStepsMenu } from './components/WorkcardStepsMenu/WorkcardStepsMenu';

import './WorkcardHeader.scss';

type SelectedMenu = (e: React.MouseEvent, value: string) => void;

export const WorkcardHeader: React.FC<{ backBtn?: boolean }> = (props) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const mountedRef = useRef(true);
  const selectedWorkcard = useAppSelector((state) => state.workcard.selectedWorkcard);
  const lang = useAppSelector((state) => state.settings.lang);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  const [getX, setX] = useState(0);
  const [width, setWidth] = useState<any>();
  const [isMobileScreen, setIsMobileScreen] = useState<boolean | null>(null);
  const [selectedPercentStatusWorkcard, setPercentStatusWorkcard] = useState<number>(0);

  const { selectedMenu, setselectedMenu } = useContext(MenuContext);

  const carousel = useRef<HTMLDivElement>(null);
  const innerCarousel = useRef<HTMLDivElement>(null);

  const isSidebarExpanded = useAppSelector((state) => state.settings.isSidebarExpanded);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const { id } = useParams<{ id: string }>();

  const { data, error, isLoading, refetch } = useGetWorkcardStatusByIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const { data: userProfile } = useGetMyProfileQuery();

  const handleOpenHamburgerMenu = (value: boolean) => {
    dispatch(openHamburgerMenu(value));
  };

  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);

  const scrollMenu = (event: any, value?: string) => {
    if (event.matches) {
      setIsMobileScreen(true);

      if (innerCarousel.current) {
        if (value) {
          setX(
            value === 'plan-decision' || value === 'Execution' || value === 'Report' ? (lang === 'en' ? -120 : -200) : 0
          );
        } else {
          setX(
            selectedMenu === 'plan-decision' || selectedMenu === 'Execution' || selectedMenu === 'Report'
              ? lang === 'en'
                ? -82
                : -50
              : 0
          );
        }
      }
    } else {
      setIsMobileScreen(false);
      setX(0);
    }
  };

  mediaQueryList.addEventListener('change', scrollMenu);

  const handleGoHome = () => {
    router.push(`/home`, 'back');
  };

  const handleSelectedMenu = (e: Event, menu: string) => {
    setselectedMenu(menu);
  };

  useEffect(() => {
    setTimeout(() => {
      if (window.matchMedia(mediaQuery).matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 1000);

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <>
      <header
        className={`bg-gradient-to-r lg:bg-gradient-to-b from-black to-dark-tertiary py-2 fixed w-full top-0 z-[100] shadow`}
      >
        <div className="mx-auto px-4 lg:pl-3 lg:pr-4 flex xl:flex xl:items-center xl:justify-between">
          <div className="min-w-0 flex-1">
            <div className="flex justify-between">
              <div className="mt-[0px] text-center hidden lg:flex">
                <UserProfileButton />
              </div>
              <div className="mt-1.5 lg:hidden">
                <i
                  onClick={handleGoHome}
                  className="cursor-pointer fa-solid fa-house-chimney text-[25px] text-gray-300"
                ></i>
              </div>
              <div className="mt-[-10px] flex flex-col text-center lg:hidden relative">
                <span className="text-center inline-block items-center px-2  text-xs font-normal text-white">
                  <span
                    className={`text-center w-[150px] py-0.5 inline-block  rounded ${
                      !isOnline ? 'bg-red-600' : 'bg-[#343434]'
                    }`}
                  >
                    {isUserSupervisor(userProfile?.crews, userProfile?.id)
                      ? t('COMPONENTS.USER_PROFILE_BUTTON.SUPERVISOR')
                      : t('COMPONENTS.USER_PROFILE_BUTTON.WORKER')}

                    {!isOnline && ' - ' + t('LABELS.OFFLINE')}
                  </span>
                  <EnvName></EnvName>
                </span>
                <span className="mt-1.5 ml-2 dark:text-quartery-default font-bold">
                  {userProfile && userProfile.name}
                </span>
              </div>
              <div className="mt-1 lg:hidden">
                <TopMenuModal></TopMenuModal>
              </div>
            </div>
          </div>

          <span className="hidden lg:inline-block">
            <OfflineBanner></OfflineBanner>
            <LastUpdateInfo isDashboard={true}></LastUpdateInfo>
          </span>
        </div>
      </header>
      {isMobileScreen === true && <WorkcardTabs selectedMenu={handleSelectedMenu}></WorkcardTabs>}
    </>
  );
};
