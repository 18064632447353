import { useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import toast, { Toaster } from 'react-hot-toast';

import { azureAppInsights } from '../../App';
import { store } from '../../store/store';

// Error middleware to catch API errors
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => async (action) => {
  if (isRejectedWithValue(action)) {
    console.warn('#### API ERROR', action);

    const msalInstance = store.getState().auth.msalInstance;
    const msalRequest = store.getState().auth.msalRequest;

    // User is logged in but his profile is not found then we log the user out and redirect to login page
    if (msalInstance && action?.payload?.status === 404 && action?.meta?.arg?.endpointName === 'getMyProfile') {
      toast.error(`You do not have access, please contact apd.services@agnicoeagle.com`, {
        duration: 3000,
        position: 'top-center',
      });

      const logoutRequest = {
        account: msalInstance?.getAccountByHomeId(process.env.REACT_APP_CLIENT_ID!),
        postLogoutRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
        mainWindowRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
      };
      msalInstance?.logoutRedirect(logoutRequest);
    }

    if (action?.payload?.status === 401 && msalInstance && msalRequest) {
      msalInstance?.acquireTokenRedirect(msalRequest);
    } else {
      toast.error(`API ERROR: ${action.payload.originalStatus} - ${action.payload.status}`, {
        duration: 3000,
        position: 'top-center',
      });

      // track API errors send to azure application insights
      azureAppInsights.trackException({ exception: action });
    }
  }

  return next(action);
};
