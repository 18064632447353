import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import workcards from '../../../../data/dashboardCardList.json';

export const DashboardCardListLoader: React.FC<unknown> = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-3 sm:mt-6 flex flex-col">
      <h1 className="text-center text-[33px] text-gray-500 mt-2.5">{t('FEATURES.DASHBOARD.CARDS')}</h1>
      <div className="overflow-x-auto md:-mx-0 no-scrollbar   mx-4 mt-0 mb-2">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-4">
          <div className="relative overflow-hidden lg:shadow lg:ring-1 lg:ring-black lg:ring-opacity-5 md:rounded-lg">
            <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 "></div>

            <table className="min-w-full flex">
              <tbody className="pt-2 w-full  md:h-auto ">
                {workcards.map((item, index) => (
                  <tr
                    key={`workcard-${index}`}
                    className={`mx-2  border-[3px] rounded-xl mb-4 h-[85px] cursor-pointer bg-black flex justify-between `}
                  >
                    <td className="pl-3 whitespace-nowrap py-2 pr-3 text-sm font-medium text-gray-900 dark:text-quartery-default w-[80%]">
                      <dl className="font-normal max-w-[125px]">
                        <dt className="mb-2 font-semibold">
                          {' '}
                          <Skeleton height={'20px'} />
                        </dt>
                      </dl>
                      <div className="mt-[-3px] flex">
                        <div className={`rounded pb-1 pt-2  py-0.5 text-gray-900 w-[37px] text-center leading-[10px]`}>
                          <span className="font-bold text-[20px]">
                            <Skeleton height={'20px'} className="inline-block" />
                          </span>
                        </div>

                        <span className="inline-block mt-[8px] ml-[7px] uppercase">
                          <Skeleton height={'20px'} className="inline-block" />
                        </span>

                        {item.shift === 'Night' ? (
                          <i className="fa-solid fa-moon text-[18px] ml-2 mt-2 "></i>
                        ) : (
                          <i className="fa-solid fa-sun text-[18px] ml-2 mt-2"></i>
                        )}

                        <span className="ml-2 mt-2">
                          <Skeleton height={'20px'} className="inline-block" />
                        </span>
                      </div>
                    </td>

                    <td className="pr-1 whitespace-nowrap px-0 py-4 text-sm text-gray-500 dark:text-quartery-default w-[20%] mr-2  text-center relative ">
                      <span className={`text-[30px] mt-0.5 inline-block`}></span>
                      <span className={`block text-[9px] leading-[8px]`}>
                        <Skeleton height={'20px'} className="inline-block" />
                      </span>
                      {/* {getStatusColor(item).stepSkipped && (
                       <span className="rounded-full bg-red-600 p-1 absolute right-[-6px] top-[-10px]">
                         <i className="fa-solid fa-triangle-exclamation text-white text-[17px]"></i>
                       </span>
                     )} */}
                      <span></span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
