import { UserIcon, UsersIcon } from '@heroicons/react/24/solid';
import {
  IonContent,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonAccordionGroup,
  IonAccordion,
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonButtons,
  IonIcon,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../hooks/hooks';
import type { UserProfile } from '../../models';
import { useGetEmployeeSearchQuery } from '../../services/data/profile.service';
import type { RootState } from '../../store/store';

import { toggleIsActive, toggleIsAllSites, addRecentSearch, removeRecentSearch } from './UserSearchModalSlice';

type SelectUser = (value: any) => void;

export const UserSearchModal: React.FC<{ modalRef: any; onSelectUser: SelectUser }> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);
  const [selectedSearchText, setSearchText] = useState('');
  const [skip, setSkip] = useState(true);

  const dataRecentSearch = useSelector((state: RootState) => state.userSearchModal.recentSearch);
  const selectedIsActive = useSelector((state: RootState) => state.userSearchModal.isActive);
  const selectedIsAllSites = useSelector((state: RootState) => state.userSearchModal.isAllSites);

  const { data, error, isLoading, refetch } = useGetEmployeeSearchQuery({ searchText: selectedSearchText }, { skip });

  const mountedRef = useRef(true);

  const mediaQuery = '(max-width: 768px)';

  const [results, setResults] = useState<any[]>([]);

  const handleUserSelect = (e: any, user: any) => {
    if (dataRecentSearch) {
      const recentSearchUser = dataRecentSearch.filter((item) => item.id === user.id);

      if (recentSearchUser.length === 0) {
        dispatch(addRecentSearch(user));
      }
    } else {
      dispatch(addRecentSearch(user));
    }

    props.onSelectUser(user);
    props.modalRef.current?.dismiss();
  };

  const handleAllSiteChange = (e: any) => {
    dispatch(toggleIsAllSites(!selectedIsAllSites));
  };

  const handleSearchChange = (ev: Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;

    if (target && target.value) {
      query = target.value.toLowerCase();
    }

    if (query && query.length > 2) {
      setSearchText(query);
      setSkip(false);

      refetch();

      setSearchText(query);
    } else {
      setResults([]);
    }
  };

  const handleIsActiveChange = (e: any) => {
    dispatch(toggleIsActive(!selectedIsActive));
  };

  const handelDeleteItem = (e: any, item: UserProfile) => {
    e.stopPropagation();

    dispatch(removeRecentSearch(item));
  };

  useEffect(() => {
    if (data) {
      setResults(data);
    }
  }, [data]);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <IonPage>
      {!selectedIsMobileScreen && (
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              {' '}
              <span className="dark:text-quartery-default">
                {t('COMPONENTS.USER_SEARCH_MODAL.SEARCH_USER_OR_CREW')}
              </span>
            </IonTitle>
          </IonToolbar>
        </IonHeader>
      )}

      <IonContent className="ion-padding">
        <IonSearchbar
          showClearButton="always"
          onClick={() => props.modalRef.current?.setCurrentBreakpoint(0.85)}
          placeholder={t('COMPONENTS.USER_SEARCH_MODAL.SEARCH_USER_OR_CREW') + ''}
          debounce={500}
          onIonChange={(ev) => handleSearchChange(ev)}
        ></IonSearchbar>

        {selectedSearchText && results.length === 0 && (
          <div className="mb-4 mx-4 block">
            <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 p-4 pt-10 text-center hover:border-gray-400 ">
              <i className="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
              <p className="mt-6 block text-base font-medium text-gray-500">
                {t('COMPONENTS.USER_SEARCH_MODAL.NO_USER_CREW_FOUND')}
              </p>
              <p className="mt-2 block text-sm font-medium text-gray-500"> &nbsp; </p>
            </div>
          </div>
        )}

        {!selectedSearchText && (
          <div className="mb-5">
            <h3 className="ml-5 text-lg font-semibold py-2">{t('COMPONENTS.USER_SEARCH_MODAL.RECENT_SEARCH')}</h3>
            <IonList>
              {dataRecentSearch &&
                dataRecentSearch.map((result: UserProfile, idx: number) => (
                  <IonItemSliding key={idx}>
                    <IonItem
                      className="cursor-pointer hover:bg-gray-200"
                      onClick={(e) => {
                        handleUserSelect(e, result);
                      }}
                    >
                      <UserIcon className="h-8 w-8 mr-2"></UserIcon>

                      {/* {result.type === 'user' && <UserIcon className="h-8 w-8 mr-2"></UserIcon>}
                    {result.type === 'crew' && <UsersIcon className="h-8 w-8 mr-2"></UsersIcon>} */}
                      <IonLabel>
                        <h2>
                          {result.name}
                          {/* {result.type === 'user' && (
                          <>
                            <span className=" right-12 absolute inline-block items-center rounded-md bg-blue-100 px-2.5 py-1 text-sm font-medium text-blue-800">
                              {result.crew}
                            </span>
                          </>
                        )}
                        {result.type === 'crew' && (
                          <span className="right-12 absolute inline-flex items-center rounded-md bg-blue-100 px-2.5 py-1 text-sm font-medium text-blue-800">
                            users
                            <span className="rounded bg-blue-700 px-1.5 text-white ml-1">{result.usersCount}</span>
                          </span>
                        )} */}
                        </h2>
                        <p> {result.jobPosition}</p>
                        {/* {result.type === 'user' && <p> {result.title}</p>}
                      {result.type === 'crew' && (
                        <>
                          <p>{result.description}</p>
                        </>
                      )} */}
                      </IonLabel>
                      <IonIcon
                        icon={close}
                        size="small"
                        slot="end"
                        onClick={(e) => {
                          handelDeleteItem(e, result);
                        }}
                      ></IonIcon>
                    </IonItem>

                    <IonItemOptions>
                      <IonItemOption>{t('COMPONENTS.USER_SEARCH_MODAL.ADD_WATCHLIST')}</IonItemOption>
                    </IonItemOptions>
                  </IonItemSliding>
                ))}
            </IonList>
          </div>
        )}

        <div className="mb-5">
          <IonList>
            {results.map((result, idx) => (
              <IonItemSliding key={idx}>
                <IonItem
                  className="cursor-pointer hover:bg-gray-200"
                  onClick={(e) => {
                    handleUserSelect(e, result);
                  }}
                >
                  <UserIcon className="h-8 w-8 mr-2"></UserIcon>

                  {/* {result.type === 'user' && <UserIcon className="h-8 w-8 mr-2"></UserIcon>}
                  {result.type === 'crew' && <UsersIcon className="h-8 w-8 mr-2"></UsersIcon>} */}

                  <IonLabel>
                    <h2>
                      {result.name}
                      {/* {result.type === 'user' && (
                        <>
                          <span className=" right-3 absolute inline-block items-center rounded-md bg-blue-100 px-2.5 py-1 text-sm font-medium text-blue-800">
                            {result.crew}
                          </span>
                        </>
                      )}
                      {result.type === 'crew' && (
                        <span className="right-3 absolute inline-flex items-center rounded-md bg-blue-100 px-2.5 py-1 text-sm font-medium text-blue-800">
                          users
                          <span className="rounded bg-blue-700 px-1.5 text-white ml-1">{result.usersCount}</span>
                        </span>
                      )} */}
                    </h2>
                    <p> {result.jobPosition}</p>
                    {/* {result.type === 'user' && <p> {result.title}</p>}
                    {result.type === 'crew' && (
                      <>
                        <p>{result.description}</p>
                      </>
                    )} */}
                  </IonLabel>
                </IonItem>

                <IonItemOptions>
                  <IonItemOption>{t('COMPONENTS.USER_SEARCH_MODAL.ADD_WATCHLIST')}</IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            ))}
          </IonList>
        </div>

        {/* {(!selectedSearchText || (selectedSearchText && results.length === 0)) && (
          <div className="mx-[14px] pt-[8px]">
            <IonAccordionGroup>
              <IonAccordion value="first">
                <IonItem slot="header" color="light">
                  <IonLabel>
                    {t('COMPONENTS.USER_SEARCH_MODAL.OPTIONS')}
                    <span className="text-gray-600 ml-2 text-sm">
                      ({selectedIsActive ? 'Active' : 'All'} {t('COMPONENTS.USER_SEARCH_MODAL.USERS')},{' '}
                      {selectedIsAllSites ? 'All sites' : 'Current site'})
                    </span>
                  </IonLabel>
                </IonItem>
                <div className="ion-padding" slot="content">
                  <IonSegment
                    onIonChange={(e) => handleIsActiveChange(e)}
                    value={selectedIsActive ? 'active' : 'inactive'}
                  >
                    <IonSegmentButton value="active">
                      <IonLabel>{t('COMPONENTS.USER_SEARCH_MODAL.ACTIVE_USERS')}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="inactive">
                      <IonLabel>{t('COMPONENTS.USER_SEARCH_MODAL.ALL_USERS')}</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </div>
                <div className="ion-padding" slot="content">
                  <IonSegment
                    onIonChange={(e) => handleAllSiteChange(e)}
                    value={selectedIsAllSites ? 'allSites' : 'currentSite'}
                  >
                    <IonSegmentButton value="currentSite">
                      <IonLabel>{t('COMPONENTS.USER_SEARCH_MODAL.CURRENT_SITE')}</IonLabel>
                    </IonSegmentButton>
                    <IonSegmentButton value="allSites">
                      <IonLabel>{t('COMPONENTS.USER_SEARCH_MODAL.ALL_SITES')}</IonLabel>
                    </IonSegmentButton>
                  </IonSegment>
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          </div>
        )} */}
      </IonContent>

      {!selectedIsMobileScreen && (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start"></IonButtons>
            <IonButtons slot="end">
              <button
                onClick={() => props.modalRef.current?.dismiss()}
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-700 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-offset-2 sm:w-auto mr-3 mt-[-6px]"
              >
                {t('COMPONENTS.USER_SEARCH_MODAL.CANCEL')}
              </button>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default UserSearchModal;
