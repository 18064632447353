export const AppRoles = {
  ADMIN: 'admin',
  SUPERVISOR: 'supervisor',
  EMPLOYEE: 'employee',
};

export const AppRolesId = {
  ADMIN: 2,
  SUPERVISOR: 1,
  EMPLOYEE: 0,
};
