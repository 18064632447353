import { Switch } from '@headlessui/react';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { useIonModal } from '@ionic/react';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type { FocusEvent } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { LastCrewCommentsLoader } from '../../../../components/LastCrewComments/LastCrewCommentsLoader';
import { Loader } from '../../../../components/Loader/Loader';
import { NoCommentButton } from '../../../../components/NoCommentButton/NoCommentButton';
import { RedboxModal } from '../../../../components/RedboxModal/RedboxModal';
import { SaveIndicator } from '../../../../components/SaveIndicator/SaveIndicator';
import { SpeechToText } from '../../../../components/SpeechToText/SpeechToText';
import { WarningMessageModifiedSig } from '../../../../components/WarningMessageModifiedSig/WarningMessageModifiedSig';
import { WorkcardSectionType } from '../../../../enums/workcardSectionType.enum';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import type { Report, Signature } from '../../../../models';
import { isUserSupervisor, isUserWorkcardOwner } from '../../../../services/common/profile.utils';
import { resizeTextarea, scrollToErrorField } from '../../../../services/common/workcard.utils';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import {
  useGetWorkcardRedboxQuery,
  useGetWorkcardEndshiftQuery,
  useUpdateWorkcardEndshiftMutation,
  usePostWorkcardRedboxSignatureMutation,
  usePostWorkcardEndshiftSignatureMutation,
  useDeleteWorkcardRedboxSignatureMutation,
} from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';
import { setLastUpdateinfo } from '../../../Settings/SettingsSlice';
import { MenuContext } from '../../WorkcardContext';
import { WorkcardApprovalButton } from '../WorkcardApprovalButton/WorkcardApprovalButton';
import { WorkcardHeader } from '../WorkcardHeader/WorkcardHeader';

import { WorkcardRedbox } from './components/WorkcardRedbox';
import { WorkcardReportLastComments } from './components/WorkcardReportLastComments';

import './WorkcardReport.scss';

export const WorkcardReport: React.FC<unknown> = () => {
  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);

  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const selectedSiteId: any = useSelector((state: RootState) => state.auth.siteId);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);
  const dispatch = useAppDispatch();
  const { selectedMenu, setselectedMenu } = useContext(MenuContext);

  const [isSupervisorApproval, setIsSuperviorApproval] = useState(false);

  const [isDailyReportSaved, setIsDailyReportSaved] = useState(false);
  const [isVehicleParkingSaved, setIsVehicleParkingSaved] = useState(false);
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [lastUpdate, setLastUpdate] = useState('');
  const [isWarningModifiedSig, setIsWarningModifiedSig] = useState<boolean>(false);
  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);

  const [listeningValues, setListeningValues]: any = useState({
    dailyReport: 0,
    vehicleParkingLocation: 0
  });

  const selectedWorkcard = useAppSelector((state) => state.workcard.selectedWorkcard);

  const mountedRef = useRef(true);
  const handleOpenModal: any = React.useRef(null);

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    getValues,
  } = useForm();

  const supervisorApprovalButton: any = React.useRef(null);
  const workerApprovalButton: any = React.useRef(null);

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  const {
    data: dataRedbox,
    refetch: refetchRedbox,
    isLoading: isLoadingRedbox,
    isFetching: isFetchingRedbox,
  } = useGetWorkcardRedboxQuery({
    siteId: selectedSiteId,
    workcardId: +id,
  });
  const { data, isLoading, refetch } = useGetWorkcardEndshiftQuery({ siteId: selectedSiteId, workcardId: +id });

  const { data: dataProfile } = useGetMyProfileQuery();

  const [updateEndshift, resEndshift] = useUpdateWorkcardEndshiftMutation();

  const [PostRedboxSignature, { error: errorredboxSig, data: resRedboxSig }] = usePostWorkcardRedboxSignatureMutation();
  const [PostEndshiftSignature, { error: errorEndshiftSig, data: resEndshiftSig }] =
    usePostWorkcardEndshiftSignatureMutation();

  const [deleteWorkcardRedboxSignature, { error: errorDeleteSig, data: resDeleteSig }] =
    useDeleteWorkcardRedboxSignatureMutation();

  const [present, dismiss] = useIonModal(RedboxModal, {
    workcardId: +id,
    siteId: selectedSiteId,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const onSubmit = (data: any) => {
    console.log(data);

    if (isSupervisorApproval && supervisorApprovalButton && supervisorApprovalButton.current) {
      supervisorApprovalButton.current.openModal();
    }

    if (!isSupervisorApproval && workerApprovalButton && workerApprovalButton.current) {
      workerApprovalButton.current.openModal();
    }
  };

  const addNoComment = () => {
    let payload: any = {};

    setValue('dailyReport', 'No comment', { shouldValidate: false });

    payload = {
      dailyReport: 'No comment',
    };

    try {
      updateEndshift({ siteId: selectedSiteId, workcardId: +id, payload })
        .unwrap()
        .then(() => {
          setIsDailyReportSaved(true);

          setTimeout(() => {
            setIsDailyReportSaved(false);
          }, 2000);
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const scrollToFirstError = () => {
    const firstErrorKey = Object.keys(errors).find((key) => errors[key]);

    scrollToErrorField(firstErrorKey);
  };

  const handleListeningStart = (e: any) => {
    const newListeningValues: any = {};
    for (const key in listeningValues) {
      if (key === e) {
        newListeningValues[key] = 0;
      } else {
        newListeningValues[key] = ++listeningValues[key];
      }
    }
    setListeningValues(newListeningValues);
  };

  const handleResizeTextarea = (field: string) => {
    resizeTextarea(field);

    const textareaVal: any = document.querySelector(`[data-question=${field}]`);
    setValue(field, textareaVal && textareaVal.value);

    setTimeout(() => {
      trigger(field);
    }, 300);
  };

  const handleListeningEnd = (e: any) => {
    let payload: any = {};

    payload = {
      [e.questionId]: e.transcript,
    };

    if (data && data[e.questionId as keyof Report]) {
      payload[e.questionId] = data[e.questionId as keyof Report] + ' ' + payload[e.questionId];
    }

    try {
      updateEndshift({ siteId: selectedSiteId, workcardId: +id, payload })
        .unwrap()
        .then(() => {
          if (e.questionId === 'dailyReport') {
            setIsDailyReportSaved(true);
            setTimeout(() => {
              setIsDailyReportSaved(false);
            }, 2000);
          } else if (e.questionId === 'vehicleParkingLocation')
          {
            setIsVehicleParkingSaved(true);
            setTimeout(() => {
              setIsVehicleParkingSaved(false);
            }, 2000);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleTranscriptEnd = (e: any) => {
    let value = e.transcript;

    if (data && data[e.questionId as keyof Report]) {
      value = data[e.questionId as keyof Report] + ' ' + e.transcript;
    }

    setValue(e.questionId, value, { shouldValidate: false });
  };

  const handleInputOnBlur = async (e: any) => {
    const inputVal: string = e.target.value;
    const questionProp: any = e?.target?.getAttribute('data-question');
    const questionVal: any = data?.[questionProp as keyof Report] || '';
    let payload: any = {};

    if (inputVal.trim() !== questionVal.trim()) {
      payload = {
        [questionProp]: inputVal.trim(),
      };

      try {
        await updateEndshift({ siteId: selectedSiteId, workcardId: +id, payload }).unwrap();

        if (questionProp === 'dailyReport') {
          setIsDailyReportSaved(true);
          setTimeout(() => {
            setIsDailyReportSaved(false);
          }, 2000);
        }else if (questionProp === 'vehicleParkingLocation')
          {
            setIsVehicleParkingSaved(true);
            setTimeout(() => {
              setIsVehicleParkingSaved(false);
            }, 2000);
          }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const handleSubmitButton = (isSupervisor: boolean) => {
    trigger();
    setIsSuperviorApproval(isSupervisor);
    handleSubmit(onSubmit);
    scrollToFirstError();
  };

  useEffect(() => {
    refetch();
    refetchRedbox();
  }, []);

  useEffect(() => {
    if (errors) {
      scrollToFirstError();
    }
  }, [errors]);

  useEffect(() => {
    if (data) {
      setLastUpdate(data.lastReadingDate!);
      dispatch(setLastUpdateinfo(data.lastReadingDate!));

      setValue('dailyReport', data.dailyReport, { shouldValidate: false });
      setValue('vehicleParkingLocation', data.vehicleParkingLocation, { shouldValidate: false });
      setValue('preventComment', data.preventComment, { shouldValidate: false });

      resizeTextarea('dailyReport');
      resizeTextarea('vehicleParkingLocation');
      resizeTextarea('preventComment');
    }

    if (
      data &&
      data.postSignatureSectionModifiedOn &&
      ((isSupervisor && !data.supervisorSignature) || (isUserOwner && !data.workerSignature))
    ) {
      setIsWarningModifiedSig(true);
    } else {
      setIsWarningModifiedSig(false);
    }
  }, [data]);

  const handleApproval = () => {
    const payload = {
      employeeLevel: isSupervisorApproval ? 1 : 0,
      signatureCert: '',
    };

    if (isValid) {
      PostEndshiftSignature({ siteId: selectedSiteId, workcardId: +id, payload });
    }
  };

  useEffect(() => {
    if (resRedboxSig) {
      refetch();
    }
  }, [resRedboxSig]);

  useEffect(() => {
    if (resEndshiftSig) {
      if (isSupervisorApproval) {
        supervisorApprovalButton?.current?.approvalConfirmed();
      } else {
        workerApprovalButton?.current?.approvalConfirmed();
      }

      refetchRedbox();
    }
  }, [resEndshiftSig]);

  useEffect(() => {
    if (isSupervisorApproval) {
      supervisorApprovalButton?.current?.closeModal();
    } else {
      workerApprovalButton?.current?.closeModal();
    }
  }, [errorEndshiftSig]);

  useEffect(() => {
    setIsUserOwner(isUserWorkcardOwner(dataProfile?.crews, selectedWorkcard!));
    setIsSupervisor(isUserSupervisor(dataProfile?.crews, dataProfile?.id));
  }, [dataProfile]);

  return (
    <>
      {isLoading && <Loader></Loader>}

      {selectedMenu === 'Report' && (
        <div className="grid grid-cols-6 gap-0 pt-[7px] lg:pt-0 dark:bg-dark-tertiary ">
          <div className="ml-0 col-span-6 relative w-full z-10">
            {isSupervisor && <WorkcardHeader></WorkcardHeader>}

            {isWarningModifiedSig && (
              <WarningMessageModifiedSig workcardSectionType={WorkcardSectionType.EndShift}></WarningMessageModifiedSig>
            )}
          </div>

          <h3 className="col-span-6 text-[17px] font-semibold uppercase leading-6 text-gray-900 dark:text-workcard-report-primary text-center mb-3 mt-3 ">
            {t('FEATURES.WORKCARD.WORKCARD_REPORT.REPORT')}
          </h3>

          <div className="col-span-6 relative grid grid-cols-6 border-t-[7px] bg-workcard-report-tertiary  border-workcard-report-primary my-4  mt-[10px] pt-[24px] pb-6 z-20 md:mb-[100px]">
            <div className="absolute text-center mx-auto w-[53px] h-[53px] left-0 right-0 rounded-full bg-gradient-to-r from-workcard-report-primary to-workcard-report-secondary top-[-27px]">
              <span className="icon-report text-[32px] mt-[10px] inline-block"></span>
            </div>

            <div className="hidden lg:col-span-6 ml-0 relative ">
              <div className=" border-b border-t border-gray-400 bg-gray-100 px-4 py-3 sm:px-6 pl-8 h-[50px]">
                <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
                  <div className="ml-4 mt-2"></div>
                  <div className="ml-4 mt-2 flex-shrink-0"></div>
                </div>
              </div>
            </div>

            <div className="sm:mt-0 col-span-6 lg:col-span-3  relative dark:bg-dark-primary dark:mx-4 dark:rounded-xl dark:mt-5 mx-6 ml-6 overflow-y-auto lg:h-[calc(100vh-200px)] no-scrollbar pl-[7px] sm:pl-0 hidden">
              {(isLoadingRedbox || isFetchingRedbox) && <LastCrewCommentsLoader></LastCrewCommentsLoader>}

              {!isLoadingRedbox && !isFetchingRedbox && <WorkcardReportLastComments></WorkcardReportLastComments>}
            </div>

            <div className="col-span-6  ml-0 relative ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div
                  className={`bg-white dark:bg-dark-secondary dark:mt-4 dark:mx-4 dark:rounded-xl border-xl px-0 pb-3 pt-3 lg:px-6 dark:min-h-0 overflow-y-auto lg:overflow-y-hidden ${
                    isWarningModifiedSig ? 'lg:h-[calc(100vh-330px)]' : 'lg:h-[calc(100vh-290px)]'
                  } no-scrollbar lg:col-span-3`}
                >
                  <div className="grid grid-cols-6">
                    <div
                      className={`sm:-ml-4 dark:ml-0 -ml-1 mt-2 col-span-6 lg:col-span-3 overflow-y-auto ${
                        isWarningModifiedSig ? 'lg:h-[calc(100vh-360px)]' : 'lg:h-[calc(100vh-330px)]'
                      }`}
                    >
                      <div className="ml-0 mt-0">
                        <div className="px-4 py-4 sm:gap-4 sm:px-4">
                          <dt className="leading-[1.1] text-[14px]  text-gray-700 dark:text-quartery-default font-bold">
                            {t('FEATURES.WORKCARD.WORKCARD_REPORT.DAILY_REPORT')}
                          </dt>
                          <div className={`relative mt-1 text-lg text-gray-900 sm:col-span-2 sm:mt-0`}>
                            <div className={`${!isUserOwner && 'invisible absolute'}`}>
                              <div className="mt-0 flex rounded-md shadow-sm">
                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                  <textarea
                                    {...register(`dailyReport`, { required: true })}
                                    onBlur={handleInputOnBlur}
                                    onChange={() => {
                                      handleResizeTextarea('dailyReport');
                                    }}
                                    data-question="dailyReport"
                                    className={`outline-none no-scrollbar text-black bg-white rounded-l-xl border border-gray-400 dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] comment block w-full  focus:border-blue-darker focus:ring-blue-darker mt-2 text-sm pr-[49px]`}
                                  ></textarea>

                                  <div className="h-auto relative mt-2">
                                    <NoCommentButton
                                      val={getValues('dailyReport')}
                                      onNoComment={addNoComment}
                                    ></NoCommentButton>
                                  </div>
                                </div>
                                <SpeechToText
                                  class="mt-[8px] h-auto border-l border-black"
                                  stopListening={listeningValues.dailyReport}
                                  key="dailyReport"
                                  questionId="dailyReport"
                                  onListeningStart={handleListeningStart}
                                  onListeningEnd={handleListeningEnd}
                                  onTranscriptEnd={handleTranscriptEnd}
                                ></SpeechToText>
                              </div>
                              {errors[`dailyReport`]?.type === 'required' && (
                                <div className="absolute mt-[1px] text-[13px] text-red-600" role="alert">
                                  <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                  <span className="font-semibold ">
                                    {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}
                                  </span>
                                </div>
                              )}
                            </div>
                            {!isUserOwner && (
                              <div className={`${data?.dailyReport ? 'text-gray-400' : 'text-red-700'} mt-[7px] `}>
                                {data?.dailyReport ? (
                                  <div className="text-[#646464] text-[14px]">{data?.dailyReport}</div>
                                ) : (
                                  <div>
                                    <i
                                      className={`fa-solid fa-triangle-exclamation ${
                                        errors[`dailyReport`]?.type === 'required' ? 'text-red-600' : 'text-gray-500'
                                      }   pr-1  pb-[6px]`}
                                    ></i>
                                    <span className="text-[13px] text-gray-500 py-1pb-[4.5px]">
                                      {t('FEATURES.WORKCARD.WAITING_FOR_WORKER_ANSWER')}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}

                            <SaveIndicator isSaved={isDailyReportSaved}></SaveIndicator>
                          </div>
                        </div>

                        <div className="px-4 py-4 sm:gap-4 sm:px-4">
                          <dt className="leading-[1.1] text-[14px]  text-gray-700 dark:text-quartery-default font-bold">
                            {t('FEATURES.WORKCARD.WORKCARD_REPORT.VEHICLE_PARKING_LOCATION')}
                          </dt>
                          <div className={`relative mt-1 text-lg text-gray-900 sm:col-span-2 sm:mt-0`}>
                            <div className={`${!isUserOwner && 'invisible absolute'}`}>
                              <div className="mt-0 flex rounded-md shadow-sm">
                                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                  <textarea
                                    {...register(`vehicleParkingLocation`, { required: false })}
                                    onBlur={handleInputOnBlur}
                                    onChange={() => {
                                      handleResizeTextarea('vehicleParkingLocation');
                                    }}
                                    data-question="vehicleParkingLocation"
                                    className={`outline-none no-scrollbar text-black bg-white rounded-l-xl border border-gray-400 dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] comment block w-full  focus:border-blue-darker focus:ring-blue-darker mt-2 text-sm pr-[49px]`}
                                  ></textarea>
                                </div>
                                <SpeechToText
                                  class="mt-[8px] h-auto border-l border-black"
                                  stopListening={listeningValues.vehicleParkingLocation}
                                  key="vehicleParkingLocation"
                                  questionId="vehicleParkingLocation"
                                  onListeningStart={handleListeningStart}
                                  onListeningEnd={handleListeningEnd}
                                  onTranscriptEnd={handleTranscriptEnd}
                                ></SpeechToText>
                              </div>
                            </div>

                            <SaveIndicator isSaved={isVehicleParkingSaved}></SaveIndicator>
                          </div>
                        </div>

                        {selectedIsMobileScreen && (
                          <div className="block lg:hidden">
                            <WorkcardRedbox></WorkcardRedbox>
                          </div>
                        )}

                        <div className="grid grid-cols-2 sm:gap-4 mt-1 lg:mt-10">
                          <div className="mx-4 col-span-2">
                            <div className="m-auto border-t-[1px] border-[#646464] w-full text-center px-4 pt-3.5 mb-4">
                              <h3 className="uppercase font-bold">{t('LABELS.SIGNATURE')}</h3>
                            </div>
                          </div>

                          <div className="relative px-4 py-4 sm:gap-4 sm:px-6 text-center">
                            {data?.supervisorSignature === null && (
                              <dt className="text-[13px] truncate text-[#646464] font-bold mb-2  text-center">
                                {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.SUPERVISOR_APPROVAL')}
                              </dt>
                            )}
                            <WorkcardApprovalButton
                              key="supervisor"
                              userType="Supervisor"
                              approvalStatus={data && data.supervisorSignature}
                              isApprovalButton={true}
                              handleSubmit={() => handleSubmitButton(true)}
                              handleApproval={handleApproval}
                              ref={supervisorApprovalButton}
                              colorButton="report"
                            />
                          </div>
                          <div className="px-4 py-4 sm:gap-4 sm:px-6 text-center">
                            {data?.workerSignature === null && (
                              <dt className="text-[13px] truncate text-[#646464] font-bold mb-2  text-center">
                                {t('FEATURES.WORKCARD.WORKCARD_EXECUTION.WORKER_APPROVAL')}
                              </dt>
                            )}
                            <WorkcardApprovalButton
                              isApprovalButton={true}
                              approvalStatus={data && data.workerSignature}
                              key="worker"
                              userType="Worker"
                              ref={workerApprovalButton}
                              handleSubmit={() => handleSubmitButton(false)}
                              handleApproval={handleApproval}
                              colorButton="report"
                            />
                          </div>

                          {isWarningModifiedSig && (
                            <div className="col-span-2 mb-4 lg:mr-6">
                              <WarningMessageModifiedSig
                                workcardSectionType={WorkcardSectionType.EndShift}
                              ></WarningMessageModifiedSig>
                            </div>
                          )}
                        </div>

                        <div className="ml-4 mt-2 flex-shrink-0"></div>
                      </div>
                    </div>
                    <div
                      className={`col-span-3 hidden lg:block overflow-y-auto ${
                        isWarningModifiedSig ? 'lg:h-[calc(100vh-360px)]' : 'lg:h-[calc(100vh-330px)]'
                      }`}
                    >
                      {!selectedIsMobileScreen && <WorkcardRedbox></WorkcardRedbox>}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
