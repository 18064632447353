import React, { useState, useEffect, Fragment, PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { LastUpdateInfo } from '../../../../../../components/LastUpdateInfo/LastUpdateInfo';
import { useAppDispatch } from '../../../../../../hooks/hooks';
import { useGetTasksByWorkcardIdQuery } from '../../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../../store/store';
import { setSelectedWorkcardTask } from '../../../../WorkcardSlice';
import { WorkcardInspectionQuestions } from '../WorkcardInspectionQuestion/WorkcardInspectionQuestion';

import { WorkcadTaskListItem } from './components/WorkcardTaskListItem/WorkcardTaskListItem';

type ToggleState = (e: React.MouseEvent, value: boolean) => void;
type SelectedTaskId = (e: React.MouseEvent, value: number) => void;

export const WorkcadTaskList: React.FC<{
  selectTaskId: SelectedTaskId;
  toggleState: ToggleState;
  showTaskDetail: boolean;
}> = (props) => {
  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();
  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);
  const [showTaskDetail, setTaskDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const mountedRef = useRef(true);

  const { data, error, isLoading, refetch } = useGetTasksByWorkcardIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
    lang: lang,
  });

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  useEffect(() => {
    setTaskDetail(props.showTaskDetail);
  }, [props.showTaskDetail]);

  const handleSelectItem = (e: React.MouseEvent, selectedItem: any) => {
    props.toggleState(e, true);
    props.selectTaskId(e, selectedItem.id);
    setTaskDetail(true);
    setSelectedItem(selectedItem);
    dispatch(setSelectedWorkcardTask(selectedItem));
  };

  return (
    <>
      <div className=" mt-[0px] lg:mt-0 lg:pb-0 overflow-y-auto lg:h-auto bg-gray-100 dark:bg-transparent ">
        {data && data.length === 0 && (
          <div className="mb-4 mx-4 block">
            <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 p-12 pt-20 text-center hover:border-gray-400 ">
              <i className="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
              <p className="mt-6 block text-base font-medium text-gray-500">
                {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.NO_PLAN_AVAILABLE')}
              </p>
              <p className="mt-2 block text-sm font-medium text-gray-500"> &nbsp; </p>
            </div>
          </div>
        )}

        {data && data.length > 0 && (
          <ul role="list" className=" bg-dark-secondary py-2 mx-4 lg:ml-3 mt-2 rounded-2xl ">
            {selectedIsMobileScreen && <WorkcardInspectionQuestions></WorkcardInspectionQuestions>}

            {data &&
              data.map((item: any, idx: number) => (
                <WorkcadTaskListItem
                  key={`task-1` + item.id}
                  index={idx + 1}
                  selectedItemId={selectedItem?.id}
                  showTaskDetail={showTaskDetail}
                  item={item}
                  onSelectItem={(e: React.MouseEvent, value) => handleSelectItem(e, value)}
                />
              ))}
          </ul>
        )}
      </div>
    </>
  );
};
