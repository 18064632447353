import { useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import type { RootState } from '../../store/store';

export const NoCommentButton: React.FC<{
  class?: string;
  val: string;
  onNoComment: any;
}> = (props) => {
  const [presentAlert] = useIonAlert();
  const { t } = useTranslation();
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  const handleNoComment = () => {
    if (props.val) {
      presentAlert({
        header: t('FEATURES.WORKCARD.DO_YOU_WANT_TO_REPLACE_FIELD_VALUE'),
        buttons: [
          {
            text: t('LABELS.CANCEL'),
            role: 'cancel',
            handler: () => {
              //
            },
          },
          {
            text: t('LABELS.SUBMIT'),
            role: 'confirm',
            handler: () => {
              props.onNoComment();
            },
          },
        ],
      });
    } else {
      props.onNoComment();
    }
  };

  return (
    <div
      className={`${
        !isOnline && 'pointer-events-none bg-gray-400'
      } absolute inset-y-0 right-0 flex pl-2 mr-[1px] pr-1.5 border-x border-gray-700 bg-gray-300 dark:bg-dark-primary border border-[3px] dark:border-dark-tertiary `}
    >
      <kbd
        onClick={() => {
          handleNoComment();
        }}
        className="cursor-pointer inline-flex items-center px-1 font-sans text-md   text-[17px] text-[#646464]"
      >
        <i className="fa-solid fa-comment-slash"></i>
      </kbd>
    </div>
  );
};
