import { useTranslation } from 'react-i18next';

import { StatusTag } from '../../../../components/StatusTag/StatusTag';
import { TaskProgressBar } from '../../../../components/TaskProgressBar/TaskProgressBar';

export const CurrentWorkcard: React.FC<unknown> = () => {
  const { t } = useTranslation();

  const user = {
    name: 'Jon Doe',
    email: 'jon.doe@agnicoeagle.com',
    role: 'Miner',
  };

  const status = {
    id: 1,
    title: 'PENDING 1ST VISIT',
    name: '',
  };

  const tasks = {
    total: 4,
    completed: 2,
  };

  return (
    <>
      <section aria-labelledby="profile-overview-title">
        <div className="overflow-hidden rounded-lg bg-white dark:bg-secondary-default shadow">
          <div className=" p-6">
            <div className="flex items-center justify-between">
              <div className="flex space-x-5">
                <div className="flex-shrink-0">
                  <span className="inline-block h-20 w-20 overflow-hidden rounded-full bg-gray-200">
                    <svg className="h-full w-full text-gray-500" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </div>
                <div className="mt-4 sm:mt-0 pt-1 text-left">
                  <p className="text-sm font-medium text-gray-600 dark:text-gray-300">
                    {t('FEATURES.DASHBOARD_EMPLOYEE.WELCOME')},
                  </p>
                  <p className="text-xl font-bold text-gray-900 dark:text-quartery-default sm:text-2xl">{user.name}</p>
                  <p className="text-sm font-medium text-gray-600 dark:text-gray-300">{user.role}</p>
                </div>
              </div>
              <div className="mt-5 flex justify-center sm:mt-0">
                <a
                  href="#"
                  className="flex items-center justify-center rounded-md border border-gray-300 dark:border-gray-900 bg-[#0033A0] dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow hover:bg-gray-50"
                >
                  {t('FEATURES.DASHBOARD_EMPLOYEE.WORKCARD')}
                  <i className="fa-solid fa-chevron-right ml-2"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 bg-gray-50 dark:border-gray-800 dark:bg-gray-700 dark:divide-gray-900 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
            <div className="px-6 py-5 text-center text-sm font-medium">
              <StatusTag status={status} />
            </div>

            <div className="px-6 py-5 text-center text-sm font-medium">
              <span className="dark:text-quartery-default">2023-01-10</span>
              <i className="fa-solid fa-moon ml-2 text-black dark:text-quartery-default"></i>
            </div>

            <div className="px-6 py-5 text-center text-sm font-medium">
              <TaskProgressBar tasks={tasks} isLabel={true} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
