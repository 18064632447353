import { useTranslation } from 'react-i18next';

import type { TaskCount } from '../../models/taskCount.model';

export const TaskProgressBar: React.FC<{ tasks: TaskCount; isLabel: boolean }> = (props) => {
  const { t } = useTranslation();

  const getPercentTasksCompleted = (tasks: any) => {
    const completedTasks = (tasks.completed / tasks.total).toFixed(2);
    return +completedTasks * 100;
  };

  return (
    <>
      <div className="m-auto text-center max-w-[200px] dark:text-quartery-default">
        {props.isLabel && <span className="mr-2"> {t('COMPONENTS.TASK_PROGRESS_BAR.TASKS')}</span>}
        <span className="text-black dark:text-quartery-default font-bold">{props.tasks.completed}</span> /{' '}
        <span className="">{props.tasks.total}</span>
      </div>

      <div className="m-auto max-w-[200px] bg-gray-200 rounded-full dark:bg-gray-500">
        <div
          className={`bg-[#0033A0] dark:bg-[#009cde] text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full 
                    width-percentage-${getPercentTasksCompleted(props.tasks)}`}
        ></div>
      </div>
    </>
  );
};
