import { Disclosure, Transition } from '@headlessui/react';
import { ChatBubbleBottomCenterTextIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';

import { toggleDashboardComment } from '../../features/Settings/SettingsSlice';
import { useAppDispatch } from '../../hooks/hooks';
import { useGetRedboxQuery } from '../../services/data/workcard.service';
import type { RootState } from '../../store/store';
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';

import './LastCrewComments.scss';

export const LastCrewComments: React.FC<{ displayCollapseBtn?: boolean; isFilterEnabled: boolean }> = (props) => {
  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);

  const itemsPerPage = 10;
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const mountedRef = useRef(true);
  const btnRef = useRef<any>(null);

  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);

  const toggleComment = () => {
    if (!selectedIsMobileScreen) {
      const openBtn = btnRef?.current?.firstChild;
      openBtn.click();
    }

    dispatch(toggleDashboardComment(!props.displayCollapseBtn));
  };

  const {
    data,
    error,
    isLoading,
    refetch: refetchRedbox,
  } = useGetRedboxQuery({
    siteId: selectedSiteId!,
    pageSize: itemsPerPage,
    pageIndex: pageIndex,
  });

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data.count / itemsPerPage));
    }
  }, [data]);

  const handlePageClick = (event: any) => {
    setPageIndex(event.selected);

    refetchRedbox();
  };

  return (
    <>
      <div className="overflow-hidden rounded-xl bg-black shadow">
        <div
          className={`p-4 ${
            props.isFilterEnabled ? 'lg:h-[calc(100vh-275px)]' : 'lg:h-[calc(100vh-193px)]'
          } text-center`}
        >
          <Disclosure defaultOpen={!selectedIsMobileScreen} key={`panel-${selectedIsMobileScreen}`}>
            {({ open }) => (
              <>
                <summary ref={btnRef} className="inline-block">
                  <Disclosure.Button
                    onClick={() => {
                      toggleComment();
                    }}
                  >
                    <div>
                      <h2 className="text-base uppercase text-center text-[14px] dark:text-quartery-default whitespace-nowrap">
                        <span className="rounded-md border-[2.5px] border-workcard-redbox-primary inline-block px-3.5 py-0 mr-2.5 arrow_box">
                          <i className="fa-solid fa-exclamation text-[16px] "></i>
                        </span>
                        {t('COMPONENTS.LAST_CREW_COMMENTS.TITLE')}

                        <ChevronRightIcon
                          className={`${
                            open ? 'rotate-90 transform' : ''
                          }   mt-[-5px] ml-3 inline-block text-white w-[20px] font-bold`}
                        />
                      </h2>
                    </div>
                  </Disclosure.Button>
                </summary>
                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel static className="text-left">
                    <div className="border border-b-[1px] border-gray-500 mt-3 -mx-10"></div>
                    <div
                      className={`mt-6 flow-root ${
                        props.isFilterEnabled ? 'lg:h-[calc(100vh-387px)]' : 'lg:h-[calc(100vh-305px)]'
                      } dark:text-quartery-default`}
                    >
                      <ul
                        role="list"
                        className={`-mt-5  overflow-y-auto ${
                          props.isFilterEnabled ? 'lg:h-[calc(100vh-380px)]' : 'lg:h-[calc(100vh-297px)]'
                        } no-scrollbar`}
                      >
                        {data?.data?.map(
                          (comment, index) =>
                            index < 5 && (
                              <li key={comment.id} className="">
                                <div className="py-4 px-2 my-5 ml-2 mr-3 rounded-xl border-[3px] border-workcard-redbox-primary relative arrow_right_box">
                                  <div className="flex items-center space-x-4">
                                    <div className="min-w-0 flex-1 !ml-1.5 mt-3"></div>
                                    <div className="-mt-1 pr-1.5">
                                      <div className="inline-flex items-center text-xs text-workcard-redbox-primary">
                                        <i className="fa-regular fa-clock mr-1"></i>
                                        {moment(comment.recordUpdatedOn).format('YYYY-MM-DD HH:mm')}
                                        <span className="inline-flex items-center rounded ml-3 mt-[-1px] ">
                                          <i className="fa-solid fa-location-dot  mr-1.5 mt-[-1px]"></i>
                                          {comment?.locationName || 'N/A'}
                                        </span>
                                      </div>
                                      <div className="">
                                        <p className="mt-[7px] items-center text-sm text-gray-500 sm:mt-0"></p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex items-center space-x-4 mb-2">
                                    <div className="min-w-0 flex-1 !ml-1.5 ">
                                      <div className="flex-grow text-sm text-gray-500">
                                        {comment.author} {t('LABELS.SAID')}:
                                      </div>
                                    </div>
                                    <div className="-mt-1 pr-0">
                                      <div className="">
                                        <CopyToClipboard textToCopy={comment.workersComment}></CopyToClipboard>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex items-center space-x-4 mr-2.5 ml-[7px]">
                                    <div className="flex-grow mt-1 text-[13px] text-whiteline-clamp-2 whitespace-pre-line">
                                      <h2 className="text-[14px] text-white uppercase font-semibold">
                                        {comment.title}
                                      </h2>
                                      {comment.workersComment}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                        )}
                        {data?.data?.length === 0 && (
                          <div className="mb-4 hidden md:block">
                            <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg my-3 p-4 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                              <i className="fa-solid fa-circle-exclamation mx-auto text-2xl text-gray-400"></i>
                              <p className="mt-1 block text-base font-medium text-gray-500">
                                {t('LABELS.NO_COMMENT_AVAILABLE')}
                              </p>
                            </div>
                          </div>
                        )}
                      </ul>
                    </div>

                    <ReactPaginate
                      className="comment-pagination-dashboard"
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                    />
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </>
  );
};
