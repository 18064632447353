import { Disclosure, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Crew } from '../../../../models';
import { isUserCrewMember } from '../../../../services/common/profile.utils';
import { useGetCrewsQuery } from '../../../../services/data/dataCreation.service';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';

type SelectCrew = (value: Crew) => void;

export const CrewMgmtList: React.FC<{ selectCrew: SelectCrew }> = (props) => {
  const { t } = useTranslation();

  const [selectedRow, setRow] = useState<number>(-1);

  const { data: dataProfile, error: errorProfile, refetch: refetchProfile } = useGetMyProfileQuery();
  const { data, error, isLoading } = useGetCrewsQuery();

  const handleSelectCrew = (crew: Crew, idxRow: number) => {
    setRow(idxRow);
    props.selectCrew(crew);
  };

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  return (
    <>
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className="py-2 w-full mb-[10px]">
              <dt className="text-sm  text-black dark:text-quartery-default font-semibold flex">
                <label
                  htmlFor="username"
                  className="block text-base font-bold text-gray-900 dark:text-quartery-default sm:mt-px sm:pt-2"
                >
                  {t('FEATURES.CREW_MGMT.CREW_LIST')}
                </label>
                {/* {data && data.workerSignature && (
                  <span className="inline-flex items-center rounded-md bg-green-100 ml-2 -mt-1 px-2.5 py-0.5 text-sm font-medium text-green-800">
                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.WORKER')}
                    <i className="fa-solid fa-circle-check text-green-700 text-[16px] ml-2"></i>
                  </span>
                )}
                {data && data.supervisorSignature && (
                  <span className="inline-flex items-center rounded-md bg-green-100 ml-2 -mt-1 px-2.5 py-0.5 text-sm font-medium text-green-800">
                    {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.SUPERVISOR')}
                    <i className="fa-solid fa-circle-check text-green-700 text-[16px] ml-2"></i>
                  </span>
                )} */}
                <ChevronRightIcon
                  className={`${open ? 'rotate-90 transform' : ''}  block w-[30px] mt-[-6px] ml-auto mr-6`}
                />
              </dt>
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel static className="text-gray-500">
                <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 ">
                  <div className="mt-1 sm:col-span-5 sm:mt-0">
                    <div className="">
                      <div className="px-4 sm:px-6 lg:px-8 pl-2 sm:!pl-0">
                        <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50 dark:bg-secondary-default">
                              <tr>
                                <th
                                  scope="col"
                                  className="w-[25%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                                >
                                  {t('FEATURES.CREW_MGMT.NAME')}
                                </th>
                                <th
                                  scope="col"
                                  className="w-[20%]  px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default lg:table-cell"
                                >
                                  {t('FEATURES.CREW_MGMT.ACTIVE')}
                                </th>
                                <th
                                  scope="col"
                                  className="w-[30%]  px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:table-cell"
                                >
                                  {t('FEATURES.CREW_MGMT.MEMBERS')}
                                </th>
                                <th
                                  scope="col"
                                  className="w-[10%]  px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:table-cell"
                                >
                                  {t('FEATURES.CREW_MGMT.IS_MEMBER')}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white dark:bg-secondary-default">
                              {data?.map((crew, idx) => (
                                <tr
                                  key={crew.id}
                                  onClick={() => handleSelectCrew(crew, idx)}
                                  className={`${idx === selectedRow && 'bg-blue-100 dark:bg-gray-400'} cursor-pointer`}
                                >
                                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-quartery-default sm:w-auto sm:max-w-none sm:pl-6">
                                    {crew.name}
                                    <dl className="font-normal lg:hidden">
                                      <dt className="sr-only">{t('FEATURES.CREW_MGMT.ACTIVE')}</dt>

                                      <dt className="sr-only sm:hidden">{t('FEATURES.CREW_MGMT.MEMBERS')}</dt>
                                    </dl>
                                  </td>
                                  <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                    {' '}
                                    {crew.active ? (
                                      <i className="fa-solid fa-circle-check text-green-700 text-lg"></i>
                                    ) : (
                                      <i className="fa-solid fa-circle-xmark text-red-700 text-lg"></i>
                                    )}
                                  </td>
                                  <td className="px-3 py-4 text-sm text-gray-500 font-semibold">
                                    {crew.crewMembers.length}
                                  </td>
                                  <td className="px-3 py-4 text-sm text-gray-500 font-semibold">
                                    {isUserCrewMember(crew.crewMembers, dataProfile?.id) ? (
                                      <i className="fa-solid fa-circle-check text-green-700 text-lg"></i>
                                    ) : (
                                      <i className="fa-solid fa-circle-xmark text-red-700 text-lg"></i>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </>
  );
};
