import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import './Completed.scss';

export const Completed: React.FC<{ onComplete: any }> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="complete-screen">
        <svg viewBox="0 0 53.19 53.19">
          <g>
            <motion.circle
              stroke="#000"
              strokeWidth={3}
              fill="transparent"
              cx={26.59}
              cy={26.59}
              r={24}
              initial={{
                scale: 0,
                opacity: 0,
              }}
              animate={{
                scale: 1,
                opacity: 1,
              }}
            />
            <motion.path
              stroke="#000"
              strokeWidth={3}
              strokeLinecap="round"
              fill="transparent"
              d="M12.29 26.59l10.98 10.42 17.49-18.23"
              initial={{
                scale: 0,
                pathLength: 0,
              }}
              animate={{
                scale: 1,
                pathLength: 1,
              }}
              transition={{
                delay: 0.2,
                duration: 0.5,
              }}
            />
          </g>
        </svg>

        <motion.h1
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.3,
            duration: 0.7,
          }}
          onAnimationComplete={() => {
            props.onComplete();
          }}
        >
          <div className="text-black">{t('FEATURES.WORKCARD.WORKCARD_APPROVAL_MODAL.APPROVED')}!</div>
        </motion.h1>
      </div>
    </>
  );
};
