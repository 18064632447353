import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { DashboardFilterView } from '../../../../enums/dashboardFilterView.enum';

export interface DashboardFiltersState {
  filters: any;
  filterView: string;
}

const initialState: DashboardFiltersState = {
  filters: {
    fromDate: '',
    toDate: '',
    shift: '',
    userObjectId: '',
  },
  filterView: DashboardFilterView.myCrews,
};

export const dashboardFiltersSlice = createSlice({
  name: 'dashboardFilters',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    setFilterView: (state, action: PayloadAction<any>) => {
      state.filterView = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilters } = dashboardFiltersSlice.actions;
export const { setFilterView } = dashboardFiltersSlice.actions;

export default dashboardFiltersSlice.reducer;
