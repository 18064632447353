import { FlagIcon, ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export const EmployeeLink: React.FC<unknown> = () => {
  const { t } = useTranslation();

  const actions = [
    {
      icon: FlagIcon,
      name: 'View My Reports',
      href: '#',
      iconForeground: 'text-blue-700',
      iconBackground: 'bg-blue-50',
    },
    {
      icon: ChatBubbleBottomCenterTextIcon,
      name: 'View My Comments',
      href: '#',
      iconForeground: 'text-blue-700',
      iconBackground: 'bg-blue-50',
    },
  ];

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  return (
    <>
      <div className="col-span-2">
        <h2 className="mt-8 sm:mt-0 font-semibold uppercase mb-4 dark:text-quartery-default">
          {t('FEATURES.DASHBOARD_EMPLOYEE.LINKS')}
        </h2>
        <section>
          <div className=" divide-gray-200 overflow-hidden rounded-lg   shadow grid grid-cols-2 gap-px divide-y-0">
            {actions.map((action, actionIdx) => (
              <div
                key={action.name}
                className={classNames(
                  actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                  actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                  actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                  actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                  'relative group bg-white dark:bg-secondary-default p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-cyan-500'
                )}
              >
                <div>
                  <span
                    className={classNames(
                      action.iconBackground,
                      action.iconForeground,
                      'rounded-lg inline-flex p-3 ring-4 ring-white'
                    )}
                  >
                    <action.icon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-[58px] dark:text-quartery-default">
                  <h3 className="text-lg font-medium">
                    <a href={action.href} className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      {action.name}
                    </a>
                  </h3>
                </div>
                <span
                  className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};
