import { Disclosure, Switch, Transition } from '@headlessui/react';
import { ChevronRightIcon, CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useIonAlert, useIonModal, useIonRouter } from '@ionic/react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ControlMeasureModal } from '../../../../../../components/ControlMeasureModal/ControlMeasureModal';
import { RiskModal } from '../../../../../../components/RiskModal/RiskModal';
import type { Plan, PlanContent } from '../../../../../../models';
import { getpendingPlanificationCount, groupBy } from '../../../../../../services/common/planification.utils';
import { isUserSupervisor } from '../../../../../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../../../../../services/data/profile.service';
import {
  useGetWorkcardPlanQuery,
  useGetWorkcardTaskPlanQuery,
  usePostWorkcardPlanMutation,
  useDeleteWorkcardPlanMutation,
} from '../../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../../store/store';
import { WorkcardRiskAssessmentPlanningModal } from '../../../WorkcardRiskAssessmentPlanningModal/WorkcardRiskAssessmentPlanningModal';
import { TaskContext } from '../../WorkcardTasksContext';

import './WorkcardPlanning.scss';

export const WorkcardPlanning: React.FC<{ type: string; disablePanel?: boolean }> = (props) => {
  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { taskId } = useParams<{ taskId: string }>();

  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const selectedTaskId = useContext(TaskContext) || +taskId;
  const [selectedPlan, setPlan] = useState<any>(null);
  const [totalPlansCount, setTotalPlansCount] = useState<any>(0);
  const [selectedPendingPlanCount, setPendingPlanCount] = useState<number>(0);
  const [data, setData] = useState<PlanContent | null>(null);
  const [enabledFilterPending, setEnabledFilterPending] = useState(false);
  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);

  const mountedRef = useRef(true);

  const [presentAlert, hideAlert] = useIonAlert();

  const { data: myProfileData } = useGetMyProfileQuery();

  const [postWorkcardPlan, response] = usePostWorkcardPlanMutation();
  const [deleteWorkcardPlan, res] = useDeleteWorkcardPlanMutation();

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  const {
    data: dataTask,
    error,
    isLoading,
    refetch,
  } = useGetWorkcardTaskPlanQuery(
    {
      siteId: selectedSiteId!,
      workcardId: +id,
      taskId: +selectedTaskId!,
      lang: lang,
    },
    { skip: props.type === 'workcard' }
  );

  const { data: dataPlan, refetch: reftechPlan } = useGetWorkcardPlanQuery(
    {
      siteId: selectedSiteId!,
      workcardId: +id,
      lang: lang,
    },
    { skip: props.type === 'task' }
  );

  const [presentRiskModal, dismissRiskModal] = useIonModal(RiskModal, {
    plan: selectedPlan,
    workcardId: +id,
    onDismiss: (data: string, role: string) => dismissRiskModal(data, role),
  });

  const [presentControlMeasureModal, dismissControlMeasureModal] = useIonModal(ControlMeasureModal, {
    plan: selectedPlan,
    workcardId: +id,
    onDismiss: (data: string, role: string) => dismissControlMeasureModal(data, role),
  });

  const classNames = (...classes: any) => {
    return classes.filter(Boolean).join(' ');
  };

  const handleDeletePlan = async (e: any, plan: Plan) => {
    e.stopPropagation();

    try {
      await deleteWorkcardPlan({
        siteId: selectedSiteId,
        workcardId: +id,
        taskId: +selectedTaskId!,
        planId: plan.id,
        payload: {
          uniqueStamp: plan.uniqueStamp,
        },
      });

      toast.success('Plan deleted !', { duration: 2000, position: 'top-center' });
    } catch (error) {
      //
    }
  };

  const handleDuplicatePlan = async (e: any, plan: Plan) => {
    e.stopPropagation();

    try {
      const payload = {
        question: plan.question,
        questionId: plan.questionId,
        employeeId: myProfileData?.id,
        employeLevel: isUserSupervisor(myProfileData?.crews, myProfileData?.id) ? 1 : 0,
        controlMeasureId: null,
        riskTypeId: null,
        planStatus: 0,
        uniqueStamp: performance.now().toString(),
      };

      await postWorkcardPlan({
        siteId: selectedSiteId,
        workcardId: +id,
        taskId: plan.taskId,
        planId: plan.id,
        payload,
      });

      toast.success('Plan duplicated !', { duration: 2000, position: 'top-center' });
    } catch (error) {
      //
    }
  };

  function handleOpenRiskModal(plan: Plan) {
    setPlan(plan);

    presentRiskModal({
      onWillDismiss: (ev: CustomEvent) => {
        // if (ev.detail.role === 'confirm') {
        //   setMessage(`Hello, ${ev.detail.data}!`);
        // }
      },
      id: 'riskAssessmentModal',
      cssClass: 'riskAssessmentModal',
    });
  }

  function handleOpenControlMeasureModal(plan: Plan) {
    setPlan(plan);

    presentControlMeasureModal({
      onWillDismiss: (ev: CustomEvent) => {
        // if (ev.detail.role === 'confirm') {
        //   setMessage(`Hello, ${ev.detail.data}!`);
        // }
      },
      id: 'riskAssessmentModal',
      cssClass: 'riskAssessmentModal',
    });
  }

  const canDuplicatePlan = (plans: Plan[]): boolean => {
    let canDuplicate = true;

    plans?.map((planification) => {
      if (planification.riskType === null || planification.controlMeasure === null) {
        canDuplicate = false;
      }
    });

    return canDuplicate;
  };

  const canDeletePlan = (plans: Plan[], plan: Plan) => {
    const duplicatePlans = plans.filter((planification) => planification.questionId === plan?.questionId) || [];

    return duplicatePlans.length >= 2 ? true : false;
  };

  useEffect(() => {
    if (dataTask) {
      const data = Object.assign({}, dataTask);

      if (data && data.plans && data.plans.length > 0) {
        data.plans = data.plans.slice().sort(function (a: any, b: any) {
          return a.questionId - b.questionId;
        });
      }

      setTotalPlansCount(dataTask?.plans?.length);

      data.plans = groupBy(data.plans, 'taskName');

      setData(data);

      setPendingPlanCount(getpendingPlanificationCount(dataTask.plans));
    }
  }, [dataTask]);

  useEffect(() => {
    if (dataPlan) {
      let data = [...dataPlan];

      if (data && data.length > 0) {
        data = data.slice().sort(function (a: any, b: any) {
          return a.questionId - b.questionId;
        });
      }

      data = groupBy(data, 'taskName');

      setTotalPlansCount(dataPlan?.length);

      const planContent: PlanContent = { plans: data, planificationPendingCount: 0, totalplanificationCount: 0 };

      setData(planContent);
      setPendingPlanCount(getpendingPlanificationCount(dataPlan));
    }
  }, [dataPlan]);

  useEffect(() => {
    if (props.type === 'task') {
      refetch();
    } else {
      reftechPlan();
    }
  }, []);

  return (
    <>
      <div
        className={`${props.type === 'task'
          ? 'px-4 pb-10'
          : 'mx-[-8px] lg:mx-0 border-[2px] border-workcard-inspection-primary bg-dark-tertiary'
          } ${props.disablePanel && '!border-none'}  mb-6 mt-4 rounded-md `}
      >
        <Disclosure
          defaultOpen={props.type === 'task' || !selectedIsMobileScreen}
          key={`panel-${selectedIsMobileScreen}`}
        >
          {({ open }) => (
            <>
              {!props.disablePanel && (
                <Disclosure.Button
                  className={`w-full ${props.type == 'task' ? 'py-2 pb-[10px]' : 'py-0 pb-[0px] dark:bg-dark-secondary'
                    } ${open ? 'rounded-t-xl' : 'rounded-xl'}`}
                >
                  <dt
                    className={`text-lg ml-2 lg:ml-0 text-black dark:text-quartery-default font-semibold flex  ${props.type == 'task' ? 'justify-center' : 'justify-between'
                      } `}
                  >
                    {props.type === 'task' ? (
                      <>
                        <div>
                          <span className="uppercase text-workcard-inspection-primary font-bold text-xl">
                            {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.PLANNING')}
                          </span>

                          <ChevronRightIcon
                            className={`${open ? 'rotate-90 transform' : ''
                              }  w-[30px] mt-[-5px] ml-auto inline-block text-workcard-inspection-primary text-lg font-bold`}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <span className="uppercase text-workcard-inspection-primary font-semibold text-[12px] relative ml-[60px] z-10">
                            <span className="absolute left-[-55px] top-[-17px]">
                              <span className=" inline-block bg-workcard-inspection-primary w-[45px] h-[45px] rounded-full text-center">
                                <span className="text-dark-primary text-[25px] font-bold z-30 inline-block relative top-[9px]">
                                  {totalPlansCount - selectedPendingPlanCount}{' '}
                                </span>
                              </span>
                            </span>
                            {t('FEATURES.WORKCARD.WORKCARD_RISK_ASSESSMENT_PLANNING.RISK_CONTROL_MEASURES')}
                          </span>
                        </div>
                        <span className="mr-2 inline-block">
                          <span className=" text-[11px] text-white">
                            {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.SHOW_ALL')}
                          </span>
                          <ChevronRightIcon
                            className={`${open ? 'rotate-90 transform' : ''}  w-[25px]  ml-auto inline-block `}
                          />
                        </span>
                      </>
                    )}
                  </dt>
                </Disclosure.Button>
              )}
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel
                  static
                  className={`text-gray-500  rounded-b-xl ${props.disablePanel && '!border-none'} ${props.type === 'task'
                    ? `border border-[3px] relative border-workcard-inspection-primary rounded-2xl dark:bg-dark-secondary mt-[0px]`
                    : 'dark:bg-dark-secondary pt-4'
                    }`}
                >
                  {props.type === 'task' && (
                    <>
                      {totalPlansCount - selectedPendingPlanCount > 0 && (
                        <div className="text-center mb-3">
                          <span className="uppercase text-workcard-inspection-primary font-semibold text-[12px] relative ml-[60px] z-10">
                            <span className="absolute left-[-33px] top-[-7px]">
                              <span className=" inline-block bg-workcard-inspection-primary w-[26px] h-[26px] rounded-full text-center">
                                <span className="text-dark-primary text-[16px] font-bold z-30 inline-block relative top-[2px]">
                                  {totalPlansCount - selectedPendingPlanCount}{' '}
                                </span>
                              </span>
                            </span>
                            {t('FEATURES.WORKCARD.WORKCARD_RISK_ASSESSMENT_PLANNING.RISK_CONTROL_MEASURES')}
                          </span>
                        </div>
                      )}
                    </>
                  )}

                  <div className="text-center">
                    <Switch
                      checked={enabledFilterPending}
                      onChange={setEnabledFilterPending}
                      className={classNames(
                        'col-span-5  relative inline-flex h-[30px] w-[52px] flex-shrink-0 cursor-pointer rounded-fullblock m-auto bg-black  rounded-2xl pt-[2px] px-[3px] switch-show-plans mt-2 mb-1.5'
                      )}
                    >
                      <span
                        className={classNames(
                          !enabledFilterPending ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none relative inline-block h-[23px] w-[23px] transform rounded-full  shadow ring-0 transition duration-200 ease-in-out bg-gradient-to-r from-workcard-inspection-primary to-red-400'
                        )}
                      ></span>

                      <span className="w-[95px] absolute text-bold top-[6px] right-[60px] text-[11px] text-[#78787a] leading-[11px]">
                        {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.SHOW_PENDING_ONLY')}
                      </span>
                      <span className="w-[50px] absolute text-bold top-[6px] left-[60px] text-[11px] text-[#78787a] leading-[11px]">
                        {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.SHOW_ALL')}
                      </span>
                    </Switch>
                  </div>

                  <div className="mt-2 overflow-hidden shadow-none lg:shadow rounded-xl lg:mx-2 lg:rounded-lg ">
                    <table className="min-w-full lg:divide-y divide-none divide-gray-300 ">
                      <tbody className=" bg-dark-tertiary lg:bg-dark-secondary w-[10%]">
                        {data?.plans?.map((task: any) => (
                          <Fragment key={task.name}>
                            {props.type !== 'task' && (
                              <tr className={` border-t border-gray-200`}>
                                <th
                                  colSpan={5}
                                  scope="colgroup"
                                  className=" bg-gray-200 dark:bg-gray-400 py-3 pl-[22px] pr-3 text-left text-sm font-semibold text-gray-900"
                                >
                                  {task.name}
                                </th>
                              </tr>
                            )}

                            {task.result.map((plan: Plan, idx: number) => (
                              <tr
                                key={idx}
                                className={`${enabledFilterPending &&
                                  plan.controlMeasure &&
                                  plan.riskType &&
                                  'invisible absolute h-[0]'
                                  } cursor-pointer rounded-xl  my-4 mx-2  block  dark:bg-dark-primary bg-gray-100 shadow`}
                              >
                                <td className="w-full py-4 pl-4 pr-0 text-sm font-medium text-gray-900 relative">
                                  <span className="font-semibold dark:text-quartery-default pr-4 inline-block">
                                    {plan.question}
                                  </span>

                                  {canDuplicatePlan(task.result) && (
                                    <span
                                      onClick={(e) => handleDuplicatePlan(e, task.result[0])}
                                      id="badge-dismiss-yellow"
                                      className="cursor-pointer inline-flex items-end px-1.5 py-1.5 text-sm font-medium text-white bg-white rounded-md absolute mt-[-1px] lg:mt-[-6px] right-[-5px]"
                                    >
                                      <i className="fa-solid fa-plus text-workcard-inspection-primary "></i>
                                    </span>
                                  )}

                                  <dl className="font-normal  dark:bg-white pb-2 pt-[1px] mt-2 rounded-md relative w-full">
                                    {canDeletePlan(task.result, plan) && (
                                      <div
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          presentAlert({
                                            header: t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.DELETE_PLAN'),
                                            buttons: [
                                              {
                                                text: 'Cancel',
                                                role: 'cancel',
                                                handler: () => {
                                                  // setHandlerMessage('Alert canceled');
                                                },
                                              },
                                              {
                                                text: 'Submit',
                                                role: 'confirm',
                                                handler: () => {
                                                  e.stopPropagation();
                                                  handleDeletePlan(e, plan);
                                                },
                                              },
                                            ],
                                          });
                                        }}
                                        className="cursor-pointer dark:text-gray-900 text-base absolute right-[-8px] top-[24px] rounded-full bg-workcard-inspection-primary h-[28px] w-[28px] border-[2px] border-white text-center"
                                      >
                                        <i className="fa-solid fa-trash text-white text-[14px]"></i>
                                      </div>
                                    )}

                                    <div
                                      onClick={() => handleOpenRiskModal(plan)}
                                      className="flex flex-col mt-2 pb-2 border-b border-gray-600"
                                    >
                                      <dt className="text-gray-700 dark:text-black pl-2 text-[13px] font-semibold">
                                        {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.TYPE_OF_RISKS')}:{' '}
                                      </dt>
                                      <dd className="text-gray-700 text-[14px] w-[95%] dark:text-workcard-inspection-primary font-bold ml-2 text-left">
                                        {plan.riskType?.title || (
                                          <span className="bg-white border-[1.5px] text-[13px] font-bold border-workcard-inspection-primary text-black w-full py-0.5 mt-0.5 text-center rounded inline-block">
                                            {t('LABELS.SELECT')}
                                            <i className="ml-1 fa-solid fa-circle-arrow-right text-workcard-inspection-primary"></i>
                                          </span>
                                        )}
                                        {plan.riskType?.requiresAdditionalDetails && <span> - {plan.otherRiskType}</span>}
                                      </dd>
                                    </div>

                                    <div
                                      onClick={() => handleOpenControlMeasureModal(plan)}
                                      className="flex flex-col mt-2 pb-0 "
                                    >
                                      <dt className="text-gray-700 dark:text-black pl-2 text-[13px] font-semibold">
                                        {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.CONTROL_MEASURE')}:
                                      </dt>
                                      <dd className="text-gray-700 text-[14px] w-[95%] dark:text-workcard-inspection-primary font-bold ml-2 text-left">
                                        {(plan.controlMeasure && plan.controlMeasure.title) || (
                                          <span className="bg-white border-[1.5px] text-[13px] border-workcard-inspection-primary text-black w-full py-0.5 mt-0.5 text-center rounded inline-block">
                                            {t('LABELS.SELECT')}
                                            <i className="ml-1 fa-solid fa-circle-arrow-right text-workcard-inspection-primary"></i>
                                          </span>
                                        )}
                                        {plan.controlMeasure?.requiresAdditionalDetails && (
                                          <span> - {plan.otherControlMeasure}</span>
                                        )}
                                      </dd>
                                    </div>
                                  </dl>
                                </td>
                                <td
                                  onClick={() => handleOpenRiskModal(plan)}
                                  className="hidden px-3 py-4 text-sm text-gray-500 dark:text-quartery-default"
                                >
                                  {plan.riskType?.title !== '' && plan.riskType?.title}
                                  {plan.riskType?.requiresAdditionalDetails && <div> - {plan.otherRiskType}</div>}
                                  {plan.riskType === null && (
                                    <span className="bg-white border border-workcard-inspection-primary text-black w-[60px] text-center rounded inline-block">
                                      {t('LABELS.SELECT')}
                                    </span>
                                  )}
                                </td>
                                <td
                                  onClick={() => handleOpenControlMeasureModal(plan)}
                                  className="hidden px-3 py-4 text-sm text-gray-500 dark:text-quartery-default"
                                >
                                  {plan.controlMeasure && plan.controlMeasure?.title}
                                  {plan.controlMeasure?.requiresAdditionalDetails && (
                                    <div> - {plan.otherControlMeasure}</div>
                                  )}
                                  {plan.controlMeasure === null && (
                                    <span className="bg-white border border-workcard-inspection-primary text-black w-[60px] text-center rounded inline-block">
                                      {t('LABELS.SELECT')}
                                    </span>
                                  )}
                                </td>
                                <td className=" py-4 pl-2 pr-2 text-right text-sm font-medium "></td>
                              </tr>
                            ))}
                          </Fragment>
                        ))}
                      </tbody>
                    </table>

                    {data?.plans.length === 0 && (
                      <div className="mb-4 mx-4 block">
                        <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg my-4 p-12 pt-20 text-center hover:border-gray-400 ">
                          <i className="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
                          <p className="mt-6 block text-base font-medium text-gray-500">
                            {' '}
                            {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.NO_PLAN_AVAILABLE')}{' '}
                          </p>
                          <p className=" block text-sm font-medium text-gray-500"> &nbsp; </p>
                        </div>
                      </div>
                    )}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
};
