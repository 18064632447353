import { useIonRouter } from '@ionic/react';
import React, { useState, useEffect, Fragment, PropsWithChildren } from 'react';
import { push as Menu } from 'react-burger-menu';

import menu from '../../data/sidebarMenu.json';
import { useAppSelector, useAppDispatch } from '../../hooks/hooks';
import { selectMenuItem } from '../Sidebar/SidebarSlice';

import { openHamburgerMenu } from './HamburgerMenuSlice';

import './HamburgerMenu.scss';

export const HamburgerMenu: React.FC<unknown> = () => {
  const router = useIonRouter();

  const isMenuOpen = useAppSelector((state) => state.hamburgerMenu.value);
  const selectedMenuItem = useAppSelector((state) => state.sidebar.value);
  const dispatch = useAppDispatch();

  const handleStateChange = (state: any) => {
    dispatch(openHamburgerMenu(state.isOpen));
  };

  const gotoRoute = (route: string) => {
    router.push(`/${route}`, 'forward');
  };

  const handleMenuItemSelected = (item: any) => {
    gotoRoute(item.route);
    dispatch(selectMenuItem(item.id));

    setTimeout(() => {
      dispatch(openHamburgerMenu(false));
    }, 100);
  };

  return (
    <Menu
      width={'80%'}
      isOpen={isMenuOpen}
      pageWrapId={'content'}
      outerContainerId={'outer-content'}
      onStateChange={(state) => handleStateChange(state)}
    >
      <div className=" flex min-h-0 flex-1 overflow-hidden h-full fixed z-50">
        <nav className="overflow-y-auto  block">
          <div className="relative w-[300px]  space-y-10 px-1 pt-2.5">
            <div className="mt-[-10px] pb-[20px] w-[115px] ml-4">
              <img src="assets/img/AGNICO_EAGLE-Reverse-Standard.svg" alt="" />
            </div>

            <ul>
              {menu.map((item, index) => (
                <li
                  onClick={() => handleMenuItemSelected(item)}
                  className="text-white !mt-[32px] sidebar-item cursor-pointer flex"
                  key={`menu-${index}`}
                >
                  <div
                    className={`inline-flex shrink-0 items-center justify-center sidebar-item-content ${
                      selectedMenuItem === item.id
                        ? `rounded-full bg-white ml-1 ${item.classSelected}`
                        : `${item.class}`
                    }`}
                  >
                    <i
                      className={`fa-solid ${item.icon} text-[22px] ${
                        selectedMenuItem === item.id ? 'text-[#0033A0]' : ''
                      }`}
                    ></i>
                  </div>
                  <div
                    className={`mt-3.5 ml-2 text-center text-lg font-normal ${
                      selectedMenuItem === item.id ? 'mt-1' : ''
                    }`}
                  >
                    {item.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
    </Menu>
  );
};
