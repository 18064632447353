import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { IonContent, IonHeader, IonFooter, IonPage, IonTitle, IonToolbar, IonButtons } from '@ionic/react';
import moment from 'moment';
import { Fragment, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetMyProfileQuery } from '../../../../services/data/profile.service';

import { SlideToConfirm } from './components/SlideToConfirm/SlideToConfirm';
import { Completed } from './components/completed/completed';

import './WorkcardApprovalModal.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WorkcardApprovalModal = ({
  isSupervisor,
  isApprovalConfirmed,
  onApprove,
  onDismiss,
}: {
  isSupervisor: string;
  isApprovalConfirmed: boolean;
  onApprove: () => void;
  onDismiss: (data?: any | null | undefined | number, role?: string) => void;
}) => {
  const handleConfirm = () => {
    onApprove();
  };

  const [isApproved, setApproval] = useState<boolean>(false);

  const { data, error, isLoading } = useGetMyProfileQuery();

  const handleComnplete = () => {
    setTimeout(() => {
      onDismiss({ isApproved: true });
    }, 2000);
  };

  const { t } = useTranslation();

  useEffect(() => {
    if (isApprovalConfirmed) {
      setApproval(true);
    }
  }, [isApprovalConfirmed]);

  return (
    <IonPage>
      <IonContent className="ion-padding " forceOverscroll={false}>
        <div className="md:!px-10 relative text-center">
          {!isApproved && (
            <>
              <div className="rounded-full h-[110px] w-[110px] border-[6px] border-black inline-block text-center">
                <i className="fa-solid fa-check text-[70px] text-black top-[-30px] inline-block mt-4"></i>
              </div>

              <h4 className="mb-2 text-[28px] text-black uppercase font-bold justify-center  p-3 md:pl-0 flex lg:inline-flex w-full">
                {t('LABELS.SIGNATURE')}
              </h4>

              <div className="text-[#636364] text-[22px] font-bold">{data && data.name}</div>
              <div className="text-[#636364] font-medium">
                {isSupervisor === 'Supervisor' ? t('FEATURES.SETTINGS.SUPERVISOR') : t('FEATURES.SETTINGS.WORKER')}
              </div>

              <div className="text-[#636364] font-bold text-[20px]">{moment().format('YYYY/MM/DD HH:mm')}</div>

              <SlideToConfirm onConfirm={handleConfirm} />
            </>
          )}

          {isApproved && <Completed onComplete={handleComnplete} />}
        </div>
      </IonContent>
    </IonPage>
  );
};
