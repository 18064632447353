import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { StatusTag } from '../StatusTag/StatusTag';

export const NextWorkcards: React.FC<unknown> = () => {
  const { t } = useTranslation();

  const nextWorkcardStatus = {
    id: 0,
    title: 'Not started',
    name: '',
  };

  const nextSchedules = [
    {
      date: {
        day: 11,
        month: 'JAN',
        time: '06:30 PM',
      },
      location: 'X751P332',
      tasks: 4,
    },
    {
      date: {
        day: 12,
        month: 'JAN',
        time: '06:30 PM',
      },
      location: 'X751P332',
      tasks: 5,
    },
    {
      date: {
        day: 13,
        month: 'JAN',
        time: '06:30 PM',
      },
      location: 'X751P332',
      tasks: 3,
    },
  ];
  return (
    <>
      <h2 className="font-semibold uppercase mt-5 dark:text-quartery-default">
        {t('COMPONENTS.NEXT_WORKCARDS.MY_NEXT_WORKCARDS')}
      </h2>

      <Swiper navigation={true} modules={[Navigation]} className="swiper-workcards w-full sm:hidden">
        {nextSchedules.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="border border-gray-300 bg-white dark:border-gray-800 dark:bg-secondary-default dark:text-quartery-default shadow rounded px-3 py-2 text-gray-700">
              <div className="card">
                <div className="card-header">
                  <span>
                    <i className="far fa-clock"></i> {item.date.time}
                  </span>

                  <span className="float-right mt-[-1px]">
                    <span className="inline-flex items-center rounded bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-gray-700">
                      <i className="fa-solid fa-location-dot text-gray-700 mr-1.5"></i>
                      {item.location}
                    </span>
                  </span>
                </div>
                <div className="card-body mt-2 text-center margin-auto">
                  <div className="card-title mb-0">
                    <span className="font-semibold text-[45px]">{item.date.day}</span>
                    <span className="font-thin uppercase text-[25px]">{item.date.month}</span>
                  </div>

                  <div className="text-center margin-auto">
                    <span className="tasks text-center margin-auto">
                      <i className="fa-solid fa-bars-progress mr-2"></i>
                      <span>{item.tasks}</span>
                      <span>{t('COMPONENTS.NEXT_WORKCARDS.TASKS')}</span>
                    </span>
                  </div>
                </div>

                <div className="m-auto mt-3 mb-2 text-center text-sm font-medium">
                  <StatusTag status={nextWorkcardStatus} />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="hidden sm:grid gap-3 grid-cols-1 sm:grid-cols-3">
        {nextSchedules.map((item, index) => (
          <div key={index}>
            <div className="border border-gray-300 bg-white dark:border-gray-800 dark:bg-secondary-default dark:text-quartery-default shadow rounded px-3 py-2 text-gray-700">
              <div className="card">
                <div className="card-header">
                  <span>
                    <i className="far fa-clock"></i> {item.date.time}
                  </span>

                  <span className="float-right mt-[-1px]">
                    <span className="inline-flex items-center rounded bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-gray-700">
                      <i className="fa-solid fa-location-dot text-gray-700 mr-1.5"></i>
                      {item.location}
                    </span>
                  </span>
                </div>
                <div className="card-body mt-2 text-center margin-auto">
                  <div className="card-title mb-0">
                    <span className="font-semibold text-[45px]">{item.date.day}</span>
                    <span className="font-thin uppercase text-[25px]">{item.date.month}</span>
                  </div>

                  <div className="text-center margin-auto">
                    <span className="tasks text-center margin-auto">
                      <i className="fa-solid fa-bars-progress mr-2"></i>
                      <span>{item.tasks}</span>
                      <span>{t('COMPONENTS.NEXT_WORKCARDS.TASKS')}</span>
                    </span>
                  </div>
                </div>

                <div className="m-auto mt-3 mb-2 text-center text-sm font-medium">
                  <StatusTag status={nextWorkcardStatus} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
