import { Disclosure, Transition, Tab } from '@headlessui/react';
import { ChevronRightIcon, CheckBadgeIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';

import { CopyToClipboard } from '../../../../../components/CopyToClipboard/CopyToClipboard';
import lastComments from '../../../../../data/crewComments.json';
import { useGetRedboxQuery } from '../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../store/store';

import './WorkcardReportLastComment.scss';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export const WorkcardReportLastComments: React.FC<unknown> = () => {
  const mediaQuery = '(max-width: 1024px)';
  const mediaQueryList = window.matchMedia(mediaQuery);

  const { t } = useTranslation();

  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);

  const [categories] = useState({
    Worker: [],
    Location: [],
    Supervisor: [],
  });

  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);

  const itemsPerPage = 10;
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const mountedRef = useRef(true);

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  const {
    data,
    error,
    isLoading,
    refetch: refetchRedbox,
  } = useGetRedboxQuery({
    siteId: selectedSiteId!,
    pageSize: itemsPerPage,
    pageIndex: pageIndex,
  });

  useEffect(() => {
    if (data) {
      setPageCount(Math.ceil(data.count / itemsPerPage));
    }
  }, [data]);

  const handlePageClick = (event: any) => {
    setPageIndex(event.selected);

    refetchRedbox();
  };

  return (
    <>
      <Disclosure defaultOpen={!selectedIsMobileScreen} key={`panel-${selectedIsMobileScreen}`}>
        {({ open }) => (
          <>
            {!open && (
              <Disclosure.Button className="z-10 cursor-pointer fixed shadow top-0 bottom-0 m-auto left-0 mt-[500px] h-[70px] w-[70px] lg:w-auto float-left lg:float-none dark:bg-dark-primary bg-gray-300 pt-[-2px] rounded-full text-center">
                <div className="icon-RedBox text-[33px] text-center text-workcard-report-primary font-bold shadow-md"></div>
                <div className="leading-[1] text-[10px] block dark:text-white text-center  relative top-[0px] right-[-3px]">
                  worker comments
                </div>
              </Disclosure.Button>
            )}
            <Disclosure.Button className={`${!open && 'hidden'} py-2 w-full mb-[10px]`}>
              <dt className="mt-2 text-sm  text-black dark:text-quartery-default font-semibold flex justify-center">
                <div>
                  <span className="uppercase text-white font-normal text-xl">worker comments</span>

                  <ChevronRightIcon
                    className={`${
                      open ? 'rotate-90 transform' : ''
                    }  inline-block w-[30px] mt-[-6px] ml-auto font-bold text-lg`}
                  />
                </div>
              </dt>
            </Disclosure.Button>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel static className="text-gray-500">
                <ul className="-mt-5 divide-y divide-gray-500 dark:divide-gray-200 overflow-y-auto lg:h-[calc(100vh-290px)] dark:lg:h-[calc(100vh-300px)] no-scrollbar ">
                  {data?.data?.map((comment) => (
                    <li key={comment.id} className="py-5">
                      <div className="relative">
                        <div className="flex items-center space-x-4">
                          <div className="flex-shrink-0">
                            <ChatBubbleBottomCenterTextIcon
                              className="h-6 w-6 mr-1.5 inline-block -mt-1.5 dark:text-quartery-default"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="min-w-0 flex-1 !ml-1">
                            <div className="flex-grow text-sm font-bold dark:text-quartery-default">
                              {comment.author}
                            </div>
                          </div>
                          <div className="-mt-1 pr-3">
                            <div className="inline-flex items-center text-xs dark:text-gray-200">
                              <i className="fa-regular fa-clock mr-1"></i>
                              {moment(comment.recordUpdatedOn).format('YYYY-MM-DD HH:mm')}
                            </div>
                            <div className="">
                              <p className="mt-[7px] items-center text-sm text-gray-500 sm:mt-0">
                                <span className="inline-flex items-center rounded ml-0.5 py-0.5 text-xs font-medium text-white">
                                  <i className="fa-solid fa-location-dot  mr-1.5"></i>
                                  {comment?.locationName || 'N/A'}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="flex items-center space-x-4 mr-2.5 ">
                          <div className="flex-grow mt-1 ml-2 text-sm text-gray-600 dark:text-gray-400 line-clamp-2 whitespace-pre-line">
                            <h2 className="text-base text-black dark:text-gray-200 font-semibold">{comment.title}</h2>
                            <span className="text-base">{comment.workersComment}</span>
                          </div>

                          <CopyToClipboard textToCopy={comment.workersComment}></CopyToClipboard>
                        </div>
                      </div>
                    </li>
                  ))}
                  {data?.data?.length === 0 && (
                    <div className="mb-4 hidden md:block">
                      <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg my-3 p-4 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <i className="fa-solid fa-circle-exclamation mx-auto text-2xl text-gray-400"></i>
                        <p className="mt-1 block text-base font-medium text-gray-500">
                          {t('LABELS.NO_COMMENT_AVAILABLE')}
                        </p>
                      </div>
                    </div>
                  )}
                </ul>

                <ReactPaginate
                  className="comment-pagination"
                  breakLabel="..."
                  nextLabel="next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="< previous"
                  renderOnZeroPageCount={null}
                />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </>
  );
};
