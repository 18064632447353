import type { Configuration } from '@azure/msal-browser';
import { LogLevel, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

// Config file for azure saml authentication
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
    postLogoutRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system:
    process.env.NODE_ENV === 'production'
      ? {}
      : {
          loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
              //
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false,
          },
        },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_ACCESS_SCOPE!],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
};
