import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

export interface DashboardFilterDateState {
  value: string;
}

const initialState: DashboardFilterDateState = {
  value: moment().format('YYYY-MM-DD'),
};

export const dashboardFilterDateSlice = createSlice({
  name: 'dashboardFilterDate',
  initialState,
  reducers: {
    setFilterDate: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setFilterDate } = dashboardFilterDateSlice.actions;

export default dashboardFilterDateSlice.reducer;
