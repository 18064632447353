import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonMenu,
  IonMenuToggle,
  IonButton,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
} from '@ionic/react';
import { library, playCircle, radio, search } from 'ionicons/icons';
import React, { useState, useEffect, Fragment, PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, useParams } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { selectMenuItem } from '../../../../layout/Sidebar/SidebarSlice';
import type { Workcard } from '../../../../models';
import { getWorkcardStepMenuStatus } from '../../../../services/common/workcard.utils';
import { useGetWorkcardStatusByIdQuery } from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';

import './WorkcardTabs.scss';

type SelectedMenu = (e: Event, value: string) => void;

export const WorkcardTabs: React.FC<{ selectedMenu: SelectedMenu }> = (props) => {
  const { t } = useTranslation();
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const selectedMenuItem = useAppSelector((state) => state.sidebar.value);

  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const menu = [
    { id: 'Greeting', name: 'FEATURES.WORKCARD.WORKCARD_GREETING.GREETING' },
    { id: 'Inspection', name: 'FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.INSPECTION_TITLE' },
    { id: 'plan-decision', name: 'FEATURES.WORKCARD.WORKCARD_DECISION.PLAN_DECISION' },
    { id: 'Execution', name: 'FEATURES.WORKCARD.WORKCARD_EXECUTION.EXECUTION' },
    { id: 'Report', name: 'FEATURES.WORKCARD.WORKCARD_REPORT.REPORT' },
  ];
  const [selectedMenu, setSelectedMenu] = useState('Greeting');
  const [isEnabledStepMenuChanged, setIsEnabledStepMenuChanged] = useState(true);
  const [selectedTab, setSelectedTab] = useState('tab1');

  const { data, error, isLoading, refetch } = useGetWorkcardStatusByIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
  });

  const handleSelectMenu = (e: Event, item: string, tab: string) => {
    // refetch();
    setSelectedTab(tab);
    setSelectedMenu(item);
    setIsEnabledStepMenuChanged(false);
    dispatch(selectMenuItem(item));
    props.selectedMenu(e, item);
  };

  const menuStepBtn: any = useRef([]);

  useEffect(() => {
    if (selectedMenuItem) {
      setTimeout(() => {
        console.log('selectedMenuItem', selectedMenuItem);
        setSelectedTab(selectedMenuItem);
      }, 100);
    }
  }, [selectedMenuItem]);

  useEffect(() => {
    // if (data && menuStepBtn && isEnabledStepMenuChanged) {
    //   menuStepBtn.current[getWorkcardStepMenuStatus(data)].click();
    // }
    // if (!isEnabledStepMenuChanged) {
    //   setIsEnabledStepMenuChanged(true);
    // }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      document.querySelector('.ion-page.ion-page-invisible')?.classList.remove('ion-page-invisible');
    }, 100);
  }, []);

  return (
    <IonTabs>
      <IonRouterOutlet></IonRouterOutlet>
      <IonTabBar slot="bottom" selectedTab={selectedTab}>
        <IonTabButton
          tab="Greeting"
          onClick={(e) => handleSelectMenu(e, 'Greeting', 'Greeting')}
          selected={selectedTab === 'Greeting'}
        >
          <IonIcon src="./assets/img/greeting.svg" />
          <IonLabel>{t('LABELS.GREETING')}</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="Inspection"
          onClick={(e) => handleSelectMenu(e, 'Inspection', 'Inspection')}
          selected={selectedTab === 'Inspection'}
        >
          <IonIcon src="./assets/img/inspection-planif-03.svg" />
          <IonLabel>{t('LABELS.INSPECTION')}</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="plan-decision"
          onClick={(e) => handleSelectMenu(e, 'plan-decision', 'plan-decision')}
          selected={selectedTab === 'plan-decision'}
        >
          <IonIcon src="./assets/img/decision.svg" />
          <IonLabel>{t('LABELS.DECISION')}</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="Execution"
          onClick={(e) => handleSelectMenu(e, 'Execution', 'Execution')}
          selected={selectedTab === 'Execution'}
        >
          <IonIcon src="./assets/img/execution.svg" />
          <IonLabel>{t('LABELS.EXECUTION')}</IonLabel>
        </IonTabButton>

        <IonTabButton
          tab="Report"
          onClick={(e) => handleSelectMenu(e, 'Report', 'Report')}
          selected={selectedTab === 'Report'}
        >
          <IonIcon src="./assets/img/report.svg" />
          <IonLabel>{t('LABELS.REPORT')}</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};
