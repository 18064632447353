import { Switch } from '@headlessui/react';
import type { OverlayEventDetail } from '@ionic/core';
import { IonDatetime, IonDatetimeButton, IonModal } from '@ionic/react';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DashboardFilterView } from '../../../../../enums/dashboardFilterView.enum';
import { FilterToggleContext } from '../../../../../features/Dashboard/DashboardContext';
import {
  setFilters,
  setFilterView,
} from '../../../../../features/Dashboard/components/DashboardFilters/DashboardFiltersSlice';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { isUserSupervisor } from '../../../../../services/common/profile.utils';
import { classNames } from '../../../../../services/common/workcard.utils';
import { useGetMyProfileQuery } from '../../../../../services/data/profile.service';
import type { RootState } from '../../../../../store/store';

import { setFilterDate } from './DashboardFilterSlice';

import './DashboardFilter.scss';

export const DashboardFilters: React.FC<unknown> = () => {
  const { t, i18n } = useTranslation();
  const [selectedView, setSelectedView] = useState('myCrews');
  const [isDateToday, setIsDateToday] = useState(true);
  const [isSupervisor, setIsSupervisor] = useState(false);

  const { selectedFilterToggle, setFilterToggle } = useContext(FilterToggleContext);

  const lang = useAppSelector((state) => state.settings.lang);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);
  const selectedDate = useAppSelector((state) => state.dashboardFilterDate.value);
  const filterView = useSelector((state: RootState) => state.dashboardFilters.filterView);

  const [selectedDateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [selectedLang, setSelectedLang] = useState('en');
  const [isModalDateFromOpen, setIsModalDateFromOpen] = useState(false);

  const modalDateFrom = useRef<HTMLIonModalElement>(null);

  const dispatch = useAppDispatch();

  const { data: myProfileData } = useGetMyProfileQuery();

  const handleToggleFilter = () => {
    handleDateChange('TODAY');

    setFilterToggle(!selectedFilterToggle);
  };

  const handleChangeFilterView = (filterView: string) => {
    setSelectedView(filterView);

    dispatch(setFilterView(filterView));
  };

  const handleDateChange = (selectedDate: string) => {
    const date = selectedDate === 'TODAY' ? moment() : moment(selectedDate, 'YYYY-MM-DD');

    dispatch(setFilterDate(date.format('YYYY-MM-DD')));

    setIsDateToday(date.isSame(moment(), 'day'));

    dispatch(
      setFilters({
        fromDate: date.format('YYYY-MM-DD'),
        toDate: date.format('YYYY-MM-DD'),
        shift: '',
        userObjectId: '',
        isOneDaySelection: true,
      })
    );
  };

  const onWillDismissDateFrom = (ev: CustomEvent<OverlayEventDetail>) => {
    setIsModalDateFromOpen(false);
  };

  const handleDateFromChanged = (ev: any) => {
    setDateFrom(ev.detail.value);

    console.log('date changed', ev.detail.value);

    handleDateChange(ev.detail.value);

    if (modalDateFrom && modalDateFrom.current) {
      modalDateFrom.current.dismiss();
    }
  };

  useEffect(() => {
    if (myProfileData) {
      setIsSupervisor(isUserSupervisor(myProfileData?.crews, myProfileData?.id));
    }
  }, [myProfileData]);

  useEffect(() => {
    const date = moment(selectedDate, 'YYYY-MM-DD');
    setIsDateToday(date.isSame(moment(), 'day'));
  }, []);

  useEffect(() => {
    setSelectedView(filterView);
  }, [filterView]);

  useEffect(() => {
    if (!isOnline) {
      const date = moment();

      setFilterToggle(false);
      dispatch(setFilterDate(date.format('YYYY-MM-DD')));
      setIsDateToday(date.isSame(moment(), 'day'));

      dispatch(
        setFilters({
          fromDate: date.format('YYYY-MM-DD'),
          toDate: date.format('YYYY-MM-DD'),
          shift: '',
          userObjectId: '',
          isOneDaySelection: true,
        })
      );
    }
  }, [isOnline]);

  useEffect(() => {
    setSelectedLang('');

    setTimeout(() => {
      setSelectedLang(lang);
    }, 200);
  }, [lang]);

  return (
    <div className="flex justify-center gap-x-8 md:flex-row flex-col">
      <div className={`w-full ${isSupervisor && 'md:w-auto'}`}>
        <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only">
          Search
        </label>
        <div className="relative">
          <div className="absolute mt-2.5 ml-[10px] z-20">
            {!selectedFilterToggle && selectedLang && (
              <>
                <div className="date-filter ml-5">
                  <IonDatetimeButton className="inline" datetime="date1"></IonDatetimeButton>
                </div>

                <IonModal
                  ref={modalDateFrom}
                  keepContentsMounted={true}
                  isOpen={isModalDateFromOpen}
                  onWillDismiss={(ev) => onWillDismissDateFrom(ev)}
                >
                  <IonDatetime
                    value={selectedDateFrom}
                    onIonChange={handleDateFromChanged}
                    presentation="date"
                    id="date1"
                    locale={lang}
                  ></IonDatetime>
                </IonModal>
              </>
            )}
            {selectedFilterToggle && (
              <span
                onClick={() => handleToggleFilter()}
                className="uppercase cursor-pointer shadow text-base font-normal inline-block ml-[10px] pt-1.5 px-2 rounded text-gray-400"
              >
                {t('FEATURES.DASHBOARD.FILTER_ON')}
              </span>
            )}
          </div>

          <input
            type="search"
            id="search"
            className="outline-none block w-full p-4 pl-10 text-sm text-white border border-[2px] border-gray-300 dark:border-gray-500 rounded-lg bg-black"
            required
          />
          <button
            type="button"
            onClick={() => handleToggleFilter()}
            className={`${!isOnline && 'pointer-events-none'} ${
              selectedFilterToggle ? 'text-white' : 'text-gray-500'
            }  absolute right-0 top-1.5  font-medium rounded-lg text-sm px-4 py-2`}
          >
            <i className="fa-solid fa-filter inline-block text-[24px]"></i>
          </button>
        </div>
      </div>

      {isSupervisor && (
        <div className="text-center mt-2 lg:mt-[1px]">
          <span className="isolate inline-flex rounded-md shadow-sm lg:h-[55px] gap-x-3">
            <button
              onClick={() => {
                handleChangeFilterView(DashboardFilterView.myCrews);
              }}
              type="button"
              className={`relative inline-flex items-center rounded-md  px-5 py-2 text-sm font-semibold text-gray-100 ring-2 ring-inset ring-gray-500  focus:z-10 ${
                selectedView === DashboardFilterView.myCrews && 'bg-gray-700'
              }`}
            >
              {t('FEATURES.DASHBOARD.MY_CREWS')}
            </button>
            <button
              onClick={() => {
                handleChangeFilterView(DashboardFilterView.assignToMe);
              }}
              type="button"
              className={`relative -ml-px inline-flex items-center rounded-md px-5 py-2 text-sm font-semibold text-gray-100 ring-2 ring-inset ring-gray-500  focus:z-10 ${
                selectedView === DashboardFilterView.assignToMe && 'bg-gray-700'
              }`}
            >
              {t('FEATURES.DASHBOARD.ASSIGN_TO_ME')}
            </button>
            <button
              onClick={() => {
                handleChangeFilterView(DashboardFilterView.AllCrews);
              }}
              type="button"
              className={`relative -ml-px inline-flex items-center rounded-md px-5 py-2 text-sm font-semibold text-gray-100 ring-2 ring-inset ring-gray-500 focus:z-10 ${
                selectedView === DashboardFilterView.AllCrews && 'bg-gray-700'
              }`}
            >
              {t('FEATURES.DASHBOARD.ALL_CREWS')}
            </button>
          </span>
        </div>
      )}
    </div>
  );
};
