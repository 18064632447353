import Skeleton from 'react-loading-skeleton';

export const WorkcardTaskListLoader: React.FC<unknown> = () => {
  const data = [{}, {}, {}];

  return (
    <>
      {data?.map((comment, index) => (
        <div
          key={index}
          className={` cursor-move border-[2px] !border-workcard-greeting-secondary bg-dark-secondary dark:rounded-xl rounded-xl lg:rounded-none dark:lg:!mx-4 my-4 mx-4 shadow-md lg:shadow-none workcardTaskItem  lg:w-full lg:ml-[6] lg:mr-[60px]`}
        >
          <div className="">
            <div className="flex flex-row h-[70px]">
              <div className="relative">
                <p className=" bg-gradient-to-b from-workcard-greeting-tertiary to-workcard-greeting-primary h-full rounded-l-lg px-2 pt-[25px] text-[40px] font-bold  leading-5 text-dark-primary ">
                  {index + 1}
                </p>
              </div>
              <div className="flex-col w-full">
                <div className="mt-[3px]">
                  <div className="relative">
                    <p className=" text-sm text-gray-500 lg:mt-0 text-end ">
                      <span className="inline-flex items-center rounded  px-2.5 py-0.5 text-[11px] font-bold text-workcard-greeting-secondary">
                        <i className="fa-solid fa-location-dot text-workcard-greeting-secondary mr-1.5 text-[13px] relative top-[-2px]"></i>
                        <Skeleton height={'20px'} width={'60px'} />
                      </span>
                    </p>
                  </div>
                </div>
                <div className="px-2.5 pt-0">
                  <div className="">
                    <div className={` text-sm  font-bold text-black dark:text-quartery-default`}>
                      <Skeleton height={'20px'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      ))}
    </>
  );
};
