import { motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import type { PanInfo, MotionStyle } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './SlideToConfirm.scss';

export const SlideToConfirm: React.FC<{ onConfirm: any }> = (props) => {
  const { t } = useTranslation();

  const ref = useRef<any>(null);
  const [isConfirm, setConfirm] = useState<boolean>(false);

  const controls = useAnimation();
  const offset = useMotionValue(0);
  const opacity = useTransform(offset, [0, 300], [1, 0]);
  const background = useTransform(offset, [0, 300], ['#CECDCD', '#000']);

  const spinTransition = {
    loop: Infinity,
    ease: 'linear',
    duration: 0.7,
  };

  const handlePan = (event: any, info: PanInfo) => {
    const x = info.offset.x;

    if (x > 0) {
      controls.set({ x: x < ref.current.offsetWidth - 80 ? x : ref.current.offsetWidth - 80 });
    }
  };

  const handlePanEnd = (event: any, info: PanInfo) => {
    if (info.offset.x >= ref.current.offsetWidth - 80) {
      setTimeout(() => {
        setConfirm(true);
      }, 150);

      setTimeout(() => {
        props.onConfirm();
      }, 1000);
    } else {
      controls.start({ x: 0 });
    }
  };

  return (
    <>
      <motion.div style={{ background }} ref={ref} className="slide-to-confirm shadow-inner ">
        {isConfirm && (
          <span>
            {t('FEATURES.WORKCARD.WORKCARD_APPROVAL_MODAL.LOADING')}
            <motion.span className="circle-loader" animate={{ rotate: 360 }} transition={spinTransition} />
          </span>
        )}

        {!isConfirm && (
          <>
            <motion.span style={{ opacity }} className="pointer-events-none select-none text-black">
              {t('FEATURES.WORKCARD.WORKCARD_APPROVAL_MODAL.SLIDE_TO_APPROVE')}
            </motion.span>
            <motion.div
              className="toggle shadow-xl"
              animate={controls}
              onPan={handlePan}
              onPanEnd={handlePanEnd}
              style={{
                x: offset,
              }}
            >
              <i className="fa-solid fa-angles-right text-lg"></i>
            </motion.div>
          </>
        )}
      </motion.div>
    </>
  );
};
