import type { Decision } from '../../models';

import { baseQuery, createApi } from './base.service';

// Define a service using a base URL and expected endpoints
export const decisionService = createApi({
  reducerPath: 'decisionService',
  baseQuery: baseQuery,
  tagTypes: ['decision'],
  endpoints: (builder) => ({
    getDecision: builder.query<Decision, { siteId: number; workcardId: number }>({
      query: (arg) => {
        const { siteId, workcardId } = arg;
        const query = `Site/${siteId}/Workcard/${workcardId}/decision`;

        return query;
      },
      providesTags: ['decision'],
    }),
    updateDecision: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/decision`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['decision'],
    }),
    postDecisionSignature: builder.mutation({
      query: ({ siteId, workcardId, payload }) => ({
        url: `Site/${siteId}/Workcard/${workcardId}/decision/signature`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['decision'],
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetDecisionQuery, useUpdateDecisionMutation, usePostDecisionSignatureMutation } = decisionService;
