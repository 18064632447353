import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './SaveIndicator.scss';

export const SaveIndicator: React.FC<{ isSaved?: boolean; duration?: number }> = (props) => {
  const { t } = useTranslation();

  const saveIndocator: any = useRef(null);

  useEffect(() => {
    if (props.isSaved) {
      saveIndocator?.current?.classList?.add('fade-in-out-save');

      setTimeout(() => {
        saveIndocator?.current?.classList?.remove('fade-in-out-save');
      }, 2000);
    }
  }, [props.isSaved]);

  return (
    <>
      {
        <div
          ref={saveIndocator}
          className="save-indicator absolute ml-2 mt-[0px] text-[13px] text-[#646464] "
          role="alert"
        >
          <i className="fa-solid fa-check mr-1 text-green-600"></i>
          {t('COMPONENTS.SAVE_INDICATOR.SAVED')}
        </div>
      }
    </>
  );
};
