import { LastCrewComments } from '../../components/LastCrewComments/LastCrewComments';
import { NextWorkcards } from '../../components/NextWorkcards/NextWorkcards';
import { Content } from '../../layout/content/content';
import { Header } from '../../layout/header/Header/Header';

import { CurrentWorkcard } from './components/CurrentWorkcard/CurrentWorkcard';
import { EmployeeLink } from './components/EmployeeLinks/EmployeeLinks';
import { PreviousWorkcard } from './components/PreviousWorkcard/PreviousWorkcard';

export const DashboardEmployee: React.FC = () => {
  return (
    <Content header={<Header title="LaRonde" hasProfile={true} />}>
      <main className="-mt-12 pt-6 pb-8 bg-gray-100 ">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          {/* Main 3 column grid */}
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            {/* Left column */}
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              {/* Current Workcard */}
              <CurrentWorkcard />

              {/* My Next Workcards */}
              <NextWorkcards />

              {/* Previous Workcard && Useful Link */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 mt-5">
                <div>
                  {/* Previous Workcard */}
                  <PreviousWorkcard />
                </div>
                {/* Links */}
                <EmployeeLink />
              </div>
            </div>

            {/* Right column */}
            <div className="grid grid-cols-1 gap-4 ">
              {/* lastComments */}
              {/* <LastCrewComments /> */}
            </div>
          </div>
        </div>
      </main>
    </Content>
  );
};
