import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface HamburgerMenuState {
  value: boolean;
}

const initialState: HamburgerMenuState = {
  value: false,
};

export const hamburgerMenuSlice = createSlice({
  name: 'hamburgerMenu',
  initialState,
  reducers: {
    openHamburgerMenu: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { openHamburgerMenu } = hamburgerMenuSlice.actions;

export default hamburgerMenuSlice.reducer;
