import { IonHeader, IonItem, IonList, IonSearchbar, IonToolbar, useIonAlert } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PlanificationType } from '../../constants/planification';
import { useAppDispatch } from '../../hooks/hooks';
import type { ControlMeasure, Plan, Risk } from '../../models';
import { isUserSupervisor } from '../../services/common/profile.utils';
import { useGetMyProfileQuery } from '../../services/data/profile.service';
import {
  useGetControlMeasureListQuery,
  useGetRiskListQuery,
  useUpdateWorkcardPlanMutation,
} from '../../services/data/workcard.service';
import type { RootState } from '../../store/store';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const RiskModal = ({
  workcardId,
  plan,
  onDismiss,
}: {
  workcardId: number;
  plan: any;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const { data: myProfileData } = useGetMyProfileQuery();
  const [updateWorkcardPlan, resUpdatePlan] = useUpdateWorkcardPlanMutation();

  const {
    data: controlMeasures,
    error: errorCM,
    isLoading: isLoadingCM,
  } = useGetControlMeasureListQuery({ siteId: selectedSiteId!, language: lang });

  const {
    data: typeOfRisks,
    error: errorRisk,
    isLoading: isLoadingRisk,
  } = useGetRiskListQuery({ siteId: selectedSiteId!, language: lang });

  const [results, setResults] = useState<Risk[] | []>([]);

  const [presentAlert, hideAlert] = useIonAlert();

  const handleInput = (ev: Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target && target.value) query = target.value.toLowerCase();

    if (typeOfRisks) {
      setResults(typeOfRisks.filter((d) => d.title.toLowerCase().indexOf(query) > -1));
    }
  };

  const handleUpdatePlan = async (
    type: number,
    id: any,
    title: any,
    requiresAdditionalDetails?: boolean,
    otherControlMeasure?: string,
    otherRiskType?: string
  ) => {
    try {
      plan = plan.current || plan;

      const payload: any = {
        questionId: plan.questionId,
        employeeId: myProfileData?.id,
        employeLevel: isUserSupervisor(myProfileData?.crews, myProfileData?.id) ? 1 : 0,
        planStatus: 0,
        uniqueStamp: plan.uniqueStamp ? plan.uniqueStamp : performance.now(),
      };

      if (otherControlMeasure) {
        payload.otherControlMeasure = otherControlMeasure;
      }

      if (otherRiskType) {
        payload.otherRiskType = otherRiskType;
      }

      if (PlanificationType.TYPEOFRISK === type) {
        payload.riskTypeId = id;
        payload.riskTypeTitle = title;

        if (!requiresAdditionalDetails) {
          payload.otherRiskType = '';
        }
      } else if (PlanificationType.CONTROLMEASURE === type) {
        payload.controlMeasureId = id;
        payload.controlMeasureTitle = title;

        if (!requiresAdditionalDetails) {
          payload.otherControlMeasure = '';
        }
      }

      await updateWorkcardPlan({
        siteId: selectedSiteId,
        workcardId: workcardId,
        taskId: plan.taskId,
        planId: plan.id,
        payload,
      });
    } catch (error) {
      //
    }
  };

  const handleTypeOfRiskChanged = (ev: Risk) => {
    if (ev.requiresAdditionalDetails) {
      setTimeout(() => {
        presentAlert({
          header: 'Specify a Type of Risk',
          buttons: [
            {
              text: 'Cancel',
              cssClass: 'alert-button-cancel',
              handler: (ev: any) => {
                //
              },
            },
            {
              text: 'Submit',
              cssClass: 'alert-button-confirm',
              handler: (e: any) => {
                handleUpdatePlan(PlanificationType.TYPEOFRISK, ev.id, ev.title, ev.requiresAdditionalDetails, undefined, e.typeRiskInput);
                onDismiss(null, 'confirm');
              },
            },
          ],
          inputs: [
            {
              name: 'typeRiskInput',
              type: 'textarea',
              placeholder: '',
            },
          ],
        });
      }, 500);
    } else {
      handleUpdatePlan(PlanificationType.TYPEOFRISK, ev.id, ev.title);
      onDismiss(null, 'confirm');
    }
  };

  const handleCloseModal = () => {
    onDismiss(null, 'confirm');
  };

  useEffect(() => {
    if (typeOfRisks) {
      setResults([...typeOfRisks]);
    }
  }, [typeOfRisks]);

  return (
    <>
      <div className="flex justify-center mb-6 mt-3">
        <span className="dark:text-quartery-default">Select a Type of Risk</span>
        <i
          onClick={handleCloseModal}
          className="fa-regular fa-circle-xmark absolute right-4 text-white text-[25px]"
        ></i>
      </div>

      <IonSearchbar debounce={100} onIonInput={(ev) => handleInput(ev)}></IonSearchbar>

      <div className="ml-3 mr-2 my-2 overflow-y-scroll">
        <IonList>
          {results.map((result) => (
            <IonItem
              onClick={() => {
                handleTypeOfRiskChanged(result);
              }}
              key={result.id}
            >
              {result.title}
            </IonItem>
          ))}
        </IonList>
      </div>
    </>
  );
};
