import { Switch } from '@headlessui/react';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/outline';
import { useIonModal } from '@ionic/react';
import { motion } from 'framer-motion';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type { FocusEvent } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { CopyToClipboard } from '../../../../../components/CopyToClipboard/CopyToClipboard';
import { RedboxModal } from '../../../../../components/RedboxModal/RedboxModal';
import { SaveIndicator } from '../../../../../components/SaveIndicator/SaveIndicator';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import type { Report, Signature } from '../../../../../models';
import { isUserSupervisor, isUserWorkcardOwner } from '../../../../../services/common/profile.utils';
import { classNames, getLocationById, resizeTextarea } from '../../../../../services/common/workcard.utils';
import { useGetMyProfileQuery } from '../../../../../services/data/profile.service';
import {
  useGetWorkcardRedboxQuery,
  useGetWorkcardEndshiftQuery,
  useUpdateWorkcardEndshiftMutation,
  usePostWorkcardRedboxSignatureMutation,
  usePostWorkcardEndshiftSignatureMutation,
  useDeleteWorkcardRedboxSignatureMutation,
  useGetWorkcardLocationQuery,
} from '../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../store/store';
import { MenuContext } from '../../../WorkcardContext';

export const WorkcardRedbox: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const selectedSiteId: any = useSelector((state: RootState) => state.auth.siteId);
  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);
  const selectedWorkcard = useAppSelector((state) => state.workcard.selectedWorkcard);
  const dispatch = useAppDispatch();
  const { selectedMenu, setselectedMenu } = useContext(MenuContext);

  const [isSupervisorApproval, setIsSuperviorApproval] = useState(false);
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [isDailyReportSaved, setIsDailyReportSaved] = useState(false);

  const { data, refetch } = useGetWorkcardEndshiftQuery({ siteId: selectedSiteId, workcardId: +id });
  const [updateEndshift, resEndshift] = useUpdateWorkcardEndshiftMutation();
  const [PostRedboxSignature, { error: errorredboxSig, data: resRedboxSig }] = usePostWorkcardRedboxSignatureMutation();
  const [deleteWorkcardRedboxSignature, { error: errorDeleteSig, data: resDeleteSig }] =
    useDeleteWorkcardRedboxSignatureMutation();

  const { data: dataProfile } = useGetMyProfileQuery();

  const { data: locations } = useGetWorkcardLocationQuery({
    siteId: selectedSiteId!,
  });

  const getLocation = (id: number) => {
    return getLocationById(locations, id);
  };

  const {
    data: dataRedbox,
    refetch: refetchRedbox,
    isLoading: isLoadingRedbox,
    isFetching: isFetchingRedbox,
  } = useGetWorkcardRedboxQuery({
    siteId: selectedSiteId,
    workcardId: +id,
  });

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    getValues,
  } = useForm();

  const [present, dismiss] = useIonModal(RedboxModal, {
    workcardId: +id,
    siteId: selectedSiteId,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const handlePreventCommentChange = (haveRedboxComment: boolean) => {
    const payload = {
      preventComment: !haveRedboxComment,
    };

    updateEndshift({ siteId: selectedSiteId, workcardId: +id, payload });
  };

  const handleApproveComment = (redboxId: number, supervisorSignature: any) => {
    const payload = { employeeLevel: 1, signatureCert: '' };

    if (supervisorSignature === null) {
      PostRedboxSignature({ siteId: selectedSiteId, workcardId: +id, redboxId: redboxId, payload });
    } else {
      deleteWorkcardRedboxSignature({
        siteId: selectedSiteId,
        workcardId: +id,
        redboxId: redboxId,
        signatureId: supervisorSignature.id,
        payload: payload,
      });
    }
  };

  useEffect(() => {
    if (data) {
      setValue('preventComment', data.preventComment, { shouldValidate: false });
    }
  }, [data]);

  const addNoComment = () => {
    let payload: any = {};

    setValue('dailyReport', 'No comment', { shouldValidate: false });

    payload = {
      dailyReport: 'No comment',
    };

    try {
      updateEndshift({ siteId: selectedSiteId, workcardId: +id, payload })
        .unwrap()
        .then(() => {
          setIsDailyReportSaved(true);

          setTimeout(() => {
            setIsDailyReportSaved(false);
          }, 2000);
        });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleOpenCommentModal = (e: any) => {
    present({
      onWillDismiss: (ev: CustomEvent) => {
        //
      },
      id: 'redboxComment',
      cssClass: 'redboxComment',
    });
  };

  useEffect(() => {
    setIsUserOwner(isUserWorkcardOwner(dataProfile?.crews, selectedWorkcard!));
    setIsSupervisor(isUserSupervisor(dataProfile?.crews, dataProfile?.id));
  }, [dataProfile]);

  return (
    <div className="px-4 py-4 lg:pt-0 sm:gap-4 sm:px-4 pb-7 mt-5 lg:mt-2 mb-10 relative ">
      <div className="border-[3px] border-workcard-report-primary rounded-t-2xl rounded-b-md bg-black">
        <dt className="text-lg  text-gray-100 dark:text-gray-100 font-semibold bg-workcard-redbox-primary rounded-t-xl pt-1.5 pb-[4px] relative">
          <span className="rounded-md border-[2.5px] border-workcard-redbox-primary inline-block px-3.5 py-0 mr-2.5 arrow_box absolute left-2 top-[-1.5px]">
            <i className="fa-solid fa-exclamation text-[16px] "></i>
          </span>
          <div className="leading-[14px] uppercase text-[14px] font-bold ml-[52px]">
            {t('FEATURES.WORKCARD.WORKCARD_REPORT.WORKER_COMMENTS_REDBOX')}
          </div>
          <div className="text-[11px] leading-[11px] font-normal mt-[3px]  ml-[52px]">
            {t('FEATURES.WORKCARD.WORKCARD_REPORT.HEALTH_SAFETY_ENV')}
          </div>
        </dt>
        <div
          className={`${
            !isUserOwner && 'dark:bg-transparent'
          } relative text-lg text-gray-900 bg-transparent sm:col-span-2 sm:mt-0`}
        >
          {isUserOwner && (data?.preventComment === null || data?.preventComment === true) && (
            <div className="px-3 mt-6">
              <dt className="leading-[1.1] relative text-[14px]  text-gray-700 dark:text-quartery-default font-bold">
                {t('FEATURES.WORKCARD.WORKCARD_REPORT.DO_YOU_HAVE_REDBOX_COMMENT')}
              </dt>

              <ul className={`${!isUserOwner && 'invisible absolute h-0 overflow-hidden'} mt-4 flex gap-2 w-full mb-6`}>
                <li className="text-center  w-[50%] md:text-right md:pr-3">
                  <input
                    {...register(`preventComment`, { required: true })}
                    type="radio"
                    id="yes_preventComment"
                    name="yes_preventComment"
                    value="yes"
                    className="hidden peer"
                    onChange={(ev) => handlePreventCommentChange(true)}
                    // checked={data?.preventComment === false}
                  />
                  <label
                    htmlFor="yes_preventComment"
                    className={`${
                      !isUserOwner && 'pointer-events-none'
                    } cursor-pointer inline-flex justify-between items-center px-5  text-white bg-black rounded-2xl border border-gray-500 cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-redbox-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-redbox-primary w-[105px] h-[75px] ml-8 peer-checked:arrow-down-redbox relative`}
                  >
                    <span className="w-full text-[23px] font-bold text-center mt-2">{t('FEATURES.WORKCARD.YES')}</span>
                  </label>
                </li>
                <li className="text-center  w-[50%] md:text-left md:pl-3">
                  <input
                    {...register(`preventComment`, { required: true })}
                    type="radio"
                    id="no_preventComment"
                    name="no_preventComment"
                    value="no"
                    className="hidden peer"
                    onChange={(ev) => handlePreventCommentChange(false)}
                    checked={data?.preventComment === true}
                  />
                  <label
                    htmlFor="no_preventComment"
                    className={`${
                      !isUserOwner && 'pointer-events-none'
                    }cursor-pointer inline-flex justify-between items-center px-5  text-white bg-black rounded-2xl border border-gray-500 cursor-pointe peer-checked:bg-blue-900 dark:peer-checked:bg-white dark:peer-checked:border-workcard-redbox-primary dark:peer-checked:border-[4px] dark:border-[2px]  peer-checked:text-workcard-redbox-primary w-[105px] h-[75px] mr-8 peer-checked:arrow-down-redbox relative`}
                  >
                    <span className="w-full text-[23px] font-bold text-center mt-2">{t('FEATURES.WORKCARD.NO')}</span>
                  </label>
                </li>
              </ul>
            </div>
          )}

          {dataRedbox?.map((comment) => (
            <div key={`comment-${comment.id}`} className="relative mb-4 mt-6 mx-2 dark:text-quartery-default">
              <ul role="list" className="-mt-5 divide-y divide-gray-200 overflow-y-auto no-scrollbar">
                <li key={comment.id} className="">
                  <div
                    className={`pb-4 pt-1 mt-2 mb-1  border-b-[1px] border-gray-600 relative  ${
                      isSupervisor && 'rounded-xl border border-dark-tertiary pt-0 overflow-hidden mt-4 !pb-0'
                    } `}
                  >
                    <div className={`flex items-center space-x-4 ${isSupervisor && 'bg-dark-tertiary  '}`}>
                      <div className={`min-w-0 flex-1 !ml-1.5 mt-3 `}></div>
                      <div
                        className={`-mt-1 pr-1.5 text-workcard-redbox-primary ${
                          isSupervisor && 'mt-0 !text-white mr-1 h-[30px] !pr-2.5'
                        }`}
                      >
                        <div className="inline-flex items-center text-xs ">
                          <i className="fa-regular fa-clock mr-1"></i>
                          {moment(comment.recordUpdatedOn).format('YYYY-MM-DD HH:mm')}
                          <span className="inline-flex items-center rounded ml-3 mt-[-1px] ">
                            <i className="fa-solid fa-location-dot  mr-1.5 mt-[-1px]"></i>
                            {comment?.locationName || getLocation(comment?.locationId).name || 'N/A'}
                          </span>
                        </div>
                      </div>
                    </div>

                    {isSupervisor && (
                      <div className="flex items-center space-x-4 mb-2 mt-1">
                        <div className="min-w-0 flex-1 !ml-1.5 ">
                          <div className="flex-grow text-[12px] text-gray-500">
                            {comment.author} {t('LABELS.SAID')}:
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="flex items-center space-x-4 mr-2.5 ml-[7px]">
                      <div className="flex-grow mt-1 text-[13px] text-whiteline-clamp-2 whitespace-pre-line">
                        <h2 className="text-[14px] text-white uppercase font-medium">{comment.title}</h2>
                        <span className="font-medium">{comment.workersComment}</span>
                      </div>
                    </div>

                    {isSupervisor && (
                      <div className=" mb-2">
                        <CopyToClipboard type="button" textToCopy={comment.workersComment}></CopyToClipboard>
                      </div>
                    )}

                    {!isSupervisor && (
                      <div className="flex justify-end pl-2  py-[5px] ">
                        {comment.supervisorSignature ? (
                          <div className="text-[#24de16] rounded-2xl border-[1.5px] border-[#24de16] text-[12px] px-2.5 h-[25px] leading-[22px] mr-2">
                            <span>{t('FEATURES.WORKCARD.WORKCARD_REPORT.APPROVED_PUBLISHED')}</span>
                            <i className="fa-solid fa-check ml-1"></i>
                          </div>
                        ) : (
                          <div className="text-[#7c7c7c] rounded-2xl border-[1.5px] border-[#7c7c7c] text-[12px] px-2.5 h-[25px] leading-[22px] mr-2">
                            <span>{t('FEATURES.WORKCARD.WORKCARD_REPORT.APPROBATION_PENDING')}</span>
                            <i className="fa-regular fa-clock ml-1  mt-[2px]"></i>
                          </div>
                        )}
                      </div>
                    )}

                    {isSupervisor && (
                      <div className="flex justify-between pl-2 bg-dark-tertiary py-[5px] ">
                        <div className="text-white uppercase text-[10px] font-extrabold">
                          {t('FEATURES.WORKCARD.WORKCARD_REPORT.APPROVE_THIS_COMMENT')}?
                        </div>
                        <Switch
                          id={comment.id.toString()}
                          key={comment.id}
                          checked={comment.supervisorSignature !== null}
                          onChange={() => {
                            handleApproveComment(comment.id, comment.supervisorSignature);
                          }}
                          className={classNames(
                            'relative inline-flex h-[23px] w-[44px] flex-shrink-0 cursor-pointer rounded-fullblock m-auto bg-black  rounded-2xl pt-[1.5px] px-[3px] switch-approve-comment mt-[3px] mb-0.5 pr-1'
                          )}
                        >
                          <span
                            className={classNames(
                              comment.supervisorSignature ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none relative inline-block h-[16px] w-[16px] transform rounded-full  shadow ring-0 transition duration-200 ease-in-out bg-gradient-to-r from-workcard-redbox-primary to-workcard-redbox-secondary'
                            )}
                          ></span>

                          <span className="absolute text-bold top-[-5px] right-[48px] text-[11px] text-gray-300">
                            {t('FEATURES.WORKCARD.NO')}
                          </span>
                          <span className="absolute text-bold top-[-5px] left-[48px] text-[11px] text-gray-300">
                            {t('FEATURES.WORKCARD.YES')}
                          </span>
                        </Switch>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            </div>
          ))}
        </div>
        {!isUserOwner && dataRedbox?.length === 0 && (
          <div
            className={`${
              data?.preventComment !== true ? 'text-red-700' : 'text-gray-400'
            } py-[10px] pl-3 text-lg border border-red-500`}
          >
            {data?.preventComment !== true ? (
              <div>
                <i
                  className={`fa-solid fa-triangle-exclamation ${
                    errors[`isWorkplaceSafeTidy`]?.type === 'required' ? 'text-red-600' : 'text-gray-500'
                  }   pr-1  pb-[6px]`}
                ></i>
                <span className="text-[13px] text-gray-500 py-1pb-[4.5px]">
                  {t('FEATURES.WORKCARD.WAITING_FOR_WORKER_ANSWER')}
                </span>
              </div>
            ) : (
              <span className="text-[13px] text-gray-500 py-1pb-[4.5px]">
                {t('FEATURES.WORKCARD.WORKCARD_REPORT.NO_COMMENT_REQUIRED')}
              </span>
            )}
          </div>
        )}
        {dataRedbox && dataRedbox.length === 0 && isUserOwner && data?.preventComment === false && (
          <div className="m-5">
            <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg my-3 p-4 text-center hover:border-gray-400 focus:outline-none">
              {data?.preventComment === false && (
                <>
                  <i className="fa-solid fa-circle-exclamation mx-auto text-2xl text-gray-400"></i>
                  <p className="mt-1 block text-base font-medium text-gray-500">{t('LABELS.NO_COMMENT_AVAILABLE')}</p>
                </>
              )}
            </div>
          </div>
        )}
        {isUserOwner && data?.preventComment === false && (
          <div className="w-[100px] h-[70px]  bg-gradient-to-r from-workcard-redbox-primary to-workcard-redbox-secondary  text-center flex items-center rounded-xl justify-center border-[3px] border-workcard-redbox-primary m-auto relative mb-[36px] mt-[30px]">
            <i
              onClick={(e) => {
                handleOpenCommentModal(e);
              }}
              className={`cursor-pointer fa-solid fa-plus text-[40px] `}
            ></i>
          </div>
        )}
      </div>
    </div>
  );
};
