import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router-dom';

import { setConnectivityStatus } from '../components/ConnectivityStatus/ConnectivityStatusSlice';
import { AppRoles } from '../constants';
import { Auth } from '../features/Auth/Auth';
import { CrewMgmt } from '../features/CrewMgmt/CrewMgmt';
import { Dashboard } from '../features/Dashboard/Dashboard';
import { DashboardEmployee } from '../features/DashboardEmployee/DashboardEmployee';
import { Logout } from '../features/Logout/Logout';
import { Settings } from '../features/Settings/Settings';
import { ControlMeasureList } from '../features/Settings/components/ControlMeasureList/ControlMeasureList';
import { RiskList } from '../features/Settings/components/RiskList/RiskList';
import { Sites } from '../features/Sites/Sites';
import { Workcard } from '../features/Workcard/Workcard';
import { WorkcardTaskDetails } from '../features/Workcard/components/WorkcardTaskDetails/WorkcardTaskDetails';
import { RouteGuard } from '../guards/routeGuard';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { HamburgerMenu } from '../layout/HamburgerMenu/HamburgerMenu';
import { Sidebar } from '../layout/Sidebar/Sidebar';
import { AuthProvider } from '../providers/authProvider';

export const Router: React.FC<unknown> = () => {
  const { t } = useTranslation();
  const isDarkMode = useAppSelector((state) => state.settings.darkmode);
  const dispatch = useAppDispatch();

  const setOnline = () => {
    dispatch(setConnectivityStatus(true));
    toast.success(t('LABELS.INTERNET_IS_ON'), { duration: 2000, position: 'top-center' });

    navigator.serviceWorker.ready.then((registration) => {
      if (registration && registration.active) {
        console.log('ONLINEEEE');
        registration.active.postMessage({ type: 'REPLAY_REQUEST' });
      }
    });
  };

  const setOffline = () => {
    dispatch(setConnectivityStatus(false));
    toast.error(t('LABELS.NO_INTERNET_CONNEXION'), { duration: 2000, position: 'top-center' });
  };

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    dispatch(setConnectivityStatus(window.navigator.onLine));

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  useEffect(() => {
    if (isDarkMode) {
      document.querySelector('html')?.classList.add('dark');
    } else {
      document.querySelector('html')?.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <div id="outer-content" className={`${isDarkMode ? 'dark' : ''} h-full`}>
      <IonReactRouter>
        <AuthenticatedTemplate>
          <AuthProvider>
            <HamburgerMenu />
            <Sidebar />
            <div id="content" className={`h-[100vh]`}>
              <IonRouterOutlet>
                <Route exact path="/home">
                  <Dashboard />
                </Route>
                <Route exact path="/home-worker">
                  <DashboardEmployee />
                </Route>
                <Route exact path="/workcard/:id">
                  <Workcard />
                </Route>
                <Route exact path="/workcard/:id/workcard-task-detail/:taskId">
                  <WorkcardTaskDetails />
                </Route>
                <Route exact path="/auth">
                  <Auth />
                </Route>
                <Route exact path="/crewmgmt">
                  <CrewMgmt />
                </Route>
                <Route exact path="/sites">
                  <Sites />
                </Route>
                <Route exact path="/settings">
                  <Settings />
                </Route>
                <Route exact path="/settings/generate-demo-workcard">
                  <CrewMgmt />
                </Route>
                <Route exact path="/settings/risk-list">
                  <RiskList />
                </Route>
                <Route exact path="/settings/control-measure-list">
                  <ControlMeasureList />
                </Route>
                <Route exact path="/logout">
                  <Logout />
                </Route>
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
              </IonRouterOutlet>
            </div>
          </AuthProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Auth />
        </UnauthenticatedTemplate>
      </IonReactRouter>
      <Toaster
        toastOptions={{
          className: 'dark:bg-dark-tertiary dark:text-quartery-default',
        }}
      />
    </div>
  );
};
