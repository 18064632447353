import { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import { useAppSelector } from '../../../hooks/hooks';

export const SpeechToTextContent: React.FC<{
  class?: string;
  isListening: number;
  questionId: string;
  onTranscriptEnd: any;
  onListeningEnd: any;
}> = (props) => {
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const lang = useAppSelector((state) => state.settings.lang);

  const startListening = () => {
    let speechRecog = 'en-CA';

    switch (lang) {
      case 'en':
        speechRecog = 'en-CA';
        break;
      case 'fr':
        speechRecog = 'fr-FR';
        break;
      case 'fi':
        speechRecog = 'fi';
        break;
      default:
        speechRecog = 'en-CA';
        break;
    }

    SpeechRecognition.startListening({ language: speechRecog, continuous: true });
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();

    if (transcript) {
      props.onListeningEnd({ transcript, questionId: props.questionId });
      resetTranscript();
    } else {
      props.onListeningEnd({ transcript: '', questionId: props.questionId });
    }
  };

  useEffect(() => {
    if (props.isListening === 1) {
      setTimeout(() => {
        resetTranscript();
        startListening();
      }, 200);
    } else {
      stopListening();
    }
  }, [props.isListening]);

  useEffect(() => {
    if (transcript) {
      props.onTranscriptEnd({ transcript, questionId: props.questionId });
    }
  }, [transcript]);

  useEffect(() => {
    if (listening === false && transcript) {
      props.onListeningEnd({ transcript, questionId: props.questionId });
    }
  }, [listening]);

  return (
    <span
      className={`active cursor-pointer inline-flex items-center h-full w-full text-md font-medium text-white text-[18px] `}
    >
      <i className={`fa-solid fa-microphone`}></i>
    </span>
  );
};
