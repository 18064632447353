import { IonContent, IonPage, useIonModal } from '@ionic/react';
import React, { createRef, useEffect } from 'react';

import { useAppSelector } from '../../hooks/hooks';

export const Content: React.FC<{ header: any; children: any; isVerticalScroll?: boolean; isDashboard?: boolean }> = (
  props
) => {
  const isSidebarExpanded = useAppSelector((state) => state.settings.isSidebarExpanded);
  const contentRef = createRef<HTMLIonContentElement>();
  const selectedMenuItem = useAppSelector((state) => state.sidebar.value);

  useEffect(() => {
    contentRef.current?.scrollToTop(0);
  }, [selectedMenuItem]);

  return (
    <IonPage id="main-content">
      <IonContent ref={contentRef} forceOverscroll={false} fullscreen className="relative flex page-content">
        <div>
          {props.header}
          <div
            className={` no-scrollbar h-[100vh] ${isSidebarExpanded ? 'lg:ml-[280px]' : 'lg:ml-[89px]'}  ${
              !props.isVerticalScroll && 'lg:overflow-hidden'
            } ${
              props.isDashboard === true
                ? 'bg-gradient-to-r from-black to-dark-tertiary  pt-[71px] lg:pt-[100px]'
                : 'pt-[65px] lg:pt-[89px] bg-[#23262d]'
            }`}
          >
            {props.children}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
