import { Switch } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { constants } from 'crypto';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Language } from '../../../../enums/language.enum';
import { Content } from '../../../../layout/content/content';
import { Header } from '../../../../layout/header/Header/Header';
import type { Crew, Risk } from '../../../../models';
import { isUserCrewMember } from '../../../../services/common/profile.utils';
import { classNames } from '../../../../services/common/workcard.utils';
import { useGetCrewsQuery } from '../../../../services/data/dataCreation.service';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import {
  useGetControlMeasureListQuery,
  usePostControlMeasureMutation,
  usePostControlMeasureTranslationMutation,
  useUpdateControlMeasureMutation,
  useUpdateControlMeasureTranslationMutation,
} from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';

export const ControlMeasureList: React.FC = () => {
  const { t } = useTranslation();
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const [selectedRow, setRow] = useState<number>(-1);
  const [controlMeasureEn, setControlMeasureEn] = useState<Risk[] | null>(null);
  const [controlMeasureFr, setControlMeasureFr] = useState<Risk[] | null>(null);
  const [controlMeasureFi, setControlMeasureFi] = useState<Risk[] | null>(null);

  const { data: dataProfile, error: errorProfile, refetch: refetchProfile } = useGetMyProfileQuery();

  const [updateControlMeasureTranslation, resUpdateControlMeasureTranslation] =
    useUpdateControlMeasureTranslationMutation();
  const [postControlMeasureTranslation, resPostControlMeasureTranslation] = usePostControlMeasureTranslationMutation();
  const [updateControlMeasure, resUpdateControlMeasure] = useUpdateControlMeasureMutation();
  const [postControlMeasure, resPostControlMeasure] = usePostControlMeasureMutation();

  const { data, error, isLoading } = useGetCrewsQuery();

  const handleSetActive = async (value: boolean, index: number) => {
    const newControlMeasure: any = Object.assign([], controlMeasureEn!);
    newControlMeasure[index].active = value;
    setControlMeasureEn(newControlMeasure);

    const payload: any = {
      id: controlMeasureEn && controlMeasureEn[index].id,
      title: controlMeasureEn && controlMeasureEn[index].title,
      active: value,
      approved: true,
      revisionNumber: 0,
      siteId: +selectedSiteId!,
    };

    try {
      await updateControlMeasure({ siteId: selectedSiteId, payload });
    } catch (error) {
      console.log('error', error);
    }
  };

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    clearErrors,
  } = useForm();

  const { data: typeOfControlMeasuresEn, refetch: refetchEn } = useGetControlMeasureListQuery({
    siteId: selectedSiteId!,
    language: 'en',
  });

  const { data: typeOfControlMeasuresFr, refetch: refetchFr } = useGetControlMeasureListQuery({
    siteId: selectedSiteId!,
    language: 'fr',
  });

  const { data: typeOfControlMeasuresFi, refetch: refetchFi } = useGetControlMeasureListQuery({
    siteId: selectedSiteId!,
    language: 'fi',
  });

  const handleCreateControlMeasure = async () => {
    const payload: any = {
      title: '',
      active: true,
      approved: true,
      revisionNumber: 0,
      siteId: +selectedSiteId!,
    };

    try {
      await postControlMeasure({ siteId: selectedSiteId, payload });

      toast.success('Control Measure created successfully !', { duration: 2000, position: 'top-center' });
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleInputOnChange = async (e: any) => {
    const inputVal: string = e.target.value;
    const lang: any = e?.target?.getAttribute('data-lang');
    const index: any = e?.target?.getAttribute('data-index');
    let newControlMeasure: any = null;

    switch (lang) {
      case 'fr':
        newControlMeasure = Object.assign([], controlMeasureFr!);
        newControlMeasure[index].title = inputVal;
        setControlMeasureFr(newControlMeasure);
        break;
      case 'en':
        newControlMeasure = Object.assign([], controlMeasureEn!);
        newControlMeasure[index].title = inputVal;
        setControlMeasureEn(newControlMeasure);
        break;
      case 'fi':
        newControlMeasure = Object.assign([], controlMeasureFi!);
        newControlMeasure[index].title = inputVal;
        setControlMeasureFi(newControlMeasure);
        break;
      default:
        newControlMeasure = Object.assign([], controlMeasureEn!);
        newControlMeasure[index].title = inputVal;
        setControlMeasureEn(newControlMeasure);
        break;
    }
  };

  const handleInputOnBlur = async (e: any) => {
    const inputVal: string = e.target.value;
    const id: any = e?.target?.getAttribute('data-id');
    const lang: any = e?.target?.getAttribute('data-lang');
    const titleTranslationId = e?.target?.getAttribute('data-translation-id');

    console.log('inputVal', inputVal);

    if (lang === Language.english) {
      const payload: any = {
        id: +id,
        title: inputVal,
        active: true,
        approved: true,
        revisionNumber: 0,
        siteId: +selectedSiteId!,
      };

      try {
        await updateControlMeasure({ siteId: selectedSiteId, payload });
      } catch (error) {
        console.log('error', error);
      }
    } else {
      const payload: any = {
        title: inputVal,
        language: lang,
      };

      if (titleTranslationId) {
        await updateControlMeasureTranslation({ siteId: selectedSiteId, cmid: id, id: titleTranslationId, payload });
      } else {
        await postControlMeasureTranslation({ siteId: selectedSiteId, cmid: id, payload });
      }
    }

    refetchEn();
    refetchFr();
    refetchFi();
  };

  useEffect(() => {
    if (typeOfControlMeasuresEn) {
      setControlMeasureEn([...JSON.parse(JSON.stringify(typeOfControlMeasuresEn))]);
    }
  }, [typeOfControlMeasuresEn]);

  useEffect(() => {
    if (typeOfControlMeasuresFr) {
      setControlMeasureFr([...JSON.parse(JSON.stringify(typeOfControlMeasuresFr))]);
    }
  }, [typeOfControlMeasuresFr]);

  useEffect(() => {
    if (typeOfControlMeasuresFi) {
      setControlMeasureFi([...JSON.parse(JSON.stringify(typeOfControlMeasuresFi))]);
    }
  }, [typeOfControlMeasuresFi]);

  return (
    <Content isVerticalScroll={true} header={<Header title="Control Measure List" hasProfile={true} backBtn={true} />}>
      <div className=" px-4 sm:px-0 sm:ml-[40px] mt-5">
        <div className="">
          <div className="">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto"></div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => handleCreateControlMeasure()}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                  >
                    <i className="fa-solid fa-plus text-white mr-2"></i>
                    Add Control Measure
                  </button>
                </div>
              </div>
            </div>

            <div className="space-y-6 sm:space-y-5">
              <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4 ">
                <div className="mt-1 sm:col-span-5 sm:mt-0 mb-10">
                  <div className="">
                    <div className="px-4 sm:px-6 lg:px-8 pl-2 sm:!pl-0">
                      <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="">
                            <tr>
                              <th
                                scope="col"
                                className="w-[3%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                              >
                                ID
                              </th>
                              <th
                                scope="col"
                                className="w-[28%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                              >
                                EN
                              </th>
                              <th
                                scope="col"
                                className="w-[28%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                              >
                                FR
                              </th>
                              <th
                                scope="col"
                                className="w-[28%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default sm:pl-6"
                              >
                                FI
                              </th>
                              <th
                                scope="col"
                                className="w-[13%]  px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-quartery-default lg:table-cell"
                              >
                                {t('FEATURES.CREW_MGMT.ACTIVE')}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white dark:bg-secondary-default">
                            {controlMeasureEn?.map((controlMeasure, idx) => (
                              <tr
                                key={controlMeasureEn[idx].id}
                                className={`${idx === selectedRow && 'bg-blue-100 dark:bg-gray-400'} cursor-pointer`}
                              >
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-quartery-default sm:w-auto sm:max-w-none sm:pl-6">
                                  {controlMeasureEn[idx].id}
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">{controlMeasureEn[idx].id}</dt>

                                    <dt className="sr-only sm:hidden">{controlMeasureEn[idx].id}</dt>
                                  </dl>
                                </td>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-gray-400 sm:w-auto sm:max-w-none sm:pl-6">
                                  <input
                                    key={`input-en-${idx}`}
                                    value={controlMeasureEn && controlMeasureEn[idx].title}
                                    type="text"
                                    onBlur={handleInputOnBlur}
                                    onChange={handleInputOnChange}
                                    data-id={controlMeasureEn[idx].id}
                                    data-index={idx}
                                    data-lang="en"
                                    data-translation-id={controlMeasureEn[idx].id}
                                    className={`text-black bg-white dark:bg-dark-tertiary dark:text-gray-200 rounded-l border border-gray-400 comment block w-full  focus:border-blue-darker focus:ring-blue-darker h-[40px] mt-2`}
                                  />
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">{t('FEATURES.CREW_MGMT.ACTIVE')}</dt>

                                    <dt className="sr-only sm:hidden">{t('FEATURES.CREW_MGMT.MEMBERS')}</dt>
                                  </dl>
                                </td>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-gray-400 sm:w-auto sm:max-w-none sm:pl-6">
                                  {controlMeasureFr && (
                                    <input
                                      key={`input-fr-${idx}`}
                                      value={controlMeasureFr[idx].title}
                                      type="text"
                                      onBlur={handleInputOnBlur}
                                      onChange={handleInputOnChange}
                                      data-id={controlMeasureFr[idx].id}
                                      data-index={idx}
                                      data-lang="fr"
                                      data-translation-id={controlMeasureFr[idx].titleTranslationId}
                                      className={`text-black bg-white dark:bg-dark-tertiary dark:text-gray-200 rounded-l border border-gray-400 comment block w-full  focus:border-blue-darker focus:ring-blue-darker h-[40px] mt-2`}
                                    />
                                  )}
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">{t('FEATURES.CREW_MGMT.ACTIVE')}</dt>

                                    <dt className="sr-only sm:hidden">{t('FEATURES.CREW_MGMT.MEMBERS')}</dt>
                                  </dl>
                                </td>
                                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 dark:text-gray-400 sm:w-auto sm:max-w-none sm:pl-6">
                                  {controlMeasureFi && (
                                    <input
                                      key={`input-fi-${idx}`}
                                      value={controlMeasureFi[idx].title}
                                      type="text"
                                      onBlur={handleInputOnBlur}
                                      onChange={handleInputOnChange}
                                      data-id={controlMeasureFi[idx].id}
                                      data-index={idx}
                                      data-lang="fi"
                                      data-translation-id={controlMeasureFi[idx].titleTranslationId}
                                      className={`text-black bg-white dark:bg-dark-tertiary dark:text-gray-200 rounded-l border border-gray-400 comment block w-full  focus:border-blue-darker focus:ring-blue-darker h-[40px] mt-2`}
                                    />
                                  )}
                                  <dl className="font-normal lg:hidden">
                                    <dt className="sr-only">{t('FEATURES.CREW_MGMT.ACTIVE')}</dt>

                                    <dt className="sr-only sm:hidden">{t('FEATURES.CREW_MGMT.MEMBERS')}</dt>
                                  </dl>
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                  <span className="dark:text-gray-300 text-black text-sm top-[-7px] mt-6 mr-2 relative left-[7px] sm:left-[0px] inline-block mb-2">
                                    Active
                                  </span>
                                  <Switch
                                    key={`active-${controlMeasureEn[idx].id}`}
                                    data-index={idx}
                                    checked={controlMeasureEn && controlMeasureEn[idx].active}
                                    onChange={(e) => {
                                      handleSetActive(e, idx);
                                    }}
                                    className={` ${classNames(
                                      controlMeasureEn && controlMeasureEn[idx].active
                                        ? 'bg-blue-900 dark:bg-[#009cde]'
                                        : 'bg-gray-200',
                                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out'
                                    )}`}
                                  >
                                    <span
                                      className={classNames(
                                        controlMeasureEn && controlMeasureEn[idx].active
                                          ? 'translate-x-5'
                                          : 'translate-x-0',
                                        'pointer-events-none relative inline-block h-[23px] w-[23px] transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                      )}
                                    >
                                      <span
                                        className={classNames(
                                          controlMeasureEn && controlMeasureEn[idx].active
                                            ? 'opacity-0 duration-100 ease-out'
                                            : 'opacity-100 duration-200 ease-in',
                                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                          <path
                                            d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </span>
                                      <span
                                        className={classNames(
                                          controlMeasureEn && controlMeasureEn[idx].active
                                            ? 'opacity-100 duration-200 ease-in'
                                            : 'opacity-0 duration-100 ease-out',
                                          'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <svg
                                          className="h-3 w-3 text-indigo-600"
                                          fill="currentColor"
                                          viewBox="0 0 12 12"
                                        >
                                          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                        </svg>
                                      </span>
                                    </span>
                                  </Switch>{' '}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};
