import { AppRolesId } from '../../constants';
import type { Crew, CrewMember, Employee, Workcard } from '../../models';

export const getUserStatus = (crewMembers: any, data: any): string => {
  const myCrewMember = crewMembers.find((member: any) => member.employeeId === data?.id);
  let userStatus = 'Worker';

  if (myCrewMember && myCrewMember.employeeLevel === AppRolesId.SUPERVISOR) {
    userStatus = 'Supervisor';
  }

  return userStatus;
};

export const isUserSupervisor = (crews: any, employeeId: any): boolean => {
  let isSupervisor = false;

  crews?.map((member: any) => {
    member.crewMembers.map((crew: any) => {
      if (crew.employeeId === employeeId && crew.employeeLevel === AppRolesId.SUPERVISOR) {
        isSupervisor = true;
      }
    });
  });

  return isSupervisor;
};

export const isUserCrewMember = (crewMembers: CrewMember[], employeeId: any): boolean => {
  let isCrewMember = false;

  crewMembers?.map((crew: any) => {
    if (crew.employeeId === employeeId) {
      isCrewMember = true;
    }
  });

  return isCrewMember;
};

export const isUserWorkcardOwner = (crews: any, workcard: Workcard): boolean => {
  let isUserWorkcardOwner = false;

  crews?.map((crew: Crew) => {
    crew.crewMembers.map((crewMember: CrewMember) => {
      workcard.employees.map((employee: Employee) => {
        if (crewMember.employeeId === employee.id && crew.id === workcard.crewId) {
          isUserWorkcardOwner = true;
        }
      });
    });
  });

  return isUserWorkcardOwner;
};
