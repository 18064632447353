import { useMsal } from '@azure/msal-react';
import { IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, useIonRouter } from '@ionic/react';
import moment from 'moment';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import menu from '../../data/sidebarMenu.json';
import { setRefreshData, toggleSidebar } from '../../features/Settings/SettingsSlice';
import { useAppSelector, useAppDispatch } from '../../hooks/hooks';

import { selectMenuItem } from './SidebarSlice';

import './Sidebar.scss';

export const Sidebar: React.FC<unknown> = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { instance } = useMsal();
  const location = useLocation();

  const mailAPD = `mailto:apd.services@agnicoeagle.com?subject=E-WORKCARD: Help / Feedback`;

  const menuWorkcard: any = [
    {
      id: 'Greeting',
      name: t('LABELS.GREETING'),
      icon: 'greeting.svg',
      class: 'class-icon-greeting',
      classSelected: 'h-12 w-12',
      route: 'greeting',
    },
    {
      id: 'Inspection',
      name: t('LABELS.INSPECTION'),
      icon: 'inspection-planif-02.svg',
      class: 'class-icon-inspec-plan',
      classSelected: 'h-12 w-12',
      route: 'inspec-plan',
    },
    {
      id: 'plan-decision',
      name: t('LABELS.DECISION'),
      icon: 'decision.svg',
      class: 'class-icon-decision',
      classSelected: 'h-12 w-12',
      route: 'decision',
    },
    {
      id: 'Execution',
      name: t('LABELS.EXECUTION'),
      icon: 'execution.svg',
      class: 'class-icon-execution',
      classSelected: 'h-12 w-12',
      route: 'execution',
    },
    {
      id: 'Report',
      name: t('LABELS.REPORT'),
      icon: 'report.svg',
      class: 'class-icon-report',
      classSelected: 'h-12 w-12',
      route: 'report',
    },
  ];

  const menu = [
    {
      id: 'home',
      name: t('FEATURES.SIDEBAR.HOME'),
      icon: 'fa-house',
      class: 'w-14 h-14',
      classSelected: 'h-12 w-12',
      route: 'home',
    },
  ];

  const selectedMenuItem = useAppSelector((state) => state.sidebar.value);
  const isSidebarExpanded = useAppSelector((state) => state.settings.isSidebarExpanded);
  const dispatch = useAppDispatch();

  const gotoRoute = (route: string) => {
    if (location.pathname.includes('home')) {
      dispatch(setRefreshData(moment().format('YYYY-MM-DD HH:MM:ss')));
    }

    dispatch(selectMenuItem(route));
    router.push(`/${route}`, route === 'home' ? 'back' : 'forward');
  };

  const handleToggleSidebar = () => {
    dispatch(toggleSidebar(!isSidebarExpanded));
  };

  const handleMenuItemWorkcard = (item: any) => {
    dispatch(selectMenuItem(item.id));
  };

  const handleMenuItemSelected = (item: any) => {
    dispatch(selectMenuItem(item.id));
    gotoRoute(item.route);
  };

  function handleSignOutClick(instance: any) {
    const logoutRequest = {
      account: instance.getAccountByHomeId(process.env.REACT_APP_CLIENT_ID!),
      postLogoutRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
      mainWindowRedirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
    };
    instance.logoutRedirect(logoutRequest);
  }

  return (
    <Suspense fallback={<div />}>
      <div className={`mt-[78px] flex min-h-0 flex-1 overflow-hidden h-full fixed z-50`}>
        <nav
          className={`hidden shrink-0 bg-gradient-to-r from-black to-dark-tertiary lg:block border-r-[9px] ${
            selectedMenuItem === 'home' || selectedMenuItem === 'settings'
              ? 'border-black'
              : `selected-border-${selectedMenuItem}`
          } `}
        >
          <div
            className={`relative flex h-[calc(100%-71px)] ${
              isSidebarExpanded ? 'w-[280px]' : 'w-20'
            } flex-col space-y-10 pl-0 mt-0`}
          >
            {menu.map((item, index) => (
              <div className="text-white !mb-[32px] sidebar-item cursor-pointer" key={`menu-${index}`}>
                <div
                  className={`inline-flex shrink-0 items-center ${
                    isSidebarExpanded ? 'justify-between !w-full  pl-5' : 'justify-center pl-6'
                  } sidebar-item-content ${item.class}`}
                >
                  <i
                    onClick={() => handleMenuItemSelected(item)}
                    className={`fa-solid ${item.icon} ml-1 text-[22px] ${
                      selectedMenuItem === item.id ? 'text-white' : 'text-white'
                    }`}
                  ></i>

                  {item.id === 'home' && isSidebarExpanded && (
                    <i
                      onClick={() => handleToggleSidebar()}
                      className={`ml-4 mr-4 fa-solid ${
                        isSidebarExpanded ? 'fa-chevron-left' : 'fa-chevron-right'
                      } text-[20px]`}
                    ></i>
                  )}
                </div>
              </div>
            ))}

            {location.pathname.includes('/workcard/') &&
              menuWorkcard.map((item: any, index: number) => (
                <div
                  onClick={() => handleMenuItemWorkcard(item)}
                  className={`${
                    selectedMenuItem === item.id && `selected-${item.route} !bg-black`
                  } relative text-white sidebar-item cursor-pointer flex justify-center !mt-[16px] py-3 workcard-item ${
                    isSidebarExpanded ? 'pl-3' : 'pl-0'
                  }`}
                  key={`menu-${index}`}
                >
                  <div
                    className={`${item.class} ${
                      isSidebarExpanded && 'expanded '
                    } inline-flex shrink-0 items-center justify-center sidebar-item-content ${item.class}`}
                  >
                    <IonIcon src={`./assets/img/${item.icon}`} />
                  </div>
                  {isSidebarExpanded && (
                    <div
                      className={`ml-3 mt-3 w-[150px] text-[17px] uppercase font-bold leading-[17px] text-left text-gray-300 ${
                        item.id === 'Inspection' && 'mt-4'
                      } ${item.id === 'plan-decision' && '!mt-1'} `}
                    >
                      {item.name}
                    </div>
                  )}
                </div>
              ))}

            <div className="flex flex-col pb-8 !mt-auto">
              <div className="px-4 sm:px-6">
                {!isSidebarExpanded ? (
                  <i
                    onClick={() => handleToggleSidebar()}
                    className={`fa-solid fa-plus ml-[6px] text-[22px] cursor-pointer `}
                  ></i>
                ) : (
                  <div className="flex justify-between gap-4 mt-7">
                    <div
                      onClick={() => {
                        gotoRoute('settings');
                      }}
                      className="cursor-pointer flex flex-col justify-center text-center"
                    >
                      <i className="fa-solid fa-user-gear text-[28px] mb-1.5 mt-1 ml-1"></i>
                      <span className="uppercase text-white text-[10px] font-semibold">{t('LABELS.SETTING')}</span>
                    </div>
                    <a href={mailAPD} className="flex flex-col justify-center text-center cursor-pointer">
                      <i className="fa-solid fa-circle-question text-[28px] mb-1.5 mt-1"></i>
                      <span className="uppercase text-white text-[10px] font-semibold">{t('LABELS.HELP')}</span>
                    </a>
                    <div
                      onClick={() => handleSignOutClick(instance)}
                      className="flex flex-col justify-center text-center cursor-pointer"
                    >
                      <i className="fa-solid fa-right-from-bracket text-[28px] mb-1.5 mt-1 ml-1"></i>
                      <span className="uppercase text-white text-[10px] font-semibold">{t('LABELS.SIGNOUT')}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </div>
    </Suspense>
  );
};
