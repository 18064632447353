import { useTranslation } from 'react-i18next';

import { StatusTag } from '../../../../components/StatusTag/StatusTag';
import { TaskProgressBar } from '../../../../components/TaskProgressBar/TaskProgressBar';

export const PreviousWorkcard: React.FC<unknown> = () => {
  const { t } = useTranslation();

  const item = {
    date: {
      day: '09',
      month: 'JAN',
      time: '06:30 PM',
    },
    location: 'X751P332',
    tasks: 5,
  };

  const previousWorkcardStatus = {
    id: 3,
    title: 'Completed',
    name: '',
  };

  const previousWorkcardTasks = {
    total: 4,
    completed: 4,
  };

  return (
    <>
      <h2 className="font-semibold uppercase mb-4 truncate dark:text-quartery-default">
        {t('FEATURES.DASHBOARD_EMPLOYEE.PREVIOUS_WORKCARD')}
      </h2>
      <div className="border border-gray-300 bg-white shadow rounded px-3 py-2 text-gray-700 dark:border-gray-800 dark:bg-secondary-default dark:text-quartery-default">
        <div className="card">
          <div className="card-header">
            <span>
              <i className="far fa-clock"></i> {item.date.time}
            </span>

            <span className="float-right mt-[-1px]">
              <span className="inline-flex items-center rounded bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-gray-700">
                <i className="fa-solid fa-location-dot text-gray-700 mr-1.5"></i>
                {item.location}
              </span>
            </span>
          </div>
          <div className="card-body mt-2 text-center margin-auto dark:text-quartery-default">
            <div className="card-title mb-0">
              <span className="font-semibold text-[45px]">{item.date.day}</span>
              <span className="font-thin uppercase text-[25px]">{item.date.month}</span>
            </div>

            <div className="px-6 text-center text-sm font-medium">
              <TaskProgressBar tasks={previousWorkcardTasks} isLabel={true} />
            </div>

            <div className="m-auto mt-3 mb-2 text-center text-sm font-medium">
              <StatusTag status={previousWorkcardStatus} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
