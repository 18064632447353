import type { Site } from '../../models';

import { baseQuery, createApi } from './base.service';

// Define a service using a base URL and expected endpoints
export const siteService = createApi({
  reducerPath: 'siteService',
  baseQuery: baseQuery,
  tagTypes: ['sites'],
  endpoints: (builder) => ({
    getSites: builder.query<Site[], void>({
      query: () => `Site`,
      providesTags: ['sites'],
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetSitesQuery } = siteService;
