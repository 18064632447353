export const Loader: React.FC<{ isLoading?: boolean }> = (props) => {
  return (
    <>
      <div className="fixed lg:absolute h-full w-full bg-[#18191E]/50 z-50 flex justify-center items-center">
        <div className="m-auto text-[40px] top-[-66px] lg:top-0 left-0 right-0 bottom-0 relative">
          <i className="fas fa-spinner fa-pulse"></i>
        </div>
      </div>
    </>
  );
};
