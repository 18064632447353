import React, { useContext } from 'react';

import { LastUpdateInfo } from '../../../../../../components/LastUpdateInfo/LastUpdateInfo';

import { WorkcardInspectionChecklist } from './WorkcardInspectionChecklist/WorkcardInspectionChecklist';

export const WorkcardInspection: React.FC<unknown> = () => {
  return (
    <>
      <div className=" py-5 px-4 ">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 lg:grid-cols-2">
          <div className="lg:col-span-2 ">
            <WorkcardInspectionChecklist />
          </div>
        </dl>
      </div>
    </>
  );
};
