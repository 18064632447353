import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { setRefreshData } from '../../features/Settings/SettingsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';

export const LastUpdateInfo: React.FC<{ isDashboard?: boolean }> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const lastUpdate = useAppSelector((state) => state.settings.lastUpdate);

  const handleRefresh = () => {
    dispatch(setRefreshData(moment().format('YYYY-MM-DD HH:MM:ss')));
  };

  return (
    <>
      <div className={`${!props.isDashboard && 'ml-[-12px]'} text-center  mt-[-3px]`}>
        <div className="">
          <p className={`${!props.isDashboard ? 'text-[13px] leading-5' : 'text-[10px] leading-6'}   text-gray-300`}>
            <i
              onClick={() => {
                handleRefresh();
              }}
              className="cursor-pointer fa-solid fa-rotate text-[18px] ml-1.5 mr-2.5"
            ></i>
            <span className="mr-2">{t('LABELS.LAST_UPDATE')}</span>
            <span className="">{moment(lastUpdate).format('YYYY-MM-DD HH:mm') || 'N/A'}</span>
          </p>
        </div>
      </div>
    </>
  );
};
