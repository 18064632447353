import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ConnectivityStatusState {
  isOnline: boolean;
}

const initialState: ConnectivityStatusState = {
  isOnline: true,
};

export const connectivityStatusSlice = createSlice({
  name: 'connectivityStatus',
  initialState,
  reducers: {
    setConnectivityStatus: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConnectivityStatus } = connectivityStatusSlice.actions;

export default connectivityStatusSlice.reducer;
