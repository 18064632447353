import { useIonRouter } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { getpendingPlanificationCount } from '../../../../../../../../services/common/planification.utils';
import {
  useGetWorkcardTaskInpsectionQuery,
  useGetWorkcardTaskPlanQuery,
} from '../../../../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../../../../store/store';

import { WorkcardTaskListItemStatus } from './components/WorkcardTaskListItemStatus/WorkcardTaskListItemStatus';

import './WorkcardTaskListItem.scss';

type SelectItem = (e: React.MouseEvent, value: boolean) => void;

export const WorkcadTaskListItem: React.FC<{
  onSelectItem: SelectItem;
  item: any;
  index: number;
  showTaskDetail: boolean;
  selectedItemId: number;
}> = (props) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const router = useIonRouter();

  const [showTaskDetail, setTaskDetail] = useState(false);
  const [selectedPendingPlanCount, setPendingPlanCount] = useState<number>(0);

  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const { data, error, isLoading, refetch } = useGetWorkcardTaskInpsectionQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
    taskId: +props.item.id!,
    lang: lang,
  });

  const { data: dataTask } = useGetWorkcardTaskPlanQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
    taskId: +props.item.id!,
    lang: lang,
  });

  const handleTaskDetail = (e: React.MouseEvent, item: any) => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
      router.push(`/workcard/${id}/workcard-task-detail/${item.id}`, 'forward');
      props.onSelectItem(e, item);
    } else {
      props.onSelectItem(e, item);
      setTaskDetail(true);
    }
  };

  useEffect(() => {
    if (dataTask) {
      setPendingPlanCount(getpendingPlanificationCount(dataTask.plans));
    }
  }, [dataTask]);

  useEffect(() => {
    setTaskDetail(props.showTaskDetail);
  }, [props.showTaskDetail]);

  return (
    <li
      className={`${showTaskDetail === true && props.selectedItemId === props.item.id
          ? '!bg-gray-300 dark:!bg-dark-primary'
          : ' dark:lg:!bg-dark-secondary  cursor-pointer dark:hover:bg-gray-500 hover:bg-gray-200 '
        }    border border-[2px] !border-gray-400 dark:!border-workcard-inspection-primary dark:my-3 dark:ml-2 dark:rounded-xl bg-white lg:bg-gray-100  dark:bg-dark-primary  rounded-xl lg:rounded-none mx-2 lg:mx-0 my-4 lg:my-0 shadow-md lg:shadow-none workcardTaskItem dark:!border-b-[1px] dark:border-solid`}
      onClick={(e) => handleTaskDetail(e, props.item)}
    >
      <div className="">
        <div className="flex flex-row h-auto">
          <div className="relative">
            <p className=" bg-gradient-to-b from-workcard-inspection-secondary to-[#c31956] h-full rounded-l-lg px-2 text-[40px] font-bold  leading-5 text-dark-primary flex items-center">
              {props.index}
            </p>
          </div>
          <div className="flex-col w-full pb-3">
            <div className="mt-[3px]">
              <div className="relative">
                <p className=" text-sm text-gray-500 lg:mt-0 text-end ">
                  <span className="inline-flex items-center rounded  px-2.5 py-0.5 text-[13px] font-bold text-workcard-inspection-secondary">
                    <i className="fa-solid fa-location-dot text-workcard-inspection-secondary mr-1.5 text-[14px] relative top-[-2px]"></i>
                    {props.item.location || 'N/A'}
                  </span>
                </p>
              </div>
            </div>
            <div className="px-2.5 pt-0">
              <div className="">
                <div className={` text-sm  font-bold text-black dark:text-quartery-default mb-2.5 mt-0.5`}>
                  {props.item.name}
                </div>
              </div>
            </div>
            <div className="text-end ml-auto flex justify-end pr-2 mt-2">
              <div className="uppercase bg-gradient-to-r from-[#c31956] to-workcard-inspection-primary px-1 py-1.5   text-xs w-[134px] border-[3px] border-workcard-inspection-secondary rounded-xl flex items-center">
                <div className="pl-4 pr-3 text-center text-[11px] font-medium leading-[12px]">
                  {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.SELECT_THIS_TASK')}
                </div>

                <i className="fa-solid fa-circle-arrow-right mr-1 text-[14px]"></i>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </li>
  );
};
