import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useIonAlert, useIonModal, useIonRouter } from '@ionic/react';
import { useState, Fragment, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { AddUserToCrewModal } from '../../components/AddUserToCrewModal/AddUserToCrewModal';
import { AppRolesId } from '../../constants';
import languageOptions from '../../data/languageOptions.json';
import { DashboardFilterView } from '../../enums/dashboardFilterView.enum';
import { useAppSelector, useAppDispatch } from '../../hooks/hooks';
import { Content } from '../../layout/content/content';
import { Header } from '../../layout/header/Header/Header';
import type { ChangeRoleForCrew, Crew } from '../../models';
import type { UserProfile } from '../../models/userProfile.model';
import { getUserStatus, isUserSupervisor } from '../../services/common/profile.utils';
import {
  useChangeRoleForCrewMutation,
  useGetMyProfileQuery,
  useUpdateMyProfileMutation,
} from '../../services/data/profile.service';
import { setFilterView } from '../Dashboard/components/DashboardFilters/DashboardFiltersSlice';

import { SettingsLoader } from './SettingsLoader';
import { toggleDarkmode, setLang } from './SettingsSlice';

import './Settings.scss';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export const Settings: React.FC = () => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState(languageOptions[0]);
  const isDarkMode = useAppSelector((state) => state.settings.darkmode);
  const selectedSiteId: any = useAppSelector((state) => state.auth.siteId);
  const lang = useAppSelector((state) => state.settings.lang);
  const isOnline = useAppSelector((state) => state.connectivityStatus.isOnline);

  const { t, i18n } = useTranslation();
  const { data, error, isLoading, refetch } = useGetMyProfileQuery();
  const [updateMyProfile, response] = useUpdateMyProfileMutation();
  const [changeRole, res] = useChangeRoleForCrewMutation();

  const [presentAlert, hideAlert] = useIonAlert();

  const [present, dismiss] = useIonModal(AddUserToCrewModal, {
    siteId: selectedSiteId,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const updateLang = (langOption: any) => {
    const payload: UserProfile = { ...data! };

    dispatch(setLang(langOption.lang));
    i18n.changeLanguage(langOption.lang);
    setSelected(langOption);
    toast.success(t('FEATURES.SETTINGS.LANG_CHANGED_SUCCESSFULLY'), { duration: 2000, position: 'top-center' });
  };

  const handleEditRisk = () => {
    router.push(`/settings/risk-list`, 'forward');
  };

  const handleEditControlMeasure = () => {
    router.push(`/settings/control-measure-list`, 'forward');
  };

  const handleGenerateWorkcard = (e: any) => {
    router.push('/settings/generate-demo-workcard', 'forward');
  };

  const handleAddUserToCrew = (e: any) => {
    present({
      onWillDismiss: (ev: CustomEvent) => {
        //
      },
      id: 'redboxComment',
      cssClass: 'redboxComment',
    });
  };

  const handleChangeDarkmode = (e: any) => {
    dispatch(toggleDarkmode(e.target.checked));

    toast.success(
      t(`${e.target.checked ? 'FEATURES.SETTINGS.DARK_MODE_TURN_ON' : 'FEATURES.SETTINGS.DARK_MODE_TURN_OFF'}`),
      {
        duration: 1500,
        position: 'top-center',
      }
    );
  };

  const handleRoleChanged = (ev: any, crew: Crew) => {
    console.log('change role', ev.value);

    const employeeRole = ev.value === 'Supervisor' ? 1 : 0;

    const payload: ChangeRoleForCrew = {
      crewId: crew.id,
      id: crew.crewMembers[0].id,
      employeeId: crew.crewMembers[0].employeeId,
      employeeLevel: employeeRole,
    };

    changeRole(payload);

    if (employeeRole === 0) {
      dispatch(setFilterView(DashboardFilterView.myCrews));
    }

    hideAlert();

    toast.success(t('FEATURES.SETTINGS.ROLE_CHANGED_SUCCESSFULLY'), { duration: 2000, position: 'top-center' });
  };

  useEffect(() => {
    languageOptions.map((languageOption) => {
      if (languageOption.lang === lang) {
        setSelected(languageOption);
      }
    });

    refetch();
  }, []);

  // useEffect(() => {
  //   if (data) {
  //     const selectedLang = languageOptions.find((langOption) => langOption.id === data.language);
  //     setSelected(selectedLang!);
  //     i18n.changeLanguage(selectedLang?.lang || 'en');
  //   }
  // }, [data]);

  return (
    <>
      {/* <SettingsLoader /> */}
      {/* {data && ( */}
      <Content header={<Header title={t('FEATURES.SETTINGS.SETTINGS')} hasProfile={true} />}>
        {/* <Toaster
          toastOptions={{
            className: 'dark:bg-primary-default dark:text-quartery-default',
          }}
        /> */}
        <div>
          <div className="profile-detail mb-10 pb-[80px]">
            <div className="mt-6 max-w-5xl mx-auto px-6 sm:px-6 lg:px-8">
              <div>
                <h3 className="text-lg leading-6 font-bold text-gray-900 dark:text-gray-100 mb-6">
                  <i className="fa-solid fa-user mr-2"></i>
                  {t('FEATURES.SETTINGS.PROFILE')}
                </h3>
              </div>

              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-base font-medium text-gray-500 dark:text-gray-400">
                    {t('FEATURES.SETTINGS.DISPLAY_NAME')}
                  </dt>
                  <dd className="mt-1 text-base text-gray-900 dark:text-gray-100">
                    {data?.name || t('LABELS.SELECT')}
                  </dd>
                </div>

                {/* <div className="sm:col-span-1">
                  <dt className="text-base font-semmediumibold text-gray-500 dark:text-gray-400">
                    {t('FEATURES.SETTINGS.EMAIL')}
                  </dt>
                  <dd className="mt-1 text-base text-gray-900 dark:text-gray-100">{data?.email || 'N/A'}</dd>
                </div> */}

                <div className="sm:col-span-1">
                  <dt className="text-base font-medium text-gray-500 dark:text-gray-400">
                    {t('FEATURES.SETTINGS.JOB_TITLE')}
                  </dt>
                  <dd className="mt-1 text-base text-gray-900 dark:text-gray-100">
                    {data?.jobPosition || t('LABELS.SELECT')}
                  </dd>
                </div>

                {/* <div className="sm:col-span-1">
                  <dt className="text-base font-medium text-gray-500 dark:text-gray-400">
                    {t('FEATURES.SETTINGS.DEPARTMENT')}
                  </dt>
                  <dd className="mt-1 text-base text-gray-900 dark:text-gray-100">{data?.department || 'N/A'}</dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-base font-medium text-gray-500 dark:text-gray-400">
                    {t('FEATURES.SETTINGS.PHONE_MOBILE')}
                  </dt>
                  <dd className="mt-1 text-base text-gray-900 dark:text-gray-100">{data?.phoneNumber || 'N/A'}</dd>
                </div> */}
              </dl>

              <div className="mt-0">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 mb-6 mt-10">
                  <i className="fa-solid fa-users mr-2"></i>
                  {t('FEATURES.SETTINGS.CREW')}
                </h3>
              </div>

              <div>
                <div className="mt-6 flow-root">
                  <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {data &&
                      data.crews.map((crew: any) => (
                        <li key={crew.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-gray-100 shadow">
                          <div className="flex w-full items-center justify-between space-x-6 p-6">
                            <div className="flex-1 truncate">
                              <div className="flex items-center space-x-3">
                                <h3 className="truncate text-sm font-bold text-gray-900">{crew.name}</h3>
                                <span
                                  onClick={() =>
                                    presentAlert({
                                      header: t('FEATURES.SETTINGS.SELECT_ROLE'),
                                      buttons: [],
                                      inputs: [
                                        {
                                          label: t('FEATURES.SETTINGS.SUPERVISOR'),
                                          type: 'radio',
                                          value: 'Supervisor',
                                          checked: getUserStatus(crew.crewMembers, data) === 'Supervisor',
                                          handler: (e) => handleRoleChanged(e, crew),
                                        },
                                        {
                                          label: t('FEATURES.SETTINGS.WORKER'),
                                          type: 'radio',
                                          value: 'Worker',
                                          checked: getUserStatus(crew.crewMembers, data) === 'Worker',
                                          handler: (e) => handleRoleChanged(e, crew),
                                        },
                                      ],
                                    })
                                  }
                                  className={`${
                                    !isOnline ? 'pointer-events-none bg-gray-200' : 'bg-blue-100'
                                  } cursor-pointer flex-shrink-0 inline-block items-center rounded-full  px-3 py-0.5 text-sm font-medium text-blue-800`}
                                >
                                  {getUserStatus(crew.crewMembers, data) === 'Supervisor'
                                    ? t('FEATURES.SETTINGS.SUPERVISOR')
                                    : t('FEATURES.SETTINGS.WORKER')}
                                </span>
                              </div>
                            </div>

                            <div className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300">
                              <span className="ml-2 relative top-1.5 font-medium">{crew?.crewMembers?.length}</span>
                              <i className="text-[12px] ml-0.5 top-[5px] relative fa-solid fa-user"></i>
                            </div>
                          </div>
                        </li>
                      ))}
                    {isUserSupervisor(data?.crews, data?.id) && isOnline && (
                      <>
                        <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-gray-100 shadow flex inline-flex items-center justify-center">
                          <button
                            onClick={(e) => handleGenerateWorkcard(e)}
                            type="button"
                            className="mr-4 ml-4 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                          >
                            <i className="fa-solid fa-plus text-white mr-2"></i>
                            {t('FEATURES.CREW_MGMT.GENERATE_DEMO_WORKCARD')}
                          </button>
                        </li>
                        <li className="py-6 col-span-1 divide-y divide-gray-200 rounded-lg bg-gray-100 shadow flex inline-flex items-center justify-center">
                          <button
                            onClick={(e) => handleAddUserToCrew(e)}
                            type="button"
                            className="mr-8 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                          >
                            <i className="fa-solid fa-plus text-white mr-2"></i>
                            Add user to crew
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>

              <div className="mt-0">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 mb-6 mt-10">
                  <i className="fa-solid fa-gear mr-2"></i>
                  {t('FEATURES.SETTINGS.SETTINGS')}
                </h3>
              </div>

              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1 flex mt-2">
                  <dt className="text-base font-medium text-gray-500 dark:text-gray-400 mr-8">
                    {t('FEATURES.SETTINGS.LANGUAGE')}
                  </dt>
                  <dd className="-mt-2 text-base text-gray-900">
                    <Listbox value={selected} onChange={updateLang}>
                      {({ open }) => (
                        <>
                          <div className="relative">
                            <div className="inline-flex divide-x divide-blue-600 rounded-md shadow-sm">
                              <div className="inline-flex divide-x divide-blue-600 rounded-md shadow-sm">
                                <div className="inline-flex items-center rounded-l-md border border-transparent bg-blue-700 dark:bg-[#009cde] py-2 pl-3 pr-4 text-white shadow-sm">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  <p className="ml-2.5 text-sm font-medium">{selected.title}</p>
                                </div>
                                <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-blue-700 dark:bg-[#009cde] p-2 text-sm font-medium text-white hover:bg-blue-800 ">
                                  <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                </Listbox.Button>
                              </div>
                            </div>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute left-0 z-10 mt-2 w-[150px] origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {languageOptions.map((option) => (
                                  <Listbox.Option
                                    key={option.id}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'text-white bg-blue-700 dark:bg-[#009cde]' : 'text-gray-900',
                                        'cursor-default select-none p-4 text-sm'
                                      )
                                    }
                                    value={option}
                                  >
                                    {({ selected, active }) => (
                                      <div className="flex flex-col">
                                        <div className="flex justify-between">
                                          <p className={selected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                                          {selected ? (
                                            <span className={active ? 'text-white' : 'text-blue-500'}>
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </dd>
                </div>
              </dl>

              {/* <div className="mt-0">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 mb-6 mt-10">
                  <i className="fa-solid fa-gear mr-2"></i>
                  Risk and Control Measure
                </h3>
              </div>

              <div>
                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">Risks</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900 dark:text-gray-400">18 items</div>
                      <button
                        onClick={handleEditRisk}
                        type="button"
                        className="mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                      >
                        Edit
                      </button>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 dark:text-white">
                      Control Measure
                    </dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900 dark:text-gray-400">18 items</div>
                      <button
                        onClick={handleEditControlMeasure}
                        type="button"
                        className="mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                      >
                        Edit
                      </button>
                    </dd>
                  </div>
                </dl>
              </div> */}
            </div>
          </div>
        </div>
      </Content>
      {/* )} */}
    </>
  );
};
