import { Combobox, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { IonContent, IonPage } from '@ionic/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { classNames } from '../../services/common/workcard.utils';
import { useGetCrewsQuery, useAddUserToCrewMutation } from '../../services/data/dataCreation.service';
import { useGetADSearchQuery } from '../../services/data/profile.service';
import { usePostWorkcardRedboxMutation } from '../../services/data/workcard.service';
import type { RootState } from '../../store/store';

import './AddUserToCrewModal.scss';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AddUserToCrewModal = ({
  siteId,
  onDismiss,
}: {
  workcardId: number;
  siteId: number;
  taskId?: number;
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
}) => {
  const { t } = useTranslation();
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const [userId, setUserId] = useState('');
  const [data, setData] = useState();
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedSearchText, setSelectedSearchText] = useState('');
  const [skip, setSkip] = useState(true);

  const [debouncedValue] = useDebounce(selectedSearchText, 1000);

  const { data: users, error, isLoading, refetch } = useGetADSearchQuery({ searchText: selectedSearchText }, { skip });
  const [addUserToCrew, resAddUserToCrew] = useAddUserToCrewMutation();
  const { data: crew, error: errorCrew, isLoading: isLoadingCrew } = useGetCrewsQuery();

  const handleUserChanged = (ev: any) => {
    setUserId(ev.detail.value);
  };

  const {
    watch,
    register,
    formState: { errors, isValid },
    handleSubmit,
    trigger,
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const submit = async () => {
    trigger();
    handleSubmit(onSubmit);

    const user = getValues('user');
    const crew = getValues('crew');

    if (crew && user) {
      const payload = {
        crewId: crew.id,
        employeeRole: 1,
        userObjectId: user.id,
      };

      await addUserToCrew(payload);

      onDismiss(null, 'confirm');
    }
  };

  const onSubmit = (data: any) => {
    trigger();
    setData(data);
  };

  const updateUser = (user: any) => {
    setSelectedUser(user);
    setValue('user', user);
  };

  const searchUser = (search: string) => {
    if (search.length > 2) {
      setSelectedSearchText(search);
    } else {
      setSkip(true);
      setSelectedUser(null);
      setSelectedSearchText('');
      setValue('user', null);
    }
  };

  useEffect(() => {
    if (debouncedValue && skip) {
      setSkip(false);
    }
  }, [debouncedValue]);

  useEffect(() => {
    watch((value, { name, type }) => {
      console.log(value, name, type);
      trigger(name);
    });
  }, [watch]);

  useEffect(() => {
    if (resAddUserToCrew?.isSuccess) {
      toast.success('User added successfully !', { duration: 2000, position: 'top-center' });
    }
  }, [resAddUserToCrew]);

  return (
    <IonPage>
      <IonContent className="ion-padding redbox-modal">
        <div className="flex justify-center mb-6">
          <span className="dark:text-quartery-default">Add User to Crew</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold flex gap-2 relative md:mb-5">
            Crew
          </dt>
          <dd className={` relative col-span-6 text-lg text-gray-900 sm:mt-[-18px] mb-3 mt-1.5 `}>
            <div className="mt-0 flex rounded-md shadow-sm">
              <Controller
                control={control}
                name="crew"
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Listbox value={value} onChange={onChange}>
                    {({ open }) => (
                      <>
                        <div className="relative mt-2 w-full">
                          <Listbox.Button className="relative w-full rounded-md bg-black h-[48px] pl-3 pr-10 text-left  shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none sm:text-sm sm:leading-6 text-white cursor-pointer">
                            <span className="block truncate">{value?.name}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </Listbox.Button>

                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-900 py-1 text-base shadow-lg ring-1 ring-gray-400  focus:outline-none sm:text-sm text-white">
                              {crew?.map((crew: any) => (
                                <Listbox.Option
                                  key={crew.id}
                                  className={({ active }) =>
                                    classNames(
                                      active ? 'bg-gray-600 text-white' : 'text-white',
                                      'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={crew}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected ? 'font-semibold' : 'font-normal',
                                          'block truncate'
                                        )}
                                      >
                                        {crew.name}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active ? 'text-white' : 'text-indigo-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                )}
              />
            </div>
            {errors[`crew`]?.type === 'required' && (
              <div className="absolute mt-[1px] text-[13px] text-red-600" role="alert">
                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
              </div>
            )}
          </dd>

          <dt className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold flex gap-2 relative pt-7 pb-2 md:mb-5">
            User
          </dt>
          <dd className={` relative col-span-6 text-lg text-gray-900 sm:mt-[-18px] mb-3 mt-1.5 `}>
            <div className="mt-0  rounded-md shadow-sm">
              <Controller
                control={control}
                name="user"
                rules={{ required: true }}
                render={({
                  field: { onChange, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <Combobox name={name} value={selectedUser} onChange={updateUser}>
                    <Combobox.Input
                      autoComplete="off"
                      onChange={(event) => searchUser(event.target.value)}
                      displayValue={(user: any) => {
                        return user?.displayName;
                      }}
                      className="focus:outline-none bg-black w-full h-[50px] rounded-xl text-white border border-gray-700 text-[13px]"
                    />
                    {users && users.length > 0 && (
                      <Combobox.Options className="bg-dark-tertiary border border-gray-500 rounded-md max-h-[200px] absolute w-full z-10 overflow-y-scroll text-white p-2 text-[18px] mt-2">
                        {users &&
                          users?.map((user) => (
                            <Combobox.Option key={user.id} value={user} className="hover:bg-dark-primary  py-2">
                              {user.displayName}
                            </Combobox.Option>
                          ))}
                      </Combobox.Options>
                    )}
                    {users && users.length === 0 && (
                      <Combobox.Options className="bg-dark-tertiary border border-gray-500 rounded-md max-h-[200px] absolute w-full z-10 overflow-y-scroll text-white p-2 text-[18px] mt-2">
                        <Combobox.Option key="location-empty" value="" className="hover:bg-dark-primary  py-2">
                          <i className="fa-solid fa-triangle-exclamation mr-2 ml-2"></i>
                          No user found
                        </Combobox.Option>
                      </Combobox.Options>
                    )}
                  </Combobox>
                )}
              />

              <div className="h-[10px]">
                {errors[`user`]?.type === 'required' && (
                  <div className="absolute bottom-[-18px] text-[13px] text-red-600" role="alert">
                    <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                    <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                  </div>
                )}
              </div>
            </div>
          </dd>

          <footer className="absolute bottom-6 w-full left-1 px-4">
            <div className="flex justify-between">
              <button
                onClick={() => onDismiss(null, 'confirm')}
                type="button"
                className="inline-flex items-center justify-center  rounded-xl px-4 py-1 w-[120px]   text-workcard-redbox-primary shadow-sm  sm:w-auto mr-3 mt-[-6px] border-[2px] border-workcard-redbox-primary border-solid text-[15px] uppercase font-bold"
              >
                {t('LABELS.CANCEL')}
              </button>
              <button
                onClick={() => submit()}
                type="button"
                className="inline-flex items-center justify-center rounded-xl border border-transparent px-4 py-1 w-[120px]  text-white shadow-sm  focus:outline-none  sm:w-auto mr-3 mt-[-6px] bg-gradient-to-r from-workcard-redbox-primary to-workcard-redbox-secondary text-[15px] uppercase font-bold"
              >
                {t('LABELS.SAVE')}
                <i className="fa-solid fa-circle-right ml-1.5 mt-[-1px] "></i>
              </button>
            </div>
          </footer>
        </form>
      </IonContent>
    </IonPage>
  );
};
