import { useMsal } from '@azure/msal-react';
import React, { useState, useEffect, Fragment, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import type { RootState } from '../../store/store';
import { loginRequest } from '../../utils/authConfig';

import bgImage from './Home_BKGD.png';

export const Auth: React.FC = () => {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const selectedSidebarItem = useSelector((state: RootState) => state.sidebar.value);

  const handleLogin = (loginType: string) => {
    if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };

  return (
    <div
      style={{ backgroundImage: `url('${bgImage}')` }}
      className="flex min-h-full flex-col justify-between sm:justify-center pt-8 pb-8 px-7 sm:px-6 lg:px-8 bg-cover"
    >
      <div className="mx-auto w-[285px] sm:max-w-md bg-white bg-opacity-[60%] rounded-2xl pt-8 pb-6 sm:mt-[-200px]">
        <img src="./assets/img/Home_Logo.png" alt="" className="w-[144px] m-auto" />
        <h2 className="mt-6 text-center text-[35px] font-bold tracking-tight text-gray-900">
          <p className="leading-[23px]">Electronic</p>
          <p>WorkCard</p>
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className=" py-8 px-4  sm:px-10">
          <div className="text-center">
            <button
              onClick={() => handleLogin('redirect')}
              className="flex w-full justify-center rounded-xl border-solid border-[3px] border-[#3db6c2] bg-gradient-to-r from-[#3eb3c5] to-[#418be2] py-3 px-4 text-[20px] font-bold text-white shadow-sm uppercase "
            >
              {t('FEATURES.AUTH.LOGIN_SSO')}
              <i className="fa-solid fa-arrow-right-to-bracket ml-3 mt-[4px] text-[20px]"></i>
            </button>
            <p className="text-black text-[13px] mt-0.5 text-semibold">( Business account )</p>
          </div>
        </div>
      </div>
    </div>
  );
};
