import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { Site } from '../../models';

export interface SiteState {
  value: Site | null;
}

const initialState: SiteState = {
  value: null,
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setSite: (state, action: PayloadAction<Site>) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSite } = siteSlice.actions;

export default siteSlice.reducer;
