import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import type { UserProfile } from '../../models';

export interface UserSearchModalState {
  isActive: boolean;
  isAllSites: boolean;
  recentSearch: UserProfile[];
}

const initialState: UserSearchModalState = {
  isActive: true,
  isAllSites: false,
  recentSearch: [],
};

export const userSearchModalSlice = createSlice({
  name: 'userSearchModal',
  initialState,
  reducers: {
    toggleIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    toggleIsAllSites: (state, action: PayloadAction<boolean>) => {
      state.isAllSites = action.payload;
    },
    addRecentSearch: (state, action: PayloadAction<UserProfile>) => {
      const cloneArr = state?.recentSearch || [];

      if (cloneArr && cloneArr.length >= 4) {
        cloneArr.pop();
      }

      cloneArr.unshift(action.payload);
      state.recentSearch = cloneArr;
    },
    removeRecentSearch: (state, action: PayloadAction<UserProfile>) => {
      let cloneArr = state?.recentSearch || [];
      cloneArr = cloneArr.filter((item) => item.id !== action.payload.id);

      state.recentSearch = cloneArr;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleIsActive, toggleIsAllSites, addRecentSearch, removeRecentSearch } = userSearchModalSlice.actions;

export default userSearchModalSlice.reducer;
