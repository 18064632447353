import { reactHooksModule } from '@reduxjs/toolkit/dist/query/react';
import { fetchBaseQuery, buildCreateApi, coreModule } from '@reduxjs/toolkit/query';

import type { RootState } from '../../store/store';

// wrapper around fetch query to customize HTTP requests (add token in header)
export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.value;

    // Retrieve Access Token from Store
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

export const createApi = buildCreateApi(coreModule(), reactHooksModule({ unstable__sideEffectsInRender: false }));
