import { useIonAlert } from '@ionic/react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { InspectionChecklistAnswerType } from '../../../../../../../../../../enums/inspectionChecklistAnswerType.enum';
import type { RiskAssessmentQuestions } from '../../../../../../../../../../models';
import {
  useCopyWorkerAnswerInpsectionBySupervisorMutation,
  useGetWorkcardTaskPlanQuery,
} from '../../../../../../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../../../../../../store/store';

type SelectItem = (e: React.MouseEvent, index: number) => void;
type closeDetail = () => void;

export const WorkcardInspectionChecklistDetail: React.FC<{
  data: RiskAssessmentQuestions | undefined;
  userType: string;
  taskId: number;
  workcardId: number;
  canCopyWorkerAnswers: boolean;
  onSelectItem: SelectItem;
  onCloseDetail: closeDetail;
}> = (props) => {
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const [presentAlert, hideAlert] = useIonAlert();

  const {
    data: planData,
    error: planError,
    refetch: planRefetch,
  } = useGetWorkcardTaskPlanQuery({
    siteId: selectedSiteId!,
    workcardId: +props.workcardId,
    taskId: +props.taskId,
    lang: lang,
  });

  const [copyWorkerAnswers, responseCopyWorkerAnswers] = useCopyWorkerAnswerInpsectionBySupervisorMutation();

  const handleCopyWorkerAnswers = () => {
    let isDisplayAlert = false;
    let isSupervisorAlreadyAnswer = false;

    if (props.data) {
      isSupervisorAlreadyAnswer = Object.values(props.data.riskAssessmentQuestions).every(
        (x) => x.supervisorAnswer !== null
      );
    }

    if (props.userType !== 'worker' && props.data && isSupervisorAlreadyAnswer) {
      isDisplayAlert = true;
    }

    if (isDisplayAlert) {
      presentAlert({
        header: t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.COPY_ALL_ANSWERS_QUESTION'),
        buttons: [
          {
            text: 'Cancel',
            cssClass: 'alert-button-cancel',
            handler: (e: any) => {
              //
            },
          },
          {
            text: 'Submit',
            cssClass: 'alert-button-confirm',
            handler: async (e: any) => {
              copyWorkerAnswers({ siteId: selectedSiteId, workcardId: props.workcardId, taskId: props.taskId });
            },
          },
        ],
        inputs: [],
      });
    } else {
      copyWorkerAnswers({ siteId: selectedSiteId, workcardId: props.workcardId, taskId: props.taskId });
    }
  };

  const handleSelectItem = (e: React.MouseEvent, index: number) => {
    props.onSelectItem(e, index);
  };

  const { t } = useTranslation();

  const displayPlanIndicator = (item: any) => {
    const planCreated = planData?.plans?.find((plan) => {
      return plan.questionId === item.id;
    });

    return planCreated ? true : false;
  };

  useEffect(() => {
    if (responseCopyWorkerAnswers?.isSuccess) {
      toast.success(t('FEATURES.WORKCARD.WORKCARD_TASK_DETAILS.WORKER_ANSWERS_COPIED_SUCCESSFULLY'), {
        duration: 2000,
        position: 'top-center',
      });
      props.onCloseDetail();
    }
  }, [responseCopyWorkerAnswers]);

  return (
    <>
      <h1 className="bg-black uppercase text-gray-200 font-bold text-[16px] text-center pt-4 pb-4">
        {props.userType !== 'worker'
          ? t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.ALL_WORKER_ANSWER')
          : t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_PLANNING.ALL_MY_ANSWER')}
        <i
          onClick={() => {
            props.onCloseDetail();
          }}
          className="fa-solid fa-xmark text-[14px] mt-[-4px] text-white border-[2px] h-[25px] w-[25px]  border-white float-right mr-[19px] rounded-full pt-[4px]"
        ></i>
      </h1>
      <table className="min-w-full divide-y divide-gray-300 table-fixed w-full bg-black">
        <thead className="">
          <tr>
            <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-400 sm:pl-6 w-[9%]"></th>
            <th
              scope="col"
              className="py-3.5 pr-3 text-left text-[12px] uppercase font-semibold text-[#78787a] sm:pl-0 w-[55%]"
            >
              {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_TASK_LIST.QUESTION')}
            </th>
            <th
              scope="col"
              className="px-2 py-3.5  text-[12px] uppercase font-semibold text-[#78787a] w-[5%] text-center"
            ></th>
            <th
              scope="col"
              className="px-0 py-3.5  text-[12px] uppercase font-semibold text-[#78787a] w-[20%] text-center"
            >
              {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_TASK_LIST.WORKER')}
            </th>
            {props.userType !== 'worker' && (
              <th
                scope="col"
                className="px-0 py-3.5  text-[12px] uppercase font-semibold text-[#78787a] w-[30%] text-center"
              >
                {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_TASK_LIST.SUPERVISOR')}
              </th>
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-800 bg-black">
          {props.data?.riskAssessmentQuestions.map((item, index) => (
            <tr
              onClick={(e) => {
                handleSelectItem(e, index);
              }}
              key={item.id}
              className=" cursor-pointer"
            >
              <td className="py-2.5 pl-[5px] text-sm font-medium text-gray-100 sm:pl-5 w-[3%] break-word text-center">
                <span className="font-extrabold text-[14px]">{index + 1}</span>
              </td>
              <td className="py-2.5 pl-1 pr-0 text-sm text-gray-100 sm:pl-0 w-[61%] break-word leading-[13px] text-[12px] font-semibold">
                {item.question}

                {item.answerType === InspectionChecklistAnswerType.input && item.supervisorAnswer?.answer && (
                  <p className="mt-1 ml-0 text-xs text-gray-500">
                    <div className="font-semibold">
                      {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.SUPERVISOR_ANSWER')}:
                    </div>
                    <div>{item.supervisorAnswer?.answer}</div>
                  </p>
                )}

                {item.answerType === InspectionChecklistAnswerType.input && item.workerAnswer?.answer && (
                  <p className="mt-1 ml-2 text-xs">
                    <div className="font-semibold">
                      {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_INSPECTION.WORKER_ANSWER')}:
                    </div>
                    <div>{item.workerAnswer?.answer}</div>
                  </p>
                )}
              </td>
              <td className="">
                {displayPlanIndicator(item) && (
                  <i className="fa-solid fa-triangle-exclamation mr-1.5 text-workcard-planification-primary text-[22px]"></i>
                )}
              </td>
              <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-200 w-[18%] font-bold text-center">
                {item.answerType === InspectionChecklistAnswerType.radio ? (
                  item.workerAnswer ? (
                    item.workerAnswer?.yesOrNo ? (
                      t('FEATURES.WORKCARD.YES')
                    ) : (
                      t('FEATURES.WORKCARD.NO')
                    )
                  ) : (
                    <span
                      className={`${
                        props.userType === 'worker'
                          ? 'bg-yellow-100 border border-yellow-700 text-yellow-800 '
                          : 'text-gray-200'
                      } p-1 rounded`}
                    >
                      {props.userType === 'worker' ? (
                        <i className="fa-solid fa-pencil"></i>
                      ) : (
                        <i className="fa-solid fa-question"></i>
                      )}
                    </span>
                  )
                ) : item.workerAnswer && item.workerAnswer.answer ? (
                  <i className="fa-solid fa-check"></i>
                ) : (
                  <span
                    className={`${
                      props.userType === 'worker'
                        ? 'bg-yellow-100 border border-yellow-700 text-yellow-800 '
                        : 'text-gray-200'
                    } p-1 rounded`}
                  >
                    {props.userType === 'worker' ? (
                      <i className="fa-solid fa-pencil"></i>
                    ) : (
                      <i className="fa-solid fa-question"></i>
                    )}
                  </span>
                )}
              </td>
              {props.userType !== 'worker' && (
                <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-200 w-[18%] font-bold text-center">
                  {item.answerType === InspectionChecklistAnswerType.radio ? (
                    item.supervisorAnswer ? (
                      item.supervisorAnswer?.yesOrNo ? (
                        t('FEATURES.WORKCARD.YES')
                      ) : (
                        t('FEATURES.WORKCARD.NO')
                      )
                    ) : (
                      <span
                        className={`${props.userType === 'worker' ? 'text-gray-200' : 'text-gray-200'} p-1 rounded`}
                      >
                        {props.userType === 'supervisor' ? (
                          <i className="fa-solid fa-question w-[20px]"></i>
                        ) : (
                          <i className="fa-solid fa-question"></i>
                        )}
                      </span>
                    )
                  ) : item.supervisorAnswer && item.supervisorAnswer.answer ? (
                    <i className="fa-solid fa-check"></i>
                  ) : (
                    <span className={`${props.userType === 'worker' ? 'text-gray-200 ' : 'text-gray-500'} p-1 rounded`}>
                      {props.userType === 'worker' ? (
                        <i className="fa-solid fa-question"></i>
                      ) : (
                        <i className="fa-solid fa-question"></i>
                      )}
                    </span>
                  )}

                  <i className="fa-solid fa-pencil ml-4 text-workcard-inspection-title text-[20px]"></i>
                </td>
              )}
            </tr>
          ))}
          {props.userType !== 'worker' && (
            <tr>
              <td colSpan={4}>
                {props.canCopyWorkerAnswers && props.userType !== 'worker' && (
                  <div
                    onClick={handleCopyWorkerAnswers}
                    className="cursor-pointer bg-white border border-black text-black px-2 py-1.5 rounded inline-block shadow- my-[20px] float-right mr-[30px]"
                  >
                    <span>
                      <i className="fa-solid fa-copy mr-0.5"></i>{' '}
                      <span className="text-[13px]">
                        {t('FEATURES.WORKCARD.WORKCARD_TASK_DETAILS.COPY_WORKER_ANSWERS')}
                      </span>
                    </span>
                  </div>
                )}
              </td>
            </tr>
          )}
          <tr className="h-[70px]"></tr>
        </tbody>
      </table>
    </>
  );
};
